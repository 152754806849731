import React, { useEffect, useRef, useState } from 'react';
import snakeCaseKeys from 'snakecase-keys';
import { useHistory } from 'react-router-dom';

import { DefaultHeader } from '../../../components';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import CustomHeaderFilter from '../../../components/Utils/Filter/CustomHeaderFilter';
import { useFetchData } from '../../../hooks';
import ComponentDataTable from '../../../components/Utils/DataTable';
import columns from './data';
import {
  driverShipmentFilterParams,
  shipmentTabOptions
} from '../../../components/Shipment/shipmentFilterParams';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';
import { debouncedMyShippingsRequest } from '../../../requests/shippings';
import payTypes from './options';

const DriverShipmentIndex = () => {
  const history = useHistory();
  const modelUrl = '/driver/shipments';
  const customParamsRef = useRef({ ...driverShipmentFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });
  const {
    data,
    isFetching,
    setMoreData,
    moreData,
    totalData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debouncedMyShippingsRequest,
    withDataTable: true,
    fetchingErrorMessage: 'Oops, ocurrió un problema al buscar tus envíos',
    customParams: {
      ...customParams,
      sort_direction: 'desc',
      dateFrom: customParams.dateFrom
        ? [customParams.dateFrom, customParams.filterByStatus]
        : '',
      dateTo: customParams.dateTo
        ? [customParams.dateTo, customParams.filterByStatus]
        : ''
    }
  });

  const handleShippingRowClicked = row => {
    history.push(`${modelUrl}/${row.id}`);
  };

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <DefaultHeader
        title="Mis Briiing"
        tooltip="Solicitudes de envío que ofertaste y te fueron adjudicadas. Es decir, entregas que debes realizar."
        body={
          <CustomHeaderFilter
            customParams={customParams}
            setCustomParams={setCustomParams}
            startDateField="dateFrom"
            endDateField="dateTo"
            pickupAddress="communeFrom"
            deliveryAddress="communeTo"
            queryPlaceholder="Buscar por nombre o precio"
            optionsSelect={payTypes}
          />
        }
      />
      <div className="data-table driver-dashboard">
        <div className="border-rounded p-0 my-4 dt-driver-next-shipping">
          <ComponentDataTable
            onRequest={isFetching}
            columns={columns}
            moreData={moreData}
            data={data}
            totalRows={totalData}
            resourceRequest={handleIndexRequest}
            onRowClicked={handleShippingRowClicked}
            customDatatableHeader={
              <HeaderFilter
                customParams={customParams}
                setCustomParams={setCustomParams}
                paramName="filterByStatus"
                tabOptions={shipmentTabOptions}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default DriverShipmentIndex;
