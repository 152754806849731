import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import { useFetchData } from '../../../hooks';
import { showTripRequest } from '../../../requests/trips';
import { debouncedMyShippingsRequest } from '../../../requests/shippings';
import {
  ComponentDataTable,
  DefaultHeader,
  DefaultModal,
  SuccessAlert
} from '../../../components';
import SolicitudeTripMainInfo from '../../../components/Driver/DriverShow/SolicitudeTripMainInfo';
import Map from '../../../components/Driver/DriverShow/Map';
import NewTripForm from './NewTripForm';
import columns from '../Shipment/data';
import {
  driverShipmentFilterParams,
  shipmentTabOptions
} from '../../../components/Shipment/shipmentFilterParams';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';

const DriverTripShow = ({ match }) => {
  const [trip, setTrip] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const { id } = match.params;
  const history = useHistory();
  const modelUrl = '/driver/shipments';
  const customParamsRef = useRef({ ...driverShipmentFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });

  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');

  const setDistanceAndDuration = (distanceData, durationData) => {
    let tripDuration = '';
    tripDuration = durationData.replace('hour', 'hora');
    tripDuration = tripDuration.replace('hours', 'horas');
    tripDuration = tripDuration.replace('min', 'minuto');
    tripDuration = tripDuration.replace('mins', 'minutos');
    setDistance(distanceData);
    setDuration(tripDuration);
  };

  const fetchTrip = () => {
    setIsFetching(true);
    showTripRequest(id, {
      dispatch,
      successCallback: response => {
        setTrip(camelCaseRecursive(response.data));
        setIsFetching(false);
      }
    });
  };

  const getTripStatusTransalted = () => {
    return shipmentTabOptions
      .find(option => option.value === customParams.filterByStatus)
      .label.toLowerCase();
  };
  const tripStatusTranslated = getTripStatusTransalted();

  const {
    data: shippingTrips,
    isFetching: isFetchingShippingTrips,
    setMoreData,
    moreData,
    totalData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debouncedMyShippingsRequest,
    withDataTable: true,
    fetchingErrorMessage: 'Oops, ocurrió un problema al buscar tus envíos',
    customParams: {
      ...customParams,
      sort_direction: 'desc',
      trip_id: id
    }
  });

  useEffect(fetchTrip, []);

  const {
    deliveryEndDate,
    deliveryOption,
    deliveryStartDate,
    destinyDetour,
    originDetour,
    pickupStartDate,
    pickupEndDate,
    pickupOption,
    startDate,
    endDate,
    deliveryAddressAttributes,
    pickupAddressAttributes,
    tripStatus
  } = trip;

  const handleShippingRowClicked = row => {
    history.push(`${modelUrl}/${row.id}`);
  };

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  if (isFetching) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  return (
    <>
      <Row className="justify-content-between opacity-animation">
        <Col sm={12} md={9} className="">
          <DefaultHeader
            breadcrumb={[
              { key: 1, name: 'Mis viajes', href: '/driver/trips' },
              {
                key: 2,
                name: 'Detalle viaje'
              }
            ]}
          />
        </Col>
        <Col sm={12} md={2} className="">
          <Button
            onClick={() => setShowModal(true)}
            className="w-100 mb-3 text-black"
            variant="primary"
          >
            Editar
          </Button>
        </Col>
      </Row>
      <div className="driver-solicitude-trip">
        <Row className="d-flex align-items-start">
          <SolicitudeTripMainInfo
            id={id}
            tripStatus={tripStatus}
            distance={distance}
            duration={duration}
            startDate={startDate}
            endDate={endDate}
            pickupOption={pickupOption}
            pickupStartDate={pickupStartDate}
            pickupEndDate={pickupEndDate}
            originDetour={originDetour}
            deliveryOption={deliveryOption}
            deliveryStartDate={deliveryStartDate}
            deliveryEndDate={deliveryEndDate}
            destinyDetour={destinyDetour}
            startLocation={pickupAddressAttributes?.communeName}
            endLocation={deliveryAddressAttributes?.communeName}
            origin={pickupAddressAttributes?.streetName}
            destiny={deliveryAddressAttributes?.streetName}
          />
          <Col sm={12} md={7} className="right-animation">
            <Map
              setDistanceAndDuration={setDistanceAndDuration}
              startPoint={pickupAddressAttributes?.streetName}
              endPoint={deliveryAddressAttributes?.streetName}
            />
          </Col>
        </Row>
      </div>

      <hr />

      <div className="data-table driver-dashboard">
        <div className="border-rounded p-0 my-4 dt-driver-next-shipping">
          <ComponentDataTable
            onRequest={isFetchingShippingTrips}
            columns={columns}
            moreData={moreData}
            data={shippingTrips}
            totalRows={totalData}
            resourceRequest={handleIndexRequest}
            onRowClicked={handleShippingRowClicked}
            noResultsMsg={`No hay Briiings ${tripStatusTranslated} en este viaje.`}
            customDatatableHeader={
              <HeaderFilter
                customParams={customParams}
                setCustomParams={setCustomParams}
                paramName="filterByStatus"
                tabOptions={shipmentTabOptions}
              />
            }
          />
        </div>
      </div>

      <DefaultModal
        title="Editar mi viaje"
        body={
          <NewTripForm
            trip={trip}
            setShowModal={setShowModal}
            setShowAlert={setShowAlert}
          />
        }
        show={ShowModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => setShowModal(false)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size="xl"
        noButtons
      />
      <SuccessAlert
        message="Viaje actualizado"
        titlebtn="Entendido"
        onClickHide={() => {
          setShowAlert(false);
          window.location.reload();
        }}
        show={showAlert}
      />
    </>
  );
};

export default DriverTripShow;
