import React from 'react';
import { Row } from 'react-bootstrap';
import IcoMoon from '../../Utils/Icon';
import CarDescription from './CarDescription';
import ImageWithZoom from '../../Utils/Image/ImageWithZoom';

const DriverDescription = ({
  avatar,
  carImages,
  title,
  carName,
  licensePlate,
  comments,
  dimensions,
  driverName,
  stars,
  driverCancellationFee
}) => {
  return (
    <div className="pt-2">
      <Row className="d-flex justify-content-start align-items-center align-content-center">
        {avatar && Object?.keys(avatar).length > 0 ? (
          <>
            <div className="d-flex justify-content-center align-items-center img-avatar-modal">
              <ImageWithZoom alt="avatar-driver" src={avatar.fileUrlMinified} />
            </div>
          </>
        ) : (
          <IcoMoon
            className="ml-2 mb-3"
            size="45"
            icon="person"
            color="#ff5e62"
          />
        )}
        <div className="mb-3">
          <h6>{driverName}</h6>
          <p className="font-weight-bold">
            Tasa de cancelación: {driverCancellationFee}%
          </p>
        </div>
        <div className="box-star ml-auto mr-3 mb-2 mt-n2">
          <IcoMoon icon="star-full" />
          <span>{stars}</span>
        </div>
      </Row>
      <div>
        <CarDescription
          images={carImages}
          title={title}
          carName={carName}
          licensePlate={licensePlate}
          dimensions={dimensions}
          comments={comments}
        />
      </div>
    </div>
  );
};

export default DriverDescription;
