import React from 'react';
import { Image, Card } from 'react-bootstrap';

const ItemCell = ({ itemName, codeNumber, imageUrl }) => {
  return (
    <div data-tag="allowRowEvents" className="d-flex client-item-cell">
      <div
        data-tag="allowRowEvents"
        className="d-flex justify-content-center align-items-center img-container ml-3"
      >
        <Image data-tag="allowRowEvents" className="card-img" src={imageUrl} />
      </div>
      <div
        data-tag="allowRowEvents"
        className="d-flex flex-column justify-content-center align-items-start ml-3"
      >
        <Card.Title
          data-tag="allowRowEvents"
          className="font-weight-bold text-truncate"
        >
          {itemName}
        </Card.Title>
        <Card.Text data-tag="allowRowEvents">Código: {codeNumber}</Card.Text>
      </div>
    </div>
  );
};

export default ItemCell;
