import React from 'react';

const LicenseDescription = ({ licenseType, className }) => {
  return (
    <div className={className}>
      <h6>Tipo de Licencia:</h6>
      <p>{licenseType}</p>
    </div>
  );
};

export default LicenseDescription;
