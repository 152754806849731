import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import ComponentDataTable from '../../../components/Utils/DataTable/index';
import { columns, conditionalClientRows } from './data';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';
import {
  orderOptions,
  tabOptions
} from '../../../components/Solicitude/SolicitudeFilterParams';
import CustomHeaderFilter from '../../../components/Utils/Filter/CustomHeaderFilter';
import { debounceIndexShippingsRequest } from '../../../requests/shippings';
import { shipmentFilterParams } from '../../../components/Shipment/shipmentFilterParams';

const ClientShippmentsIndex = () => {
  const history = useHistory();
  const modelUrl = '/client/solicitudes';
  const customParamsRef = useRef({ ...shipmentFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });

  const handleRowClicked = row => {
    const newUri = `${modelUrl}/${row.solicitude.id}`;
    history.push({ pathname: newUri, state: 'fromShipmentsDoneIndex' });
  };

  const {
    data: solicitudes,
    setMoreData,
    moreData,
    isFetching,
    totalData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexShippingsRequest,
    withDataTable: true,
    fetchingErrorMessage: 'Oops, ocurrió un problema al buscar tus envíos',
    customParams: {
      ...customParams,
      sort_direction: 'desc'
    }
  });

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <DefaultHeader
        title="Envíos realizados"
        body={
          <div className="d-flex align-items-center header-responsive">
            <CustomHeaderFilter
              customParams={customParams}
              setCustomParams={setCustomParams}
              orderOptions={orderOptions}
              startDateField="dateFrom"
              endDateField="dateTo"
              pickupAddress="communeFrom"
              deliveryAddress="communeTo"
              queryPlaceholder="Buscar por nombre o precio"
            />
          </div>
        }
      />
      <div className="data-table dt-client p-0 my-4 dt-client-shippments shippments-done">
        <ComponentDataTable
          onRequest={isFetching}
          columns={columns}
          data={solicitudes}
          totalRows={totalData}
          moreData={moreData}
          onRowClicked={handleRowClicked}
          conditionalRowStyles={conditionalClientRows}
          resourceRequest={handleIndexRequest}
          subHeader={false}
          customDatatableHeader={
            <HeaderFilter
              customParams={customParams}
              setCustomParams={setCustomParams}
              paramName="statusFilter"
              tabOptions={tabOptions}
            />
          }
        />
      </div>
    </>
  );
};

export default ClientShippmentsIndex;
