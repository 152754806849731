import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { Field, Form, withFormik } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { BasicTextArea } from '../..';
import { createCommentRequest } from '../../../requests/comments';

let reloadComments;

const CommentForm = ({
  onHide,
  isValid,
  errors,
  touched,
  buttonText,
  commentId,
  fetchComments
}) => {
  localStorage.removeItem('commentId');
  if (commentId) localStorage.setItem('commentId', commentId);
  reloadComments = fetchComments;

  return (
    <>
      <Form>
        <Field name="text">
          {({ field }) => (
            <BasicTextArea
              {...field}
              rows={3}
              size={10}
              placeholder="Escribe tu pregunta..."
              error={errors[field.name]}
              touched={touched[field.name]}
            />
          )}
        </Field>
        <Row className="d-flex justify-content-end w-100 my-2">
          <Button
            type="submit"
            variant="success"
            className="mr-n4"
            disabled={!isValid}
            onClick={onHide}
          >
            {buttonText}
          </Button>
        </Row>
      </Form>
    </>
  );
};

const handleSubmit = (values, { props }) => {
  const { dispatch, auth, solicitudeId: id } = props;
  const { text } = values;
  const commentId = localStorage.getItem('commentId');
  const comment = {
    text,
    solicitude_id: id,
    comment_id: commentId,
    user_id: auth.user.id
  };
  createCommentRequest(id, {
    dispatch,
    params: comment,
    successCallback: () => {
      localStorage.removeItem('commentId');
      reloadComments();
    }
  });
};

const initialValues = {
  text: '',
  comment_id: ''
};

const mapStateToProps = state => {
  return state;
};

const validationSchema = Yup.object().shape({
  text: Yup.string().required('Este campo es obligatorio')
});

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(CommentForm)
  )
);
