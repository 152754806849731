import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LoadSpinner = ({ customClassName, ...props }) => {
  return (
    <div className={`containerSpinnerLoad ${customClassName}`}>
      <Spinner {...props} />
    </div>
  );
};

LoadSpinner.propTypes = {
  position: PropTypes.string,
  top: PropTypes.number,
  bottom: PropTypes.number
};
LoadSpinner.defaultProps = {
  position: 'absolute',
  top: 0,
  bottom: 0
};

export default LoadSpinner;
