import React from 'react';
import { connect } from 'react-redux';
import DriverDashboard from '../Driver/DriverDashboard/DriverDashboard';

const DriverHome = () => {
  return <DriverDashboard />;
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(DriverHome);
