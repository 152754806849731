import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toogleSidebar } from '../../../actions/utils';
import MainLogoBG from '../MainLogo/MainLogoBG';
import { ButtonComponent } from '../Button';
import IcoMoon from '../Icon/IcoMoon';

const LandingNavbar = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/');
  const publicRoutes = ['', 'components', 'contact', 'page'];
  const { user } = useSelector(state => state.auth);

  return (
    <Navbar
      bg="light"
      expand="lg"
      fixed="top"
      className="bg-menu-landing"
      id="navbar-landing"
    >
      <Navbar.Brand as={Link} to="/" className="navbar-brand-landing">
        <MainLogoBG />
      </Navbar.Brand>
      {user.id > 0 && !publicRoutes.includes(path[1]) ? (
        <ButtonComponent
          typeButton="Icon"
          icon="menu"
          iconSize={32}
          className="hamburger-menu icon"
          variant="outline-info"
          iconColor="black"
          onClick={() => dispatch(toogleSidebar())}
        />
      ) : (
        <Navbar.Toggle className="p-0" aria-controls="basic-navbar-nav">
          <IcoMoon icon="menu" size={32} />
        </Navbar.Toggle>
      )}
      {children}
    </Navbar>
  );
};

export default LandingNavbar;
