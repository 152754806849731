/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner } from 'react-bootstrap';
import bgDriver from '../../assets/images/background_driver_blend.png';
import Form01 from './StepForm/Form01';
import Form02 from './StepForm/Form02';
import Form03 from './StepForm/Form03';
import SuccessRegister from './StepForm/SuccessRegister';
import StepsHeader from './StepForm/StepsHeader';
import { showUserRequest, updateUserRequest } from '../../requests/user';
import { indexBanksRequest } from '../../requests/banks';
import { MainLogo } from '../../components';

const StepsRegister = () => {
  const [step, setStep] = useState(0);
  const [onRequest, setOnRequest] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [user, setUser] = useState({});
  const [vehiclesExist, setVehiclesExist] = useState(false);
  const [myState, setMyState] = useState({});
  const dispatch = useDispatch();
  localStorage.removeItem('isFirstLogin');

  const setInitialValues = () => {
    const emptyBackLicense =
      Object.entries(user?.driverAttributes?.backLicenseImg || {}).length === 0;
    const emptyFrontLicense =
      Object.entries(user?.driverAttributes?.frontLicenseImg || {}).length ===
      0;
    const emptyCertificate =
      Object.entries(user?.driverAttributes?.backgroundCertificateFile || {})
        .length === 0;
    const emptyFrontIdentity =
      Object.entries(user?.driverAttributes?.frontIdentityImg || {}).length ===
      0;
    const emptyBackIdentity =
      Object.entries(user?.driverAttributes?.backIdentityImg || {}).length ===
      0;

    return {
      user: {
        avatar: user.avatarProfile || {},
        driverAttributes: {
          id: user.driverAttributes?.id || '',
          name: user.driverAttributes?.name || '',
          lastName: user.driverAttributes?.lastName || '',
          description: user.driverAttributes?.description || '',
          licenseType: user.driverAttributes?.licenseType || '',
          deleteVehiclesPhotos: [],
          bankAccountAttributes: {
            accountNumber:
              user?.driverAttributes?.bankAccountAttributes?.accountNumber ||
              '',
            accountType:
              user?.driverAttributes?.bankAccountAttributes?.accountType || '',
            bankId:
              user?.driverAttributes?.bankAccountAttributes?.bank?.id || '',
            rut: user?.driverAttributes?.bankAccountAttributes?.rut || ''
          },
          frontLicense: emptyFrontLicense
            ? ''
            : user.driverAttributes?.frontLicenseImg,
          backLicense: emptyBackLicense
            ? ''
            : user.driverAttributes?.backLicenseImg,
          frontIdentity: emptyFrontIdentity
            ? ''
            : user.driverAttributes?.frontIdentityImg,
          backIdentity: emptyBackIdentity
            ? ''
            : user.driverAttributes?.backIdentityImg,
          backgroundCertificate: emptyCertificate
            ? ''
            : user.driverAttributes?.backgroundCertificateFile,
          user_id: user.id || null,
          vehiclesAttributes: vehiclesExist
            ? [...user?.driverAttributes?.vehiclesAttributes]
            : [
                {
                  car_make:
                    user?.driverAttributes?.vehiclesAttributes[0]?.car_make ||
                    '',
                  car_model:
                    user?.driverAttributes?.vehiclesAttributes[0]?.car_model ||
                    '',
                  car_year:
                    user?.driverAttributes?.vehiclesAttributes[0]?.car_year ||
                    '',
                  license_plate:
                    user?.driverAttributes?.vehiclesAttributes[0]
                      ?.license_plate || '',
                  vehicle_type:
                    user?.driverAttributes?.vehiclesAttributes[0]
                      ?.vehicle_type || '',
                  height:
                    user?.driverAttributes?.vehiclesAttributes[0]?.height || '',
                  length:
                    user?.driverAttributes?.vehiclesAttributes[0]?.length || '',
                  width:
                    user?.driverAttributes?.vehiclesAttributes[0]?.width || '',
                  photos: [],
                  id: user?.driverAttributes?.vehiclesAttributes[0]?.id || null,
                  vehicle_photos:
                    user?.driverAttributes?.vehiclesAttributes[0]
                      ?.vehicle_photos || []
                }
              ]
        }
      }
    };
  };

  useEffect(() => {
    setMyState(setInitialValues());
  }, [user]);

  const handleUpdateRequest = values => {
    setOnRequest(true);
    const {
      avatar,
      driverAttributes: {
        vehiclesAttributes,
        frontLicense,
        backLicense,
        frontIdentity,
        backIdentity,
        backgroundCertificate
      }
    } = values.user;

    const params = snakeCaseKeys(values);
    params.user.driver_attributes.vehicles_attributes = vehiclesAttributes;
    params.user.driver_attributes.front_license = frontLicense;
    params.user.driver_attributes.back_license = backLicense;
    params.user.driver_attributes.front_identity = frontIdentity;
    params.user.driver_attributes.back_identity = backIdentity;
    params.user.driver_attributes.background_certificate = backgroundCertificate;
    if (avatar) params.user.avatar = avatar;

    updateUserRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        setStep(4);
      },
      callback: () => setOnRequest(false)
    });
  };

  useEffect(() => {
    setOnRequest(true);
    showUserRequest({
      dispatch,
      successCallback: response => {
        const data = camelCaseRecursive(response.data);
        data.driverAttributes.vehiclesAttributes =
          response.data.driver_attributes.vehicles_attributes;
        setUser(data);
        setStep(1);
      },
      callback: () => setOnRequest(false)
    });
  }, []);

  useEffect(() => {
    setOnRequest(true);
    indexBanksRequest({
      dispatch,
      params: { for_selector: true },
      successCallback: response => {
        const data = camelCaseRecursive(response.data);
        setBankList(data.data);
      },
      callback: () => setOnRequest(false)
    });
  }, []);

  useEffect(() => {
    const vehiclesAttributes = user?.driverAttributes?.vehiclesAttributes;
    if (vehiclesAttributes?.length > 0) setVehiclesExist(true);
    else setVehiclesExist(false);
  }, [user?.driverAttributes?.vehiclesAttributes]);

  const updateState = newState => {
    setMyState(newState);
  };

  const goToStep = newState => {
    if (newState < step) setStep(newState);
  };

  return (
    <div>
      <img src={bgDriver} alt="bg-client" className="bg-image color-driver" />
      <div style={{ position: 'absolute', top: '20px', left: '20px' }}>
        <MainLogo />
      </div>
      <div className="steps_register_background">
        <div className="steps_register_form px-2">
          <StepsHeader step={step} goToStep={goToStep} />
          <div className="">
            {step === 0 && (
              <div
                className="d-flex justify-content-center align-items-center w-100 h-100"
                style={{ minHeight: '500px' }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {step === 1 && !onRequest && (
              <Form01
                modelName="user[driverAttributes]"
                setStep={setStep}
                myState={myState}
                updateState={updateState}
                user={user}
              />
            )}
            {step === 2 && (
              <Form02
                modelName="user[driverAttributes]"
                setStep={setStep}
                myState={myState}
                updateState={updateState}
              />
            )}
            {step === 3 && (
              <Form03
                modelName="user[driverAttributes]"
                setStep={setStep}
                myState={myState}
                updateState={updateState}
                onRequest={onRequest}
                formRequest={handleUpdateRequest}
                bankList={bankList}
              />
            )}
            {step === 4 && <SuccessRegister />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsRegister;
