/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { FormikInput, FormikSelect } from '../../../components';
import AccountTypes from './accountOptions';

const From03 = props => {
  const {
    onHide,
    isValid,
    errors,
    touched,
    modelName,
    setFieldValue,
    setFieldTouched,
    myState,
    setStep,
    updateState,
    showState,
    onRequest,
    bankList
  } = props;

  const {
    bankId,
    accountType
  } = myState.user?.driverAttributes?.bankAccountAttributes;

  return (
    <Form className="steps_register opacity-animation">
      <h4 className="text-center mb-2">Datos Bancarios</h4>

      <Row>
        <Col md={6}>
          <Field name={`${modelName}[name]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                setFieldValue={setFieldValue}
                label="Nombre"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelName}[lastName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                setFieldValue={setFieldValue}
                label="Apellido"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Field name={`${modelName}[bankAccountAttributes][rut]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            label="RUT"
            setFieldValue={setFieldValue}
            placeholder="Ej: 12.345.678-9"
            inputType="text"
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>

      <Row>
        <Col md={6}>
          <Field name={`${modelName}[bankAccountAttributes][bankId]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Banco"
                placeholder="Seleccionar banco"
                options={bankList}
                defaultValue={bankId}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
        <Col md={6}>
          <Field name={`${modelName}[bankAccountAttributes][accountType]`}>
            {({ field }) => (
              <FormikSelect
                {...field}
                label="Tipo de cuenta"
                placeholder="Escoge un cuenta"
                options={AccountTypes}
                defaultValue={accountType}
                onChange={data =>
                  setFieldValue(field.name, data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched(field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </Col>
      </Row>

      <Field name={`${modelName}[bankAccountAttributes][accountNumber]`}>
        {({ field }) => (
          <FormikInput
            {...field}
            label="N° de cuenta"
            placeholder="Ingresa N° de cuenta"
            inputType="text"
            setFieldValue={setFieldValue}
            error={getIn(errors, field.name)}
            touched={getIn(touched, field.name)}
          />
        )}
      </Field>

      <Button
        variant="primary"
        size="lg"
        className="btn mb-2 login-btn driver-btn"
        block
        type="submit"
        disabled={!isValid}
        onClick={onHide}
      >
        {onRequest ? (
          <Spinner
            as="span"
            animation="border"
            className="mr-2"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          'Guardar'
        )}
      </Button>
      <div className="my-4 d-flex justify-content-center reset-password-btn driver">
        <Link to="/driver/home" className="text-black">
          Skip
        </Link>
      </div>
    </Form>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const setInitialValues = props => {
  return props.myState;
};

const validationSchema = Yup.object().shape({
  // user: Yup.object().shape({
  //   driverAttributes: Yup.object().shape({
  //     name: Yup.string().required('Debes ingresar tu nombre'),
  //     lastName: Yup.string().required('Debes ingresar tu apellido'),
  //     bankAccountAttributes: Yup.object().shape({
  //       rut: Yup.string().required('Debes ingresar tu RUT'),
  //       accountNumber: Yup.string().required(
  //         'Debes ingresar tu nombre N° de cuenta'
  //       ),
  //       accountType: Yup.string().required('Debes ingresar tu tipo de cuenta'),
  //       bankId: Yup.string().required('Debes ingresar nombre de tu Banco')
  //     })
  //   })
  // })
});

const handleSubmit = (values, { props }) => {
  const { updateState, formRequest } = props;
  updateState(values);
  formRequest(values);
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: props => setInitialValues(props),
      validationSchema,
      handleSubmit
    })(From03)
  )
);
