import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { useFetchForRemoteSelect } from '../../hooks';
import {
  debouncedIndexCommuneRequest,
  indexCommuneRequest
} from '../../requests/communes';
import { InputRemoteSelect } from '../Utils/Select';

const CommuneRemoteSelect = ({
  abbr,
  values,
  errors,
  touched,
  field,
  nestedDependency,
  dependencyArray,
  customDisabled,
  initialParams,
  onChangeCallback,
  label,
  placeholder
}) => {
  const labelText = label || 'Comuna';
  const placeholderText = placeholder || 'Seleccionar comuna';
  const { setFieldValue } = useFormikContext();
  const {
    options: communes,
    selectedOption: selectedCommune,
    fetchOptions,
    isFetching
  } = useFetchForRemoteSelect({
    indexRequest: indexCommuneRequest,
    debouncedIndexRequest: debouncedIndexCommuneRequest,
    value: getIn(values, field.name),
    initialParams: initialParams && { ...initialParams },
    nestedDependency: nestedDependency && nestedDependency,
    dependencyArray: dependencyArray && [...dependencyArray]
  });

  return (
    <InputRemoteSelect
      {...field}
      abbr={abbr}
      isClearable
      disabled={isFetching || customDisabled}
      label={labelText}
      placeholder={placeholderText}
      defaultOptions={communes}
      value={selectedCommune}
      request={fetchOptions}
      onChange={data => {
        setFieldValue(field.name, data?.value || '');
        if (onChangeCallback) onChangeCallback(data);
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default CommuneRemoteSelect;
