import React from 'react';
import AsyncSelect from 'react-select/async';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ff5e62' : 'white',
    '&:hover': {
      backgroundColor: 'rgba(145, 145, 145, 0.415)',
      cursor: 'pointer'
    }
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '15px',
    boxShadow: state.isFocused
      ? '0px 0px 3px 2px rgba(145, 145, 145, 0.455)'
      : 'none',
    borderColor: '#ced4da',
    '&:hover': {
      borderColor: '#ced4da'
    }
  })
};

const RemoteSelect = ({ disabled, value, request, onChange, ...moreProps }) => (
  <AsyncSelect
    placeholder="Buscar..."
    loadingMessage={() => 'Cargando...'}
    noOptionsMessage={() => 'No hay opciones'}
    value={value}
    defaultOptions
    loadOptions={request}
    onChange={onChange}
    isDisabled={disabled}
    styles={customStyles}
    {...moreProps}
  />
);

export default RemoteSelect;
