import React, { useState } from 'react';
import RatingCell from './RatingCell';
import StatusCell from './StatusCell';

const ShippingCellChanger = ({ solicitude }) => {
  const {
    id: solicitudeId,
    selectedQuotation: { id: quotationId, rating },
    status: solicitudeStatus
  } = solicitude;
  const [status, setStatus] = useState(solicitudeStatus);
  const isFinished = status === 'finished';
  return isFinished ? (
    <RatingCell quotationId={quotationId} rating={rating} />
  ) : (
    <StatusCell id={solicitudeId} status={status} setStatus={setStatus} />
  );
};

export default ShippingCellChanger;
