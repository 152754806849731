import React from 'react';
import { connect } from 'react-redux';
import CLientDashboard from '../Client/ClientDashboard/ClientDashboard';

const ClientHome = () => {
  return <CLientDashboard />;
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(ClientHome);
