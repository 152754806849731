import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { debounceMySolicitudesRequest } from '../../../requests/solicitudes';
import ComponentDataTable from '../../../components/Utils/DataTable/index';
import { columns, conditionalClientRows } from '../ClientDashboard/data';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';
import {
  clientSolicitudeFilterParams,
  orderOptions,
  tabOptions
} from '../../../components/Solicitude/SolicitudeFilterParams';
import CustomHeaderFilter from '../../../components/Utils/Filter/CustomHeaderFilter';

const ClientSolicitudeIndex = () => {
  const history = useHistory();
  const modelUrl = '/client/solicitudes';
  const customParamsRef = useRef(clientSolicitudeFilterParams);
  const [customParams, setCustomParams] = useState(customParamsRef.current);

  const handleRowClicked = row => {
    const newUri = `${modelUrl}/${row.id}`;
    history.push({ pathname: newUri, state: 'fromSolicitudesIndex' });
  };

  const { data: solicitudes, setMoreData, isFetching } = useFetchData({
    debouncedIndexRequest: debounceMySolicitudesRequest,
    fetchingErrorMessage: 'Oops, ocurrió un problema al buscar tus solicitudes',
    customParams: {
      ...customParamsRef.current,
      sort_direction: 'desc'
    }
  });

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <>
        <DefaultHeader
          title="Mis Solicitudes"
          body={
            <div className="d-flex align-items-center header-responsive">
              <CustomHeaderFilter
                customParams={customParams}
                setCustomParams={setCustomParams}
                orderOptions={orderOptions}
                startDateField="dateFrom"
                endDateField="dateTo"
                pickupAddress="communeFrom"
                deliveryAddress="communeTo"
                queryPlaceholder="Buscar por nombre"
              />
              <Button
                as={Link}
                to="/client/solicitudes/new"
                variant="secondary"
                className="d-flex justify-content-center align-items-center header-button"
                style={{
                  height: '40px',
                  minWidth: '150px'
                }}
              >
                Nueva Solicitud
              </Button>
            </div>
          }
        />
        <div className="data-table dt-client p-0 my-4 dt-client-shippments index-view">
          <ComponentDataTable
            onRequest={isFetching}
            columns={columns}
            data={solicitudes}
            onRowClicked={handleRowClicked}
            conditionalRowStyles={conditionalClientRows}
            customDatatableHeader={
              <HeaderFilter
                customParams={customParams}
                setCustomParams={setCustomParams}
                paramName="filterQuotation"
                tabOptions={tabOptions}
              />
            }
          />
        </div>
      </>
    </>
  );
};

export default ClientSolicitudeIndex;
