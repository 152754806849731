import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import IcoMoon from '../Icon/IcoMoon';

const SidebarClient = ({ closeSidebar, pathName, location }) => {
  const isDriver = localStorage.getItem('loginRole') === 'driver';
  const home = location.pathname === '/driver/home';
  const isMobile = window.innerWidth <= 991;

  const setActiveLink = links => {
    if (links.includes(pathName)) return pathName;
    return '';
  };

  return (
    <div className="container-links">
      <Nav
        className="flex-column"
        activeKey={pathName}
        defaultActiveKey="/client/home"
      >
        <Nav.Item>
          <Nav.Link
            eventKey={setActiveLink(
              isMobile ? ['home'] : ['home', 'solicitudes']
            )}
            as={Link}
            to="/client/home"
            className={`${isDriver && home ? 'driver' : 'client'}`}
            onClick={closeSidebar}
          >
            <IcoMoon icon="home1" />
            <span className="d-inline-block m-2">Inicio</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-lg-none">
          <Nav.Link
            eventKey="solicitudes"
            as={Link}
            to="/client/solicitudes/new"
            className={`${isDriver ? 'driver' : 'client'}`}
            onClick={closeSidebar}
          >
            <IcoMoon icon="files" />
            <span className="d-inline-block m-2">Crear Solicitud</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="shipments"
            as={Link}
            to="/client/shipments"
            className={`${isDriver ? 'driver' : 'client'}`}
            onClick={closeSidebar}
          >
            <IcoMoon icon="shipping" />
            <span className="d-inline-block m-2">Envíos Realizados</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default SidebarClient;
