import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';
import { FormikInput } from '../../components';
import { requestSignIn } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import { resetPasswordRequest } from '../../requests/utils';
import WrapperSession from '../../components/Auth/WrapperSession';
import bgDriver from '../../assets/images/background_driver_blend.png';
import bgClient from '../../assets/images/background_client_blend.png';

const ResetPassword = props => {
  const loginRole = localStorage.getItem('loginRole');
  const isClient = loginRole === 'client';
  const { onHide, isValid, errors, touched } = props;
  return (
    <WrapperSession>
      <Form className="login_box p-5">
        <img
          src={isClient ? bgClient : bgDriver}
          alt="bg-client"
          className={`bg-image ${isClient ? 'color-client' : 'color-driver'}`}
        />
        <Fade top duration={100}>
          <h5 className="text-center mb-3">Cambiar contraseña</h5>
        </Fade>
        <Fade bottom duration={100}>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                label="Contraseña"
                placeholder="Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="passwordConfirmation">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                label="Confirmar Contraseña"
                placeholder="Confirmar Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant="secondary"
            size="lg"
            className="btn login-btn client-btn"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Cambiar Contraseña
          </Button>
        </Fade>
      </Form>
    </WrapperSession>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test(
      'password-confirmation-test',
      'Las contraseñas no coinciden',
      function equalTo(value) {
        if (value && value === this.parent.password) {
          return true;
        }
        return false;
      }
    )
});

const handleSubmit = (values, { props }) => {
  const { location, dispatch } = props;
  const token = new URLSearchParams(location.search).get('token');
  resetPasswordRequest({
    dispatch,
    params: {
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
      token
    },
    successCallback: result => {
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
      dispatch(
        requestSignIn({
          user: {
            email: result.data.user.email,
            password: values.password,
            resetPassword: true
          }
        })
      );
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ResetPassword)
  )
);
