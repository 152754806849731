import React, { useEffect, useState } from 'react';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { showSolicitudeRequest } from '../../../requests/solicitudes';
import { DefaultHeader } from '../../../components';
import SolicitudeTripMainInfo from '../../../components/Driver/DriverShow/SolicitudeTripMainInfo';
import Map from '../../../components/Driver/DriverShow/Map';
import Route from '../../../components/Driver/DriverShow/Route';
import basicSolicitude from '../../../components/Solicitude/basicSolicitude';

const DriverSolicitudeTripShow = ({ match }) => {
  const [solicitude, setSolicitude] = useState(basicSolicitude);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const { id } = match.params;

  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');

  const setDistanceAndDuration = (distanceData, durationData) => {
    durationData.replace('hour', 'hora');
    durationData.replace('min', 'minuto');
    setDistance(distanceData);
    setDuration(durationData);
  };

  const fetchSolicitude = () => {
    setIsFetching(true);
    showSolicitudeRequest(id, {
      dispatch,
      successCallback: response => {
        setSolicitude(camelCaseRecursive(response.data));
        setIsFetching(false);
      }
    });
  };

  useEffect(fetchSolicitude, []);

  if (isFetching) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  const {
    // deliveryOption,
    // deliveryStartDate,
    deliveryEndDate,
    pickupPersonName,
    pickupPersonPhone,
    deliveryPersonName,
    deliveryPersonPhone,
    pickupStartDate,
    // pickupOption,
    client,
    // description,
    deliveryAddressAttributes,
    pickupAddressAttributes,
    itemsAttributes
  } = solicitude;

  const startPoint = `${pickupAddressAttributes.streetName}, ${pickupAddressAttributes.communeName}. ${pickupAddressAttributes.regionName}`;
  const endPoint = `${deliveryAddressAttributes.streetName}, ${deliveryAddressAttributes.communeName}. ${deliveryAddressAttributes.regionName}`;

  return (
    <>
      <DefaultHeader
        breadcrumb={[
          { key: 1, name: 'Mis viajes', href: '/driver/home' },
          {
            key: 2,
            name: 'Solicitud',
            href: `/driver/solicitudes/${id}/shipping`
          },
          { key: 3, name: 'Detalle viaje' }
        ]}
      />
      <div className="driver-solicitude-trip">
        <Row className="d-flex align-items-start">
          <SolicitudeTripMainInfo
            distance={distance}
            duration={duration}
            pickupStartDate={pickupStartDate}
            deliveryEndDate={deliveryEndDate}
            startLocation={pickupAddressAttributes.communeName}
            endLocation={deliveryAddressAttributes.communeName}
          />
          <Col sm={12} md={7}>
            <Map
              setDistanceAndDuration={setDistanceAndDuration}
              startPoint={startPoint}
              endPoint={endPoint}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Route
            itemsAttributes={itemsAttributes}
            pickupAddressAttributes={pickupAddressAttributes}
            deliveryAddressAttributes={deliveryAddressAttributes}
            pickupPersonName={pickupPersonName}
            pickupPersonPhone={pickupPersonPhone}
            deliveryPersonName={deliveryPersonName}
            deliveryPersonPhone={deliveryPersonPhone}
            client={client}
            solicitude={solicitude}
          />
        </Row>
      </div>
    </>
  );
};

export default DriverSolicitudeTripShow;
