/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { Spinner } from 'react-bootstrap';
import geocodeAddress from './GoogleGeocode';

const GooglePointMap = ({
  zoomControl,
  streetViewControl,
  mapTypeControl,
  fullscreenControl,
  address
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  });
  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [center, setCenter] = useState(undefined);

  useEffect(() => {
    const setCoordinates = async () => {
      const coordinates = await geocodeAddress(address);
      setCenter(coordinates);
    };
    setCoordinates();
  }, []);

  if (!isLoaded) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  const options = {
    zoomControl,
    streetViewControl,
    mapTypeControl,
    fullscreenControl
  };

  if (map) console.log('Map Loaded');

  return (
    <div className="google-map">
      <div className="google-map">
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={options}
          onLoad={mapData => {
            setMap(mapData);
          }}
        >
          <Marker position={center} />
          {/* Put as many Marker as you desire... */}
        </GoogleMap>
      </div>
    </div>
  );
};

export default GooglePointMap;
