export const utilsTypes = {
  // SETTINGS
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
  // SIDEBAR
  TOOGLE_SIDEBAR: 'TOOGLE_SIDEBAR',
  SET_SIDEBAR: 'SET_SIDEBAR',
  // SLIDES
  GET_SLIDES_REQUEST: 'GET_SLIDES_REQUEST',
  GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS',
  GET_SLIDES_FAILURE: 'GET_SLIDES_FAILURE',
  // PAGE
  GET_PAGE_REQUEST: 'GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'GET_PAGE_FAILURE',
  // ALERT
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT_ERROR: 'SET_ALERT_ERROR',
  // SET_HEADERS
  SET_HEADERS: 'SET_HEADERS',
  // NOTIFICATIONS
  GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
  GET_UNREAD_NOTIFICATIONS_REQUEST: 'GET_UNREAD_NOTIFICATIONS_REQUEST',
  GET_UNREAD_NOTIFICATIONS_SUCCESS: 'GET_UNREAD_NOTIFICATIONS_SUCCESS',
  GET_UNREAD_NOTIFICATIONS_FAILURE: 'GET_UNREAD_NOTIFICATIONS_FAILURE',
  // HELPERS
  OPEN_TRIP_MODAL: 'OPEN_TRIP_MODAL'
};

// SETTINGS
export const getSettings = () => ({ type: utilsTypes.GET_SETTINGS_REQUEST });

// SIDEBAR
export const toogleSidebar = () => ({ type: utilsTypes.TOOGLE_SIDEBAR });
export const setSidebar = params => ({ type: utilsTypes.SET_SIDEBAR, params });

// SLIDE
export const getSlides = () => ({ type: utilsTypes.GET_SLIDES_REQUEST });

// PAGE
export const getPage = url => ({
  type: utilsTypes.GET_PAGE_REQUEST,
  url
});

// ALERT
export const closeAlert = params => ({ type: utilsTypes.CLOSE_ALERT, params });
export const sendAlert = params => ({ type: utilsTypes.SET_ALERT, ...params });

export const setHeaders = params => ({
  type: utilsTypes.SET_HEADERS,
  ...params
});

// NOTIFICATIONS
export const getNotifications = () => ({
  type: utilsTypes.GET_NOTIFICATIONS_REQUEST
});
export const getUnreadNotifications = () => ({
  type: utilsTypes.GET_UNREAD_NOTIFICATIONS_REQUEST
});

// HELPERS
export const openTripModal = () => ({
  type: utilsTypes.OPEN_TRIP_MODAL
});
