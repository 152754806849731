import React from 'react';
import IcoMoon from '../Utils/Icon';

const ClientCell = ({ fristName, lastName, profileImage }) => {
  const getItemProfile = () => {
    if (profileImage) {
      return (
        <div className="avatar_profile_img d-flex justify-content-center align-items-center">
          <img alt="avatar" src={profileImage} />
        </div>
      );
    }
    return (
      <div style={{ marginRight: 6 }}>
        <IcoMoon icon="person" size={39} />
      </div>
    );
  };

  return (
    <div
      data-tag="allowRowEvents"
      className="d-flex justify-content-center align-items-center pl-2 pt-3 pt-md-0"
    >
      {getItemProfile()}
      <p data-tag="allowRowEvents">
        {fristName} {lastName}
      </p>
    </div>
  );
};

export default ClientCell;
