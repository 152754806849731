import React from 'react';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import IcoMoon from '../Utils/Icon/IcoMoon';

const DashboardGreeting = ({ name, countDays, stars, profilePercentage }) => {
  const location = useLocation();
  const isClient = location.pathname.includes('client');

  return (
    <Col
      lg={12}
      xl={4}
      className="p-0 my-2 d-flex align-items-start justify-content-center flex-column left-animation"
    >
      <div className="d-flex align-items-center flex-wrap">
        <h4 className="title-name mr-2">¡Hola {name}!</h4>
        {stars && (
          <span className="d-flex justify-content-center align-items-center score-box">
            <IcoMoon size="17" icon="star-full" />
            <p className="mt-1 nokerning-text">{stars}</p>
          </span>
        )}
      </div>
      <h6 className="days-used font-weight-normal">
        Has usado Briiing! por {countDays} días
      </h6>
      {profilePercentage !== 100 && !isClient && (
        <Link className="link-primary" to="/driver/stepsregister">
          <OverlayTrigger
            placement="right"
            delay={{ show: 100, hide: 100 }}
            overlay={
              <Tooltip id="button-tooltip">Completar Información</Tooltip>
            }
          >
            <p className="text-primary font-weight-bold">
              Tu perfil esta {profilePercentage}% completo.
            </p>
          </OverlayTrigger>
        </Link>
      )}
    </Col>
  );
};

export default DashboardGreeting;
