import React from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';

import IconCell from '../../../components/TableCells/IconCell';
import DriverItemCell from '../../../components/TableCells/DriverItemCell';
import QuotationCell from '../../../components/TableCells/QuotationCell';
import DateCell from '../../../components/TableCells/DateCell';

const Columns = memoize(({ hasNotVehicle, itIsSolicitude }) => [
  {
    name: 'Icon',
    sortable: false,
    grow: 0.05,
    selector: row => row.icon,
    cell: row => {
      const {
        id,
        icon,
        iconText,
        hasQuotation,
        amIBestQuotation
      } = itIsSolicitude ? row : row.solicitude;
      return (
        <IconCell
          id={id}
          icon={icon}
          hasQuotation={hasQuotation}
          module="driver"
          message={iconText}
          userHasBestQuotation={amIBestQuotation}
        />
      );
    }
  },
  {
    name: 'Nombre Item',
    grow: 1.5,
    sortable: false,
    selector: row => row.itemName,
    cell: row => {
      const { id, itemName, itemsAttributes } = itIsSolicitude
        ? row
        : row.solicitude;
      const { tripId } = row;
      return (
        <DriverItemCell
          id={id}
          itemName={itemName}
          height={itemsAttributes[0]?.height}
          length={itemsAttributes[0]?.length}
          width={itemsAttributes[0]?.width}
          weight={itemsAttributes[0]?.weight}
          imageUrl={itemsAttributes[0].itemImages[0]?.fileUrlMinified}
          isFragile={itemsAttributes[0]?.isFragile}
          tripId={tripId}
        />
      );
    }
  },
  {
    name: 'Fecha Salida',
    grow: 1.5,
    sortable: false,
    selector: row => row.startDate,
    cell: row => {
      const {
        id,
        startDate,
        endDate,
        pickupAddressAttributes,
        deliveryAddressAttributes,
        hasMyOffer,
        pickupOption,
        deliveryOption
      } = itIsSolicitude ? row : row.solicitude;
      return (
        <DateCell
          id={id}
          startLocation={pickupAddressAttributes.communeName}
          endLocation={deliveryAddressAttributes.communeName}
          startDate={startDate}
          endDate={endDate}
          hasMyQuotation={hasMyOffer}
          pickupOption={pickupOption}
          deliveryOption={deliveryOption}
        />
      );
    }
  },
  {
    name: 'N° Cotizaciones',
    grow: 1.3,
    sortable: false,
    selector: row => row.quoteTotal,
    cell: row => {
      const {
        id,
        hasQuotation,
        offerCountMessage,
        quotationCount,
        amIBestQuotation,
        hasMyOffer
      } = itIsSolicitude ? row : row.solicitude;
      const imBestOffer = hasMyOffer && amIBestQuotation;
      return (
        <QuotationCell
          id={id}
          hasQuotation={hasQuotation}
          module="driver"
          hasMyOffer={hasMyOffer}
          quotationCount={quotationCount}
          quotationMessage={offerCountMessage}
          buttonName="Re ofertar"
          buttonVariant={imBestOffer ? 'success' : 'warning'}
          buttonUrl={{
            pathname: `/driver/quotations/${id}/new`,
            state: 'fromDashboard'
          }}
          disabledButton={hasNotVehicle}
        />
      );
    }
  }
]);

Columns.propTypes = {
  hasNotVehicle: PropTypes.bool,
  itIsSolicitude: PropTypes.bool
};

Columns.defaultProps = {
  hasNotVehicle: false,
  itIsSolicitude: true
};

const conditionalDriverRows = memoize(({ itIsSolicitude = true }) => [
  {
    when: row => {
      const { icon } = itIsSolicitude ? row : row.solicitude;
      return icon === 'flame';
    },
    classNames: ['bg-green']
  },
  {
    when: row => {
      const { icon } = itIsSolicitude ? row : row.solicitude;
      return icon === 'files';
    },
    classNames: ['hover-green']
  },
  {
    when: row => {
      const { icon, amIBestQuotation } = itIsSolicitude ? row : row.solicitude;
      return icon === 'shipping' && !amIBestQuotation;
    },
    classNames: ['hover-yellow']
  },
  {
    when: row => {
      const { icon, amIBestQuotation } = itIsSolicitude ? row : row.solicitude;
      return icon === 'shipping' && amIBestQuotation;
    },
    classNames: ['hover-disabled']
  }
]);

export { Columns, conditionalDriverRows };
