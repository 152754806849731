import React from 'react';
import { Form } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import IcoMoon from '../Icon/IcoMoon';

const FormikAddressAutocompleteInput = ({
  placeholder,
  label,
  size,
  margin,
  error,
  latError,
  touched,
  helpText,
  inputType,
  abbr,
  icon,
  iconSize,
  iconColor,
  iconClick,
  classNameIcon,
  handlePlaceSelected,
  values,
  ...props
}) => {
  return (
    <Form.Group className={margin}>
      {label ? (
        <Form.Label>
          {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
        </Form.Label>
      ) : (
        false
      )}

      <Autocomplete
        className={`form-control ${error && touched ? 'is-invalid' : ''}`}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        placeholder={placeholder}
        options={{
          types: ['address'],
          componentRestrictions: { country: 'cl' }
        }}
        autoComplete="off"
        // defaultValue={value}
        onPlaceSelected={place => handlePlaceSelected(place)}
        {...props}
      />
      {icon && !error && (
        <div className={`container-icon ${classNameIcon}`}>
          <IcoMoon
            onClick={() => iconClick()}
            size={iconSize}
            color={iconColor}
            icon={icon}
          />
        </div>
      )}
      {error && touched ? (
        <Form.Text className="text-danger">{error}</Form.Text>
      ) : null}
    </Form.Group>
  );
};

export default FormikAddressAutocompleteInput;
