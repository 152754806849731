import { getIn } from 'formik';
import camelCaseRecursive from 'camelcase-keys-recursive';
import jwt from 'jwt-decode';

const responseToBlob = response => {
  const format = response.headers['content-type'].split('/')[1];
  let blob = '';
  const { data } = response;
  if (format === 'xlsx') {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i !== byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  } else if (format === 'csv') {
    const readUTF8 = data.toString('utf8');
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    blob = new Blob([data], { type: response.headers['content-type'] });
  }
  return blob;
};

const parseFilename = headers => {
  let filename = '';
  const disposition = headers['content-disposition'];
  if (
    disposition &&
    (disposition.indexOf('attachment') !== -1 ||
      disposition.indexOf('inline') !== -1)
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

const downloadFile = response => {
  const blob = responseToBlob(response);
  const filename = parseFilename(response.headers);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}`;
  link.click();
  window.URL.revokeObjectURL(url);
};

const insertString = (value, position, str) => {
  return value.slice(0, position) + str + value.slice(position);
};

const rutFormat = value => {
  let rut = value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  if (rut.length > 1) {
    rut = insertString(rut, -1, '-');
  }
  if (rut.length > 5) {
    rut = insertString(rut, -5, '.');
  }
  if (rut.length > 9) {
    rut = insertString(rut, -9, '.');
  }
  return rut;
};

const validRutInput = e => {
  const re = /^[0-9kK\b]+$/;
  const rawRut = e.target.value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  return e.target.value === '' || re.test(rawRut);
};

const validPhoneInput = e => {
  const re = /^[0-9\b]+$/;
  return e.target.value === '' || re.test(e.target.value);
};

const isObject = v => typeof v === 'object';
const camelCase = str => str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());

const camelCaseEmptyStringRecursive = obj => {
  if (obj === null) return '';

  if (Array.isArray(obj)) {
    return obj.map(value => camelCaseEmptyStringRecursive(value));
  }

  if (isObject(obj)) {
    const newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
      newObject[camelCase(key)] = camelCaseEmptyStringRecursive(value);
    });
    return newObject;
  }
  return obj;
};

const filterArray = (array, query, keys) => {
  if (query === '') {
    return array;
  }
  if (array.length === 0) {
    return array;
  }
  return array.filter(item => {
    let inQuery = false;
    keys.forEach(key => {
      let vKey = getIn(item, key);
      vKey = Array.isArray(vKey) ? vKey.map(k => k.label) : vKey;
      if (
        String(vKey)
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        inQuery = true;
      }
    });
    return inQuery;
  });
};

const sortByAttribute = (array, attribute, order = 'asc') => {
  return array.sort((a, b) => {
    const aAttr = a[attribute]?.toLowerCase();
    const bAttr = b[attribute]?.toLowerCase();

    if (order === 'desc') {
      if (aAttr < bAttr) return 1;
      if (bAttr < aAttr) return -1;
    } else {
      if (aAttr > bAttr) return 1;
      if (bAttr > aAttr) return -1;
    }
    return 0;
  });
};

const hasRole = role => {
  const payload = localStorage.getItem('jwt');
  const user = jwt(payload);
  if (!user) return false;

  const { roles } = camelCaseRecursive(user);
  return roles?.includes(role);
};

const redirectByRole = () => {
  if (hasRole('client')) return '/client/home';
  if (hasRole('driver')) return '/driver/home';

  return '/';
};

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

const capitalizeString = str => {
  const lower = str.toLowerCase();
  return capitalizeFirstLetter(lower);
};

const emptyFalseParamsRecursive = (params = {}) => {
  return Object.entries(params)
    .map(([key, value]) => [key, value || ''])
    .reduce((acc, el) => {
      const [key, value] = el;
      return { ...acc, [key]: value };
    }, {});
};

const togglePath = currentPathName => {
  if (currentPathName === 'client') {
    localStorage.setItem('loginRole', 'client');
    return '/driver/home';
  }
  localStorage.setItem('loginRole', 'driver');
  return '/client/home';
};

export const statusToButtonText = {
  with_selected_quotation: 'Notificar "Briiing recogido"',
  picked: 'Notificar "En camino a la entrega"',
  to_be_delivered: 'Notificar "Entrega"',
  delivered: 'Entregado',
  finished: 'Finalizado'
};

export const solicitudeStatusButtonColor = {
  with_selected_quotation: 'selected_quotation',
  picked: 'picked',
  to_be_delivered: 'to_be_delivered',
  delivered: 'info',
  finished: 'info'
};

const formatDateToEdit = d => {
  return d
    .split('-')
    .reverse()
    .join('-');
};

export {
  camelCaseEmptyStringRecursive,
  emptyFalseParamsRecursive,
  downloadFile,
  filterArray,
  hasRole,
  redirectByRole,
  rutFormat,
  sortByAttribute,
  validRutInput,
  validPhoneInput,
  capitalizeString,
  togglePath,
  formatDateToEdit
};
