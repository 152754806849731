import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import {
  DefaultHeader,
  SuccessAlert,
  SpinnerLoader
} from '../../../components';
import { updateUserRequest, showUserRequest } from '../../../requests/user';
import { indexBanksRequest } from '../../../requests/banks';
import { validateToken } from '../../../actions/auth';
import ProfileDriverForm from './ProfileDriverForm';

const ProfileDriverEdit = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [quotationId, setQuotationId] = useState(0);
  const [vehiclesExist, setVehiclesExist] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUpdateRequest = values => {
    setOnRequest(true);
    const {
      avatar,
      fromQuotation,
      driverAttributes: {
        vehiclesAttributes,
        frontLicense,
        backLicense,
        frontIdentity,
        backIdentity,
        backgroundCertificate
      }
    } = values.user;
    if (fromQuotation) setQuotationId(fromQuotation);

    const params = snakeCaseKeys(values);
    params.user.driver_attributes.vehicles_attributes = vehiclesAttributes;
    params.user.driver_attributes.front_license = frontLicense;
    params.user.driver_attributes.back_license = backLicense;
    params.user.driver_attributes.front_identity = frontIdentity;
    params.user.driver_attributes.back_identity = backIdentity;
    params.user.driver_attributes.background_certificate = backgroundCertificate;
    if (avatar) params.user.avatar = avatar;

    updateUserRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        dispatch(validateToken());
        setShowAlert(true);
        setOnRequest(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  useEffect(() => {
    setOnRequest(true);
    showUserRequest({
      dispatch,
      successCallback: response => {
        const data = camelCaseRecursive(response.data);
        data.driverAttributes.vehiclesAttributes =
          response.data.driver_attributes.vehicles_attributes;
        setUser(data);
      },
      callback: () => setOnRequest(false)
    });
  }, []);

  useEffect(() => {
    setOnRequest(true);
    indexBanksRequest({
      dispatch,
      params: { for_selector: true },
      successCallback: response => {
        const data = camelCaseRecursive(response.data);
        setBankList(data.data);
      },
      callback: () => setOnRequest(false)
    });
  }, []);

  useEffect(() => {
    const vehiclesAttributes = user?.driverAttributes?.vehiclesAttributes;
    if (vehiclesAttributes?.length > 0) setVehiclesExist(true);
    else setVehiclesExist(false);
  }, [user?.driverAttributes?.vehiclesAttributes]);

  return (
    <div className="edit-driver-profile">
      <DefaultHeader
        breadcrumb={[
          { key: 1, name: 'Mi Perfil', href: '/driver/profile' },
          { key: 2, name: 'Editar perfil' }
        ]}
      />
      {onRequest && <SpinnerLoader animation="border" variant="primary" />}
      <div className={onRequest ? 'bg-opacity' : ''}>
        <ProfileDriverForm
          user={user}
          vehiclesExist={vehiclesExist}
          modelName="user[driverAttributes]"
          formRequest={handleUpdateRequest}
          onRequest={onRequest}
          bankList={bankList}
        />
      </div>
      <SuccessAlert
        message="Perfil editado"
        titlebtn="Entendido"
        onClickHide={() => {
          setShowAlert(false);
          setTimeout(() => {
            if (quotationId > 0) {
              history.push(`/driver/quotations/${quotationId}/new`);
            } else {
              history.push('/driver/profile');
            }
          }, 300);
        }}
        show={showAlert}
      />
    </div>
  );
};

export default ProfileDriverEdit;
