import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { DefaultHeader, DefaultModal, IcoMoon } from '../../../components';
import ComponentDataTable from '../../../components/Utils/DataTable';
import { Columns, conditionalDriverRows } from './data';
import SidebarFilter from '../../../components/Utils/Filter/SidebarFilter';
import {
  driverSolicitudeFilterParams,
  driverSolicitudeTabOptions
} from '../../../components/Solicitude/SolicitudeFilterParams';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';
import { debounceIndexSolicitudesRequest } from '../../../requests/solicitudes';
import showMeRequest from '../../../requests/auth';
import { useFetchData } from '../../../hooks';
import { emptyFalseParamsRecursive } from '../../../services/utils';

const DriverSolicitudeIndex = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const completeProfileUrl = `/driver/profile/edit`;
  const history = useHistory();
  const customParamsRef = useRef({ ...driverSolicitudeFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });
  const {
    data: solicituds,
    isFetching,
    setMoreData,
    handleIndexRequest,
    moreData
  } = useFetchData({
    debouncedIndexRequest: debounceIndexSolicitudesRequest,
    fetchingErrorMessage: 'Oops, ocurrió un problema al buscar tus solicitudes',
    customParams: {
      ...customParams,
      sort_direction: 'desc'
    }
  });
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const closeSidebar = () => setSidebarCollapsed(true);
  const toggleSidebar = () => setSidebarCollapsed(false);
  const modelUrl = '/driver/solicitudes';

  const handleRowClicked = row => {
    history.push(`${modelUrl}/${row.id}/offer`);
  };

  const onFilterCallback = () => {
    handleIndexRequest(snakeCaseKeys(customParams));
  };

  const goToProfileEdit = () => {
    history.push(completeProfileUrl);
  };

  const fetchVehicles = () => {
    showMeRequest({
      dispatch,
      successCallback: response => {
        const vehiclesLength =
          response.data.user_info.driver_attributes.vehicles_attributes
            ?.length > 0;
        setShowModal(!vehiclesLength);
      }
    });
  };

  useEffect(fetchVehicles, []);
  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <SidebarFilter
        collapsed={sidebarCollapsed}
        customParams={customParams}
        setCustomParams={setCustomParams}
        closeSidebar={closeSidebar}
        setMoreData={setMoreData}
        onFilterCallback={onFilterCallback}
      />
      <DefaultHeader
        title="Mis Ofertas"
        body={
          <div>
            <Button
              variant="secondary"
              className="outline mr-2"
              onClick={toggleSidebar}
              size="lg"
            >
              Filtros
            </Button>
          </div>
        }
      />
      <div className="data-table dt-client p-0 mb-4 dt-client-shippments dt-driver-offers">
        <ComponentDataTable
          onRequest={isFetching}
          columns={Columns()}
          data={solicituds}
          conditionalRowStyles={conditionalDriverRows}
          onRowClicked={handleRowClicked}
          moreData={moreData}
          resourceRequest={onFilterCallback}
          customDatatableHeader={
            <HeaderFilter
              customParams={customParams}
              setCustomParams={setCustomParams}
              paramName="driverFilterQuotation"
              tabOptions={driverSolicitudeTabOptions}
            />
          }
        />
      </div>
      <DefaultModal
        handleConfirm={() => goToProfileEdit()}
        title=""
        titleBtnClose="Cancelar"
        titleBtnSave="Agregar auto"
        onlyConfirmBtn
        variantBtnSave="primary"
        size="sm"
        body={
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex justify-content-center align-items-center car-logo">
              <IcoMoon size="45" icon="car" />
            </div>
            <p className="my-4 font-weight-bold" style={{ fontSize: '16px' }}>
              Debes agregar un auto para ofertar
            </p>
          </div>
        }
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </>
  );
};

export default DriverSolicitudeIndex;
