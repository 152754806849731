import React, { useState } from 'react';

import { DefaultModal } from '../components';

const useConfirmModal = ({ modalTitle, onConfirmCallback, modalBody }) => {
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleConfirmCallback = () => {
    onConfirmCallback();
    handleModalClose();
  };

  const ModalComponent = () => {
    return (
      <DefaultModal
        show={showModal}
        title={modalTitle}
        handleClose={handleModalClose}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        handleConfirm={handleConfirmCallback}
        body={modalBody}
      />
    );
  };

  return {
    ModalComponent,
    handleShowModal
  };
};

export default useConfirmModal;
