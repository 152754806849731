import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { LinkBtn, ButtonComponent } from '../Button';

const handleDelete = (
  arrayValues,
  setFieldValue,
  valuePath,
  index,
  setWithMethod
) => {
  const arrayValuesLength = arrayValues.length;
  const deleteValue = arrayValues[index];
  if (deleteValue.id && deleteValue !== '') {
    deleteValue._destroy = 'true';
    if (setWithMethod) {
      return setWithMethod(deleteValue);
    }
    return setFieldValue(`${valuePath}[${index}]`, deleteValue);
  }
  const newValues = [
    ...arrayValues.slice(0, index),
    ...arrayValues.slice(index + 1, arrayValuesLength)
  ];
  if (setWithMethod) {
    return setWithMethod(newValues);
  }
  return setFieldValue(`${valuePath}`, newValues);
};

const addRow = (
  arrayValues,
  setFieldValue,
  valuePath,
  newAttributes,
  setWithMethod
) => {
  const newValues = [...arrayValues, newAttributes];
  if (setWithMethod) {
    return setWithMethod(newValues);
  }
  return setFieldValue(`${valuePath}`, newValues);
};

const deleteButton = (
  arrayValues,
  setFieldValue,
  valuePath,
  index,
  setWithMethod,
  deleteBtnTooltipText,
  classNameDeleteBtn
) => {
  return (
    <>
      <ButtonComponent
        onClick={() =>
          handleDelete(
            arrayValues,
            setFieldValue,
            valuePath,
            index,
            setWithMethod
          )
        }
        typeButton="Icon"
        icon="close"
        className={`with-icon d-none d-md-block mt-n2 mb-5 ${index > 0 &&
          classNameDeleteBtn}`}
        iconSize={30}
        tooltipText={`${deleteBtnTooltipText} ${index + 1}`}
        variant="btn-transparent"
      />
      <ButtonComponent
        onClick={() =>
          handleDelete(
            arrayValues,
            setFieldValue,
            valuePath,
            index,
            setWithMethod
          )
        }
        typeButton="defaultButton"
        icon="close"
        className="btn-icon d-md-none mb-3 mt-2"
        iconSize={30}
        tooltipText={`${deleteBtnTooltipText} ${index + 1}`}
        variant="primary-outline"
      >
        {`N˚${index + 1}`}
      </ButtonComponent>
    </>
  );
};

// Esta pensado para funcionar con los values de formik, para efecto de funcionar en el ejemplo de componentes
// Lo voy a cambiar un poco
const NestedBody = ({
  arrayValues,
  body,
  deleteLastOne,
  index,
  inputLength,
  setFieldValue,
  shownLength,
  valuePath,
  setWithMethod,
  deleteBtnTooltipText,
  classNameDeleteBtn
}) => {
  const validLength = inputLength !== 0 && shownLength !== 0;
  return (
    <>
      <Col md={validLength ? 11 : 12} className={validLength && 'pr-md-0'}>
        {body}
      </Col>
      {body && (
        <Col
          md={1}
          className="d-flex justify-content-end justify-md-content-center align-items-start"
        >
          {deleteLastOne
            ? index === inputLength &&
              validLength &&
              deleteButton(
                arrayValues,
                setFieldValue,
                valuePath,
                index,
                setWithMethod,
                deleteBtnTooltipText,
                classNameDeleteBtn
              )
            : validLength &&
              deleteButton(
                arrayValues,
                setFieldValue,
                valuePath,
                index,
                setWithMethod,
                deleteBtnTooltipText,
                classNameDeleteBtn
              )}
        </Col>
      )}
    </>
  );
};
const NestedAttributes = ({
  setFieldValue,
  newAttributes,
  mapInputs,
  arrayValues,
  valuePath,
  addTitle,
  deleteLastOne,
  setWithMethod,
  deleteBtnTooltipText,
  classNameDeleteBtn
}) => {
  const inputLength = mapInputs.length - 1;
  const shownLength =
    mapInputs.filter(e => {
      return e !== undefined;
    }).length - 1;
  const nestedBody = mapInputs.map((body, index) => (
    <NestedBody
      key={`nested-${index.toString()}`}
      arrayValues={arrayValues}
      body={body}
      deleteLastOne={deleteLastOne}
      index={index}
      inputLength={inputLength}
      setFieldValue={setFieldValue}
      shownLength={shownLength}
      valuePath={valuePath}
      setWithMethod={setWithMethod}
      deleteBtnTooltipText={deleteBtnTooltipText}
      classNameDeleteBtn={classNameDeleteBtn}
    />
  ));
  return (
    <Row>
      {nestedBody}
      <Col md={12}>
        <LinkBtn
          variant="primary"
          to="#"
          onClick={() =>
            addRow(
              arrayValues,
              setFieldValue,
              valuePath,
              newAttributes,
              setWithMethod
            )
          }
        >
          {addTitle}
        </LinkBtn>
      </Col>
    </Row>
  );
};

export default NestedAttributes;
