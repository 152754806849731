import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { redirectByRole } from '../../services/utils';

const NonLoggedLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('jwt') ? (
        <Redirect
          to={{ pathname: redirectByRole(), state: { from: props.location } }}
        />
      ) : (
        <>
          <Container className="container-layout" fluid>
            <Component {...props} />
          </Container>
        </>
      )
    }
  />
);

export default NonLoggedLayout;
