import React, { useEffect, useRef } from 'react';

import Columns from '../../Solicitude/Quotation/Columns';
import ComponentDataTable from '../../Utils/DataTable';
import { useFetchData } from '../../../hooks';
import { debounceIndexQuotationsRequest } from '../../../requests/quotations';

const Quotations = ({ solicitudeId, modelUrl, expired }) => {
  const _isMounted = useRef(true);

  const {
    data,
    isFetching,
    moreData,
    totalData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexQuotationsRequest,
    withDataTable: true,
    customParams: { solicitude_id: solicitudeId, filter_active: 'true' }
  });

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <div className="data-table container-fluid border-rounded p-0 my-4 solicitude-quotation-table">
      <ComponentDataTable
        columns={Columns({ modelUrl, expired })}
        data={data}
        pagination={false}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        totalRows={totalData}
        onRequest={isFetching}
        subHeader={false}
      />
    </div>
  );
};

export default Quotations;
