import camelCaseRecursive from 'camelcase-keys-recursive';
import { utilsTypes } from '../actions/utils';

const initialState = {
  sidebarOpen: false,
  settings: {
    mainContactEmail: '',
    driverCommissionPercentage: '',
    BriiingCommissionPercentage: ''
  },
  slides: [],
  page: {
    body: null
  },
  alert: {
    show: false,
    title: '',
    kind: '',
    timeout: 3000,
    message: ''
  },
  ongoingRequest: {
    getNotifications: false,
    getUnreadNotifications: false,
    getSettings: false,
    getSlides: false,
    getPage: false
  },
  notifications: {
    data: [],
    unread: 0
  },
  showTripModal: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case utilsTypes.TOOGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen,
        showTripModal: false
      };
    case utilsTypes.SET_SIDEBAR:
      return {
        ...state,
        sidebarOpen: action.params.show
      };
    case utilsTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: true }
      };
    case utilsTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: camelCaseRecursive(action.result),
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SLIDES_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: true }
      };
    case utilsTypes.GET_SLIDES_SUCCESS:
      return {
        ...state,
        slides: action.result,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_SLIDES_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_PAGE_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: true },
        page: { body: null },
        url: action.url
      };
    case utilsTypes.GET_PAGE_SUCCESS:
      return {
        ...state,
        page: action.result,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.GET_PAGE_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          kind: action.kind,
          timeout: action.timeout || initialState.alert.timeout,
          message: action.message,
          title: action.title
        }
      };
    case utilsTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      };
    case utilsTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getNotifications: true }
      };
    case utilsTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: camelCaseRecursive(action.result.data)
        },
        ongoingRequest: { ...state.ongoingRequest, getNotifications: false }
      };
    case utilsTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getNotifications: false }
      };
    case utilsTypes.GET_UNREAD_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUnreadNotifications: true
        }
      };
    case utilsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: { ...state.notifications, unread: action.result },
        ongoingRequest: {
          ...state.ongoingRequest,
          getUnreadNotifications: false
        }
      };
    case utilsTypes.GET_UNREAD_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: {
          ...state.ongoingRequest,
          getUnreadNotifications: false
        }
      };
    case utilsTypes.OPEN_TRIP_MODAL:
      return {
        ...state,
        showTripModal: true
      };
    default:
      return state;
  }
};

export default reducer;
