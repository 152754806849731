import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ff5e62' : 'white',
    '&:hover': {
      backgroundColor: 'rgba(145, 145, 145, 0.415)',
      cursor: 'pointer'
    }
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: '15px',
    boxShadow: state.isFocused
      ? '0px 0px 3px 2px rgba(145, 145, 145, 0.455)'
      : 'none',
    borderColor: '#ced4da',
    '&:hover': {
      borderColor: '#ced4da'
    }
  })
};

const handleDefaultMultiValue = (value, defaultValue, defaultMultiValues) => {
  if (defaultValue && value && value === defaultValue)
    return defaultMultiValues;
  return undefined;
};

const findDefaultValue = (value, defaultValue, options, props) => {
  const { isMulti, defaultMultiValues } = props;
  if (isMulti) {
    return handleDefaultMultiValue(value, defaultValue, defaultMultiValues);
  }
  if (
    defaultValue &&
    value &&
    defaultValue !== '' &&
    value !== '' &&
    value === defaultValue
  ) {
    const vAttribute = options.filter(e => defaultValue === e.value);
    return vAttribute[0];
  }
  if (value === '') return null;
  return undefined;
};

const FormikSelect = ({
  options,
  label,
  abbr,
  customOption,
  error,
  touched,
  helpText,
  name,
  defaultValue,
  value,
  isDisabled,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  setFieldTouched,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group
      controlId={label}
      className={`${isDisabled ? 'disabled' : ''} ${
        error && touched ? 'is-invalid' : ''
      }`}
    >
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                ?
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <Select
        className={`${isOpen ? 'border-on ' : ''}`}
        placeholder="Buscar..."
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => {
          if (setFieldTouched) setFieldTouched();
          setIsOpen(false);
        }}
        loadingMessage={() => 'Cargando...'}
        noOptionsMessage={() => 'No hay opciones'}
        options={options}
        isSearchable
        name={name}
        value={findDefaultValue(value, defaultValue, options, props)}
        isDisabled={isDisabled}
        styles={customStyles}
        {...props}
      />
      {error && touched && (
        <Form.Text className="text-danger">{error}</Form.Text>
      )}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikSelect.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};

export default FormikSelect;
