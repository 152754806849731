import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import { useFetchData } from '../../../hooks';
import { debounceIndexQuotationsRequest } from '../../../requests/quotations';
import { ComponentDataTable, DefaultHeader } from '../../../components';
import CustomHeaderFilter from '../../../components/Utils/Filter/CustomHeaderFilter';
import { driverQuotationFilterParams } from '../../../components/Quotation/params';
import { Columns, conditionalDriverRows } from './ColumnsData';
import useCustomParams from '../../../hooks/useCustomParams';

const DriverQuotationIndex = () => {
  const { push } = useHistory();
  const { customParamsRef, customParams, setCustomParams } = useCustomParams({
    customFilters: driverQuotationFilterParams
  });
  const {
    data,
    totalData,
    handleIndexRequest,
    isFetching,
    moreData,
    setMoreData
  } = useFetchData({
    debouncedIndexRequest: debounceIndexQuotationsRequest,
    withDataTable: true,
    customParams: {
      ...customParamsRef.current,
      for_driver: true,
      filter_active: 'true',
      selected_filter: 'false'
    }
  });

  const onRowClicked = row => {
    const {
      solicitude: { id: solicitudeId }
    } = row;
    push(`/driver/solicitudes/${solicitudeId}/offer`);
  };

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <DefaultHeader
        title="Mis ofertas"
        tooltip="Ofertas que has realizado a solicitudes que han no se han adjudicado a ningún briiinger y siguen vigentes."
        body={
          <CustomHeaderFilter
            customParams={customParams}
            setCustomParams={setCustomParams}
            startDateField="dateFrom"
            endDateField="dateTo"
            pickupAddress="communeFrom"
            deliveryAddress="communeTo"
            queryPlaceholder="Buscar por nombre o precio"
          />
        }
      />
      <div className="client-solicitude-show">
        <div className="data-table dt-client p-0 mb-4 dt-client-shippments dt-driver-offers">
          <ComponentDataTable
            subHeader={false}
            data={data}
            totalRows={totalData}
            resourceRequest={handleIndexRequest}
            onRequest={isFetching}
            moreData={moreData}
            columns={Columns({ itIsSolicitude: false })}
            onRowClicked={onRowClicked}
            conditionalRowStyles={conditionalDriverRows({
              itIsSolicitude: false
            })}
          />
        </div>
      </div>
    </>
  );
};
export default DriverQuotationIndex;
