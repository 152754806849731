import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import {
  updateSolicitudeRequest,
  showSolicitudeRequest
} from '../../../requests/solicitudes';
import { SpinnerLoader, SuccessAlert } from '../../../components';
import { formatDateToEdit } from '../../../services/utils';
import ClientSolicitudeForm from './ClientSolicitudeForm';
import basicSolicitude from './solicitude';

const ClientSolicitudeEdit = ({ match }) => {
  const [solicitude, setSolicitude] = useState(basicSolicitude);
  const [onRequest, setOnRequest] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const _isMounted = useRef(true);
  const solicitudeId = match.params.id;

  const dateFormat = d => moment(formatDateToEdit(d)).toDate();

  const fetchSolicitude = () => {
    setOnRequest(true);
    showSolicitudeRequest(solicitudeId, {
      dispatch,
      successCallback: response => {
        if (_isMounted.current) {
          const itemsAttributes = response?.data?.items_attributes;
          const data = camelCaseRecursive(response.data);
          data.itemsAttributes = itemsAttributes;
          data.deleteItemsPhotos = [];

          if (data.pickupOption === 'soon_as_possible') {
            data.pickupStartDate = '';
            data.pickupEndDate = '';
          } else {
            data.pickupStartDate = dateFormat(data.pickupStartDate);
            data.pickupEndDate = dateFormat(data.pickupEndDate);
          }

          if (data.deliveryOption === 'soon_as_possible') {
            data.deliveryStartDate = '';
            data.deliveryEndDate = '';
          } else {
            data.deliveryStartDate = dateFormat(
              data.deliveryOption === 'on_date'
                ? data.deliveryEndDate
                : data.deliveryStartDate
            );
            data.deliveryEndDate = dateFormat(data.deliveryEndDate);
          }

          setSolicitude(data);
          setOnRequest(false);
        }
      }
    });
  };

  const handleUpdateRequest = values => {
    setOnRequest(true);
    const { itemsAttributes } = values.solicitude;
    const myParams = snakeCaseKeys(values);
    myParams.solicitude.items_attributes = itemsAttributes;

    updateSolicitudeRequest(solicitudeId, {
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => setShowAlert(true),
      callback: () => setOnRequest(false)
    });
  };

  useEffect(() => {
    fetchSolicitude();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <div className="container-client-solicitude">
      {onRequest && <SpinnerLoader animation="border" variant="secondary" />}
      <div className={onRequest ? 'bg-opacity' : ''}>
        <ClientSolicitudeForm
          solicitude={solicitude}
          action="edit"
          formRequest={handleUpdateRequest}
        />
      </div>
      <SuccessAlert
        titlebtn="Entendido"
        onClickHide={() => {
          setShowAlert(false);
          setTimeout(() => {
            history.push('/client/home');
          }, 300);
        }}
        show={showAlert}
      />
    </div>
  );
};

export default ClientSolicitudeEdit;
