import React, { useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { DefaultHeader } from '../../../components';
import { validateToken } from '../../../actions/auth';
import {
  ProfileFirstCol,
  ProfileSecondCol,
  ProfileSessionInfo
} from '../../../components/Profile';

const ProfileClient = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const {
    clientAttributes,
    completenessClient,
    avatarProfile,
    email,
    countDays
  } = camelCaseRecursive(user);

  useEffect(() => {
    dispatch(validateToken());
  }, []);

  return (
    <div className="edit-driver-profile">
      <DefaultHeader
        title="Mi Perfil"
        firstColXs={6}
        body={
          <Button as={Link} to="/client/profile/edit" variant="secondary">
            Editar
          </Button>
        }
      />
      <Row className="mt-4">
        <ProfileFirstCol
          module="client"
          avatarProfileUrl={avatarProfile?.fileUrlMinified}
          fullName={clientAttributes?.fullName}
          email={email}
          countDays={countDays}
          completeness={completenessClient}
          phone={clientAttributes?.phone}
        />
        <ProfileSecondCol
          description={clientAttributes?.description}
          frontIdentityUrl={clientAttributes?.frontIdentityImg.fileUrlMinified}
          frontIdentityImgFullRes={clientAttributes?.frontIdentityImg.fileUrl}
          backIdentityUrl={clientAttributes?.backIdentityImg.fileUrlMinified}
          backIdentityImgFullRes={clientAttributes?.backIdentityImg.fileUrl}
        />
      </Row>
      <ProfileSessionInfo user={camelCaseRecursive(user)} />
    </div>
  );
};

export default ProfileClient;
