import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DriverItemCell from '../../TableCells/DriverItemCell';
import StatusBox from './StatusBox';

const Route = ({
  itemsAttributes,
  pickupAddressAttributes,
  deliveryAddressAttributes,
  pickupPersonName,
  pickupPersonPhone,
  deliveryPersonName,
  deliveryPersonPhone,
  solicitude
}) => {
  return (
    <div className="route-card d-flex w-100 p-3 shadow-black my-2 ">
      {itemsAttributes?.length > 0 && (
        <Row className="d-flex w-100 justify-content-beetwen">
          <Col sm={12} md={4} className="d-flex w-100 flex-column pt-4 ">
            {itemsAttributes?.map(item => (
              <DriverItemCell
                key={item.id}
                itemName={item.name}
                height={item.height}
                length={item.length}
                width={item.width}
                weight={item.weight}
                imageUrl={item.itemImages[0]?.fileUrlMinified}
                isFragile={item.isFragile}
              />
            ))}
          </Col>
          <StatusBox
            pickupPersonName={pickupPersonName}
            pickupPersonPhone={pickupPersonPhone}
            deliveryPersonName={deliveryPersonName}
            deliveryPersonPhone={deliveryPersonPhone}
            pickupAddressAttributes={pickupAddressAttributes}
            deliveryAddressAttributes={deliveryAddressAttributes}
            quotation={solicitude.selectedQuotation}
            routeStatusBox
          />
        </Row>
      )}
    </div>
  );
};

export default Route;
