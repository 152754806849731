import React from 'react';
import memoize from 'memoize-one';
import IconCell from '../../../components/TableCells/IconCell';
import IsTodayCell from '../../../components/TableCells/IsTodayCell';
import DateCell from '../../../components/TableCells/DateCell';
import QuotationCell from '../../../components/TableCells/QuotationCell';
import DriverItemCell from '../../../components/TableCells/DriverItemCell';

const Columns = memoize(() => {
  return [
    {
      name: 'Icon',
      grow: 0.1,
      sortable: false,
      selector: row => row.icon,
      cell: ({ id, icon, hasQuotation, iconText, amIBestQuotation }) => (
        <IconCell
          id={id}
          icon={icon}
          hasQuotation={hasQuotation}
          module="driver"
          message={iconText}
          userHasBestQuotation={amIBestQuotation}
        />
      )
    },
    {
      name: 'Nombre Item',
      grow: 1.8,
      sortable: false,
      selector: row => row.itemName,
      cell: ({ id, itemName, itemsAttributes }) => (
        <DriverItemCell
          id={id}
          itemName={itemName}
          height={itemsAttributes[0]?.height}
          length={itemsAttributes[0]?.length}
          width={itemsAttributes[0]?.width}
          weight={itemsAttributes[0]?.weight}
          imageUrl={itemsAttributes[0].itemImages[0]?.fileUrlMinified}
          isFragile={itemsAttributes[0]?.isFragile}
        />
      )
    },
    {
      name: 'Fecha Viaje',
      grow: 1.8,
      sortable: false,
      selector: row => row.startDate,
      cell: ({
        id,
        deliveryAddressAttributes,
        pickupAddressAttributes,
        hasQuotation,
        startDate,
        endDate,
        hasMyOffer,
        pickupOption,
        deliveryOption
      }) => (
        <DateCell
          id={id}
          endLocation={deliveryAddressAttributes.communeName}
          startLocation={pickupAddressAttributes.communeName}
          startDate={startDate}
          endDate={endDate}
          hasQuotation={hasQuotation}
          module="driver"
          hasMyQuotation={hasMyOffer}
          pickupOption={pickupOption}
          deliveryOption={deliveryOption}
        />
      )
    },
    {
      name: row => (row?.hasTrip ? 'Nombre Cliente' : 'Cotizaciones'),
      grow: 1.7,
      sortable: false,
      selector: row => (row?.hastrip ? row.clientName : row?.quotationCount),
      cell: ({
        id,
        hasQuotation,
        hasMyOffer,
        offerCountMessage,
        quotationCount
      }) => {
        return (
          <QuotationCell
            id={id}
            hasQuotation={hasQuotation}
            module="driver"
            hasMyOffer={hasMyOffer}
            quotationCount={quotationCount}
            quotationMessage={offerCountMessage}
          />
        );
      }
    },
    {
      name: 'IsToday',
      grow: 1,
      sortable: false,
      selector: row => row.isToday,
      cell: ({ id, pickupAt }) => <IsTodayCell id={id} dateToCheck={pickupAt} />
    }
  ];
});

const conditionalDriverRows = [
  {
    when: ({ icon }) => icon === 'flame',
    classNames: ['bg-green']
  },
  {
    when: ({ icon }) => icon === 'files',
    classNames: ['hover-green']
  },
  {
    when: ({ icon, amIBestQuotation }) =>
      icon === 'shipping' && !amIBestQuotation,
    classNames: ['hover-yellow']
  },
  {
    when: ({ icon, amIBestQuotation }) =>
      icon === 'shipping' && amIBestQuotation,
    classNames: ['hover-disabled']
  }
];

export { Columns, conditionalDriverRows };
