import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { openTripModal } from '../../../actions/utils';
import IcoMoon from '../Icon/IcoMoon';

const SidebarDriver = ({ closeSidebar, pathName, location }) => {
  const isClient = localStorage.getItem('loginRole') === 'client';
  const home = location.pathname === '/client/home';
  const dispatch = useDispatch();

  const setActiveLink = links => {
    if (links.includes(pathName)) return pathName;
    return '';
  };

  return (
    <div className="container-links">
      <Nav
        className="flex-column"
        activeKey={pathName}
        defaultActiveKey="/driver/home"
      >
        <Nav.Item>
          <Nav.Link
            eventKey={setActiveLink(['home', 'solicitudes'])}
            as={Link}
            to="/driver/home"
            className={`${isClient && home ? 'client' : 'driver'}`}
            onClick={closeSidebar}
          >
            <IcoMoon icon="home1" />
            <span className="d-inline-block m-2">Inicio</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="d-lg-none">
          <Nav.Link
            className={`${isClient && home ? 'client' : 'driver'}`}
            onClick={() => {
              dispatch(openTripModal());
              closeSidebar();
            }}
          >
            <IcoMoon icon="files" />
            <span className="d-inline-block m-2">Crear viaje</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="shipments"
            as={Link}
            to="/driver/shipments"
            className={`${isClient ? 'client' : 'driver'}`}
            onClick={closeSidebar}
          >
            <IcoMoon icon="car" />
            <span className="d-inline-block m-2">Mis Briiing</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="quotations"
            as={Link}
            to="/driver/quotations"
            className={`${isClient ? 'client' : 'driver'}`}
            onClick={closeSidebar}
          >
            <IcoMoon icon="shipping" />
            <span className="d-inline-block m-2">Mis ofertas</span>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="trips"
            as={Link}
            to="/driver/trips"
            className={`${isClient ? 'client' : 'driver'}`}
            onClick={closeSidebar}
          >
            <IcoMoon icon="road" />
            <span className="d-inline-block m-2">Mis viajes</span>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default SidebarDriver;
