import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Spinner, Image, Button } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { commitPaymentRequest } from '../../requests/payments';
import { showSolicitudeRequest } from '../../requests/solicitudes';
import { sendAlert } from '../../actions/utils';
import MianLogo from '../../components/Utils/MainLogo';
import confetti from '../../assets/images/gif/confetti-outline.gif';

const PaymentOrderConfirmation = () => {
  const [onRequest, setOnRequest] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token_ws');
  const solicitudeId = new URLSearchParams(location.search).get(
    'solicitude_id'
  );
  const quotationId = new URLSearchParams(location.search).get('quotation_id');
  const [solicitude, setSolicitude] = useState(undefined);
  const modelUrl = `/client/solicitudes/${solicitudeId}`;
  const [data, setData] = useState(false);
  const { responseCode, status } = Boolean(data) && data;
  const isAuthorized = status === 'AUTHORIZED' && responseCode === 0;

  const handleFetchSolicitude = () => {
    setOnRequest(true);
    showSolicitudeRequest(solicitudeId, {
      dispatch,
      successCallback: response => {
        setSolicitude(camelCaseRecursive(response.data));
      }
    });
    setOnRequest(false);
  };

  const handleCommitPayment = () => {
    setOnRequest(true);
    const sendParams = { token_ws: token, quotation_id: quotationId };
    commitPaymentRequest({
      dispatch,
      params: sendParams,
      successCallback: response => {
        setData(camelCaseRecursive(response.data));
        setOnRequest(false);
      },
      failureCallback: () => setOnRequest(false)
    });
  };

  useEffect(() => {
    handleFetchSolicitude();
  }, [data]);

  useEffect(() => {
    if (token && solicitudeId) {
      handleCommitPayment();
    } else {
      dispatch(
        sendAlert({ kind: 'error', message: 'Debes brindar el token del pago' })
      );
    }
  }, []);

  return (
    <div className="payment-confirmation">
      {onRequest ? (
        <Spinner animation="border" variant="info" className="mx-auto" />
      ) : (
        <>
          {token && solicitudeId && (
            <>
              <div className="payment-card shadow-black m-4">
                <div className="payment-header">
                  <MianLogo />
                  {isAuthorized ? (
                    <Image
                      src={confetti}
                      alt="Logo"
                      className="d-inline-block align-top"
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className="w-100 h-100 p-4">
                  <h5 className="text-secondary">
                    {isAuthorized ? 'Pago exitoso' : 'El pago no fue realizado'}
                  </h5>

                  {isAuthorized ? (
                    <>
                      <hr />
                      <p className="text-secondary font-weight-bold">
                        Datos del pago
                      </p>
                      <p>
                        <strong>Total pagado:</strong>{' '}
                        {data?.amount?.toLocaleString()}
                      </p>
                      <p>
                        <strong>Tarjeta de crédito terminada en:</strong>{' '}
                        {data?.cardDetail?.cardNumber}
                      </p>
                      <p>
                        <strong>Fecha transacción:</strong>{' '}
                        {data?.transactionDate}
                      </p>
                      <p>
                        <strong> Código autorización: </strong>
                        {data?.authorizationCode}
                      </p>

                      <hr />
                      <p className="text-secondary font-weight-bold">
                        Datos de solicitud
                      </p>
                      <p>
                        <strong> ID Solicitud:</strong> {solicitudeId}
                      </p>
                      <p>
                        <strong> Nombre ítem:</strong> {solicitude?.itemName}
                      </p>
                      <p>
                        <strong> Dirección de retiro:</strong>{' '}
                        {solicitude?.pickupAddressAttributes?.streetName}
                      </p>
                      <p>
                        <strong> Fecha inicial de retiro:</strong>{' '}
                        {solicitude?.selectedQuotation?.pickupAt}
                      </p>
                      <p>
                        <strong> Direccion de entrega:</strong>{' '}
                        {solicitude?.deliveryAddressAttributes?.streetName}
                      </p>
                      <p>
                        <strong> Fecha final de entrega:</strong>{' '}
                        {solicitude?.selectedQuotation?.deliveryAt}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        ¡Ups! Algo salió mal con el pago. Por favor reintententa
                        más tarde.
                      </p>
                    </>
                  )}

                  <hr />
                  <Button
                    as={Link}
                    type="submit"
                    variant="secondary"
                    className="my-4 float-right"
                    to={modelUrl}
                  >
                    Volver a la solicitud
                  </Button>
                </div>
              </div>
            </>
          )}
          {!(token && solicitudeId) && null}
        </>
      )}
    </div>
  );
};

export default PaymentOrderConfirmation;
