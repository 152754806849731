import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexTripRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', `/trips${format || ''}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showTripRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/trips/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createTripRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', `/trips`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateTripRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/trips/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const debounceIndexTripsRequest = AwesomeDebouncePromise(
  indexTripRequest,
  300
);
