import React from 'react';
import { Image } from 'react-bootstrap';
import logo from './logo.png';

const MainLogo = () => (
  <Image
    src={logo}
    alt="Logo Logo Briiing!"
    className="d-inline-block align-top"
  />
);

export default MainLogo;
