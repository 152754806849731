import React from 'react';
import { Form } from 'react-bootstrap';
import IcoMoon from '../Icon/IcoMoon';

const FormikInput = ({
  placeholder,
  label,
  size,
  margin,
  error,
  touched,
  helpText,
  inputType,
  abbr,
  icon,
  iconSize,
  iconColor,
  iconClick,
  classNameIcon,
  ...props
}) => (
  <Form.Group className={margin}>
    {label ? (
      <Form.Label>
        {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
      </Form.Label>
    ) : (
      false
    )}
    <Form.Control
      size={size}
      placeholder={placeholder}
      type={inputType}
      className={error && touched ? 'is-invalid' : ''}
      {...props}
    />
    {icon && !error && (
      <div className={`container-icon ${classNameIcon}`}>
        <IcoMoon
          onClick={() => iconClick()}
          size={iconSize}
          color={iconColor}
          icon={icon}
        />
      </div>
    )}
    {error && touched ? (
      <Form.Text className="text-danger">{error}</Form.Text>
    ) : null}
    {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
  </Form.Group>
);

export default FormikInput;
