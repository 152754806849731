import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { capitalizeString } from '../../../services/utils';

const TabFilters = ({ label, optionsArray, paramName, setCustomParams }) => {
  const loginRole = localStorage.getItem('loginRole');
  const variant = loginRole === 'client' ? 'secondary' : 'primary';
  const [selectedTab, setSelectedTab] = useState(optionsArray[0].value);

  const handleClick = value => {
    setSelectedTab(value);
    setCustomParams(params => ({
      ...params,
      page: 0,
      [paramName]: value || ''
    }));
  };

  return (
    <section className="pt-1 ml-n2">
      {label && <p className="font-weight-bold">{label}</p>}
      <div className="tab-filter-container">
        {optionsArray.map(item => (
          <Button
            key={item.value}
            data-target={item.value}
            variant={variant}
            className={`${
              selectedTab === item.value ? '' : 'outline'
            } tab-filter-button `}
            onClick={() => handleClick(item.value, item.key)}
          >
            {item.label
              ? capitalizeString(item.label)
              : capitalizeString(item.value)}
          </Button>
        ))}
      </div>
    </section>
  );
};

TabFilters.defaultProps = {
  buttonsVariant: 'secondary-blue',
  optionsArray: [],
  paramName: '',
  setCustomParams: () => null
};

export default TabFilters;
