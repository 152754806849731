import React from 'react';
import { Row } from 'react-bootstrap';
import DashboardGreeting from './DashboardGreeting';
import DashboardInfoBox from './DashboardInfoBox';

const DashboardHeader = ({
  name,
  driver,
  countDays,
  dashboardData,
  stars,
  profilePercentage,
  userInfo
}) => {
  return (
    <Row className="m-0">
      <DashboardGreeting
        name={name}
        countDays={countDays}
        stars={stars}
        profilePercentage={profilePercentage}
      />
      <DashboardInfoBox
        userInfo={userInfo}
        driver={driver}
        title={driver ? 'Ganancia Trimestral' : 'N° envíos realizados'}
        icon={driver ? 'smile' : 'car'}
        total={
          dashboardData[
            driver ? 'totalMoneyByQuarter' : 'shippmentsDoneCount'
          ] || 0
        }
      />
      <DashboardInfoBox
        showModal={driver}
        userInfo={userInfo}
        driver={driver}
        title={driver ? 'Monto por cobrar' : 'N° solicitudes'}
        icon={driver ? 'money' : 'shipping'}
        total={
          dashboardData[driver ? 'amountReceivable' : 'solicitudeCount'] || 0
        }
      />
    </Row>
  );
};

export default DashboardHeader;
