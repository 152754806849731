import React from 'react';
import { Route } from 'react-router-dom';
import { Container, NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NavbarTop, NavbarLogged, Footer } from '../../components';
import { requestSignOut } from '../../actions/auth';

const PublicLayout = ({ component: Component, hideNavigation, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('jwt') ? (
          <>
            {!hideNavigation ? (
              <>
                <NavbarLogged>
                  <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>
                    Cerrar Sesión
                  </NavDropdown.Item>
                </NavbarLogged>
                <Container className="container-layout" fluid>
                  <Component {...props} />
                </Container>
                <Footer />
              </>
            ) : (
              <Component {...props} />
            )}
          </>
        ) : (
          <>
            <NavbarTop />
            <Container className="container-layout" fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        )
      }
    />
  );
};

export default PublicLayout;
