import React, { useState, useEffect } from 'react';
import moment from 'moment';

const PickUpDeliveryCell = ({
  delivery,
  originDetour,
  destinyDetour,
  startDate,
  endDate
}) => {
  const [namePoint, setNamePoint] = useState('Comienza');
  const [date, setDate] = useState(startDate);
  const [detour, setDetour] = useState(originDetour);

  useEffect(() => {
    if (delivery) {
      setNamePoint('Termina');
      setDate(endDate);
      setDetour(destinyDetour);
    }
  }, []);

  return (
    <div
      data-tag="allowRowEvents"
      className="d-fex flex-column align-items-start justify-content-center"
    >
      <p data-tag="allowRowEvents">
        <strong data-tag="allowRowEvents">{namePoint}</strong>{' '}
        {moment(date).format('DD/MM/YYYY')}
      </p>
      <p data-tag="allowRowEvents">Desvío: {detour}km</p>
    </div>
  );
};

export default PickUpDeliveryCell;
