import * as Yup from 'yup';

const quotationSchema = {
  driverCommissionAmount: Yup.string().required(
    'Debes ingresar el valor a cobrar.'
  ),
  vehicleId: Yup.string().required(
    'Debes elegir al menos uno de tus vehículos'
  ),
  pickupAt: Yup.string().required('Debes ingresar el fecha de retiro.'),
  deliveryAt: Yup.string().required('Debes ingresar la fecha de entrega.')
};

export default quotationSchema;
