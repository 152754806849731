import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toogleSidebar } from '../../../actions/utils';
import MainLogo from '../MainLogo';
import { ButtonComponent } from '../Button';
import IcoMoon from '../Icon/IcoMoon';

const PrincipalNavbar = ({ children, notificationButton }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/');
  const publicRoutes = ['', 'components', 'contact', 'page'];
  const { user } = useSelector(state => state.auth);
  const loginRole = localStorage.getItem('loginRole');

  return (
    <Navbar
      bg="light"
      expand="lg"
      fixed="top"
      className={`navbar ${loginRole === 'client' ? 'client' : 'driver'}`}
    >
      <Navbar.Brand as={Link} to="/" className="navbar-brand">
        <MainLogo />
      </Navbar.Brand>
      {user.id > 0 && !publicRoutes.includes(path[1]) ? (
        <>
          <div className="ml-auto d-lg-none">{notificationButton}</div>
          <ButtonComponent
            typeButton="Icon"
            icon="menu"
            iconSize={32}
            className="hamburger-menu icon"
            variant="outline-info"
            iconColor="black"
            onClick={() => dispatch(toogleSidebar())}
          />
        </>
      ) : (
        <Navbar.Toggle className="p-0" aria-controls="basic-navbar-nav">
          <IcoMoon icon="menu" size={32} />
        </Navbar.Toggle>
      )}
      {children}
    </Navbar>
  );
};

export default PrincipalNavbar;
