import React from 'react';
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import confetti from '../../../assets/images/gif/confetti-outline.gif';

const SuccessRegister = () => {
  return (
    <>
      <div className="success-register d-flex justify-content-center align-items-center flex-column opacity-animation">
        <Image
          src={confetti}
          alt="Logo"
          className="d-inline-block align-top confetti"
        />
        <h1>¡Felicitaciones!</h1>
        <p className="m-4">
          Te recordamos que para poder cotizar debes terminar de rellenar los
          datos obligatorios{' '}
        </p>
        <Button as={Link} to="/driver/home" variant="light">
          Ver las ofertas!!
        </Button>
      </div>
    </>
  );
};

export default SuccessRegister;
