import React from 'react';
import ImageUploading from 'react-images-uploading';
import IcoMoon from '../Icon';

const InputImage = ({
  getImages,
  images,
  destroyImage,
  imageKey = 'file_url',
  ...props
}) => {
  const maxNumber = 5;

  const onChange = imageList => getImages(imageList);

  return (
    <div className="container-input-img multiple">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey={imageKey}
        {...props}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          <>
            {images?.length === 0 && (
              <div className="img-box multiple">
                {[1, 2].map(n => (
                  <div
                    key={`n-${n.toString()}`}
                    className={`box ${isDragging ? 'dragging' : ''}`}
                    onClick={onImageUpload}
                    onKeyDown={onImageUpload}
                    role="button"
                    tabIndex={0}
                    {...dragProps}
                  >
                    <IcoMoon icon="add" size={40} />
                  </div>
                ))}
              </div>
            )}
            <div className="container-multiple-img">
              {imageList.map((image, index) => (
                <div key={`n-${index.toString()}`} className="box-multiple-img">
                  <img src={image[imageKey]} alt={`img-${image?.name}`} />
                  <div className="multiple-name-container">
                    <span className="text-truncate">
                      {image?.name || image?.filename}
                    </span>
                    <IcoMoon
                      icon="close"
                      size={20}
                      onClick={() => {
                        onImageRemove(index);
                        if (image.id && destroyImage) {
                          destroyImage(image.id);
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
              {images?.length > 0 && images?.length < maxNumber && (
                <div className="img-box multiple">
                  <div
                    className={`box ${isDragging ? 'dragging' : ''}`}
                    onClick={onImageUpload}
                    onKeyDown={onImageUpload}
                    role="button"
                    tabIndex={0}
                    {...dragProps}
                  >
                    <IcoMoon icon="add" size={40} />
                  </div>
                </div>
              )}
            </div>
            {images?.length > 0 ? (
              <div className="name-container d-flex align-items-center mb-3 mt-4">
                <button
                  type="button"
                  className="name-img btn-img text-truncate"
                  onClick={() => {
                    onImageRemoveAll();
                    if (destroyImage) destroyImage(imageList.map(i => i.id));
                  }}
                >
                  Eliminar todas las imágenes
                </button>
              </div>
            ) : (
              <div className="name-container d-flex align-items-center mb-3 mt-4">
                <button
                  type="button"
                  className="name-img btn-img text-truncate"
                  onClick={onImageUpload}
                >
                  Subir imágenes
                </button>
              </div>
            )}
          </>
        )}
      </ImageUploading>
    </div>
  );
};

export default InputImage;
