import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ImageWithZoom from '../../Utils/Image/ImageWithZoom';

const CarDescription = ({ images, carName, licensePlate, dimensions }) => {
  return (
    <>
      <Row className="mx-0 p-0 mb-2 mt-2">
        <Col md={6} className="p-0">
          <h6 className="font-weight-bold mt-n1 mb-1">Auto:</h6>
          <p className="" style={{ fontWeight: 300, fontSize: '16px' }}>
            {carName}
          </p>
          <p className="text-uppercase" style={{ fontSize: '16px' }}>
            {licensePlate}
          </p>
          {dimensions && <p style={{ fontSize: '16px' }}>{dimensions}</p>}
        </Col>
        <Col
          md={6}
          className={`images-scrollable p-0 ${
            images?.length === 0 ? 'd-flex justify-content-md-end' : ''
          }`}
        >
          {images?.length === 0 && <div className="empty_img-container" />}
          <div className="d-flex align-items-center mt-1 mb-2 mb-md-0">
            {images?.length > 0 &&
              images?.map((img, index) => (
                <div key={`${index.toString()}-img`} className="car-img-modal">
                  <ImageWithZoom
                    src={img.fileUrlMinified}
                    fullSizeImage={img.fileUrl}
                  />
                </div>
              ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CarDescription;
