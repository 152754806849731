import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { withFormik, Field, Form, getIn } from 'formik';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  FormikInput,
  FormikCheckBox,
  AddressFields,
  DefaultHeader,
  FormikNumberFormat
} from '../../../components';
import { FormikDatePicker } from '../../../components/Utils/Input';
import UrgencyButtons from './UrgencyButtons';
import WarningCard from './WarningCard';
import ItemsAttributes from '../../../components/Solicitude/ItemsAttributes';

const ClientSolicitudeForm = ({
  errors,
  touched,
  values,
  setFieldValue,
  action
}) => {
  const [closeWarningCard, setCloseWarningCard] = useState(true);
  const [urgencyBtnSelected, setUrgencyBtnSelected] = useState({
    left: false,
    right: false
  });
  const [urgencyTypeLeft, setUrgencyTypeLeft] = useState('');
  const [urgencyTypeRight, setUrgencyTypeRight] = useState('');
  const { settings } = useSelector(state => state.utils);
  const isType = type => type.split('-')[0];
  const isEdit = action === 'edit';

  const {
    itemsAttributes,
    pickupStartDate,
    pickupEndDate,
    deliveryStartDate,
    deliveryEndDate,
    pickupOption,
    deliveryOption,
    deleteItemsPhotos
  } = values.solicitude;

  const pickupOptionError = getIn(errors, 'solicitude[pickupOption]');
  const deliveryOptionError = getIn(errors, 'solicitude[deliveryOption]');
  const pickupStartDateTouched = getIn(touched, 'solicitude[pickupStartDate]');
  const pickupEndDateTouched = getIn(touched, 'solicitude[pickupEndDate]');
  const deliveryEndDateTouched = getIn(touched, 'solicitude[deliveryEndDate]');
  const deliveryStartDateTouched = getIn(
    touched,
    'solicitude[deliveryStartDate]'
  );

  const renderUrgencyInputLeft = () => {
    const fieldPickupStartDate = 'solicitude[pickupStartDate]';
    const fieldPickupEndDate = 'solicitude[pickupEndDate]';

    if (urgencyTypeLeft === 'left-between_range') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de inicio"
              selected={pickupStartDate || new Date()}
              placeholderText="Seleccionar inicio"
              minDate={new Date()}
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldPickupStartDate, date);
              }}
              error={getIn(errors, fieldPickupStartDate)}
              touched={getIn(touched, fieldPickupStartDate)}
            />
          </Col>
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de término"
              selected={pickupEndDate}
              placeholderText="Seleccionar término"
              minDate={pickupStartDate || new Date()}
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldPickupEndDate, date);
              }}
              error={getIn(errors, fieldPickupEndDate)}
              touched={getIn(touched, fieldPickupEndDate)}
            />
          </Col>
        </Row>
      );
    }
    if (urgencyTypeLeft === 'left-on_date') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha"
              selected={pickupStartDate || new Date()}
              minDate={new Date()}
              dateFormat="dd-MM-yyyy"
              placeholderText="Seleccionar un día"
              onChange={date => {
                setFieldValue(fieldPickupStartDate, date);
                setFieldValue(fieldPickupEndDate, date);
              }}
              error={getIn(errors, fieldPickupStartDate)}
              touched={getIn(touched, fieldPickupEndDate)}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderUrgencyInputRight = () => {
    const fieldDeliveryStartDate = 'solicitude[deliveryStartDate]';
    const fieldDeliveryEndDate = 'solicitude[deliveryEndDate]';

    if (urgencyTypeRight === 'right-between_range') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de inicio"
              selected={deliveryStartDate || new Date()}
              placeholderText="Seleccionar inicio"
              minDate={new Date()}
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldDeliveryStartDate, date);
              }}
              error={getIn(errors, fieldDeliveryStartDate)}
              touched={getIn(touched, fieldDeliveryStartDate)}
            />
          </Col>
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha de término"
              selected={deliveryEndDate}
              placeholderText="Seleccionar término"
              minDate={deliveryStartDate || new Date()}
              dateFormat="dd-MM-yyyy"
              onChange={date => {
                setFieldValue(fieldDeliveryEndDate, date);
              }}
              error={getIn(errors, fieldDeliveryEndDate)}
              touched={getIn(touched, fieldDeliveryEndDate)}
            />
          </Col>
        </Row>
      );
    }
    if (urgencyTypeRight === 'right-on_date') {
      return (
        <Row className="mt-fields mb-n1">
          <Col md={12} xl={6} className="pr-fix">
            <FormikDatePicker
              abbr
              label="Fecha"
              selected={deliveryStartDate || new Date()}
              minDate={new Date()}
              dateFormat="dd-MM-yyyy"
              placeholderText="Seleccionar un día"
              onChange={date => {
                setFieldValue(fieldDeliveryStartDate, date);
                setFieldValue(fieldDeliveryEndDate, date);
              }}
              error={getIn(errors, fieldDeliveryStartDate)}
              touched={getIn(touched, fieldDeliveryStartDate)}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const currentStartDate = () => {
    setFieldValue('solicitude[pickupStartDate]', moment().toDate());
    setFieldValue('solicitude[deliveryStartDate]', moment().toDate());
  };
  useEffect(currentStartDate, []);

  useEffect(() => {
    if (!isEdit) {
      if (urgencyTypeRight === 'right-on_date') {
        setFieldValue('solicitude[deliveryEndDate]', moment().toDate());
      }
      if (urgencyTypeLeft === 'left-on_date') {
        setFieldValue('solicitude[pickupEndDate]', moment().toDate());
      }
    }
  }, [urgencyTypeRight, urgencyTypeLeft]);

  useEffect(() => {
    if (isEdit) {
      setUrgencyTypeLeft(`left-${pickupOption}`);
      setUrgencyTypeRight(`right-${deliveryOption}`);
    }
  }, [pickupOption, deliveryOption]);

  return (
    <>
      <DefaultHeader
        breadcrumb={[
          { key: 1, name: 'Inicio', href: '/client/home' },
          { key: 2, name: isEdit ? 'Editar solicitud' : 'Nueva solicitud' }
        ]}
      />
      <Form className="form-client-solicitude">
        <ItemsAttributes
          itemsAttributes={itemsAttributes}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          deleteItemsPhotos={deleteItemsPhotos}
        />
        <hr className="my-5" />
        <Row>
          <Col md={6} className="br-client pr-md-5 mb-5 mb-md-0">
            <h6 className="mb-1">Retiro</h6>
            <AddressFields
              modelName="solicitude[pickupAddressAttributes]"
              streetNamePlaceHolder="retiro"
              values={values}
              errors={errors}
              touched={touched}
            />
            <Row>
              <Col xl={6}>
                <Field name="solicitude[pickupPersonName]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="text"
                      label="Nombre quien entrega"
                      placeholder="Nombre completo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col xl={6}>
                <Field name="solicitude[pickupPersonPhone]">
                  {({ field }) => (
                    <FormikNumberFormat
                      {...field}
                      abbr
                      phoneFormat
                      label="Celular"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <div className="ml-3 w-100">
                <h6 className="mt-3">Necesito que lo retiren...</h6>
                <UrgencyButtons
                  side="left"
                  rangeOptions={[
                    'soon_as_possible',
                    'between_range',
                    'on_date'
                  ]}
                  setFieldValue={setFieldValue}
                  modelValues={values.solicitude}
                  modelName="solicitude"
                  setUrgencyBtnSelected={setUrgencyBtnSelected}
                  selectedType={type => {
                    if (isType(type) === 'left') setUrgencyTypeLeft(type);
                  }}
                />
                {pickupOptionError &&
                  !urgencyBtnSelected.left &&
                  (pickupStartDateTouched || pickupEndDateTouched) && (
                    <small className="text-danger mb-n1">
                      {pickupOptionError}
                    </small>
                  )}
                {renderUrgencyInputLeft()}
              </div>
            </Row>
          </Col>
          <Col md={6} className="pl-md-5">
            <h6 className="mb-1">Entrega</h6>
            <AddressFields
              modelName="solicitude[deliveryAddressAttributes]"
              streetNamePlaceHolder="entrega"
              values={values}
              errors={errors}
              touched={touched}
            />
            <Row>
              <Col xl={6}>
                <Field name="solicitude[deliveryPersonName]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="text"
                      label="Nombre quien recibe"
                      placeholder="Nombre completo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col xl={6}>
                <Field name="solicitude[deliveryPersonPhone]">
                  {({ field }) => (
                    <FormikNumberFormat
                      {...field}
                      abbr
                      phoneFormat
                      label="Celular"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <div className="ml-3 w-100">
                <h6 className="mt-3">Necesito que lo entreguen...</h6>
                <UrgencyButtons
                  side="right"
                  rangeOptions={[
                    'soon_as_possible',
                    'between_range',
                    'on_date'
                  ]}
                  setFieldValue={setFieldValue}
                  modelValues={values.solicitude}
                  modelName="solicitude"
                  setUrgencyBtnSelected={setUrgencyBtnSelected}
                  selectedType={type => {
                    if (isType(type) === 'right') setUrgencyTypeRight(type);
                  }}
                />
                {deliveryOptionError &&
                  !urgencyBtnSelected.right &&
                  (deliveryStartDateTouched || deliveryEndDateTouched) && (
                    <small className="text-danger mb-n1">
                      {deliveryOptionError}
                    </small>
                  )}
                {renderUrgencyInputRight()}
              </div>
            </Row>
          </Col>
          <hr />
        </Row>
        <hr className="my-5" />
        <Row>
          <Col md={12}>
            <Field name="solicitude[description]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  as="textarea"
                  className="text-area-client"
                  placeholder="Recuerda no incluir información de contacto o serás suspendido por x tiempo"
                  label="Comentario"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {closeWarningCard && (
            <WarningCard setCloseWarningCard={setCloseWarningCard} />
          )}
        </Row>
        <Row className="mt-3">
          <Col
            xs={12}
            md={9}
            xl={10}
            className="d-flex justify-content-start terms-check-box"
          >
            <Field name="terms">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  abbr="true"
                  field={field}
                  label="Acepto"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  link={`/${settings.linkTerms}`}
                  linkText="términos y condiciones"
                />
              )}
            </Field>
          </Col>
          <Col sm={12} md={4} xl={3} className="ml-auto">
            <Button
              variant="success"
              type="submit"
              className="mt-4"
              disabled={!values.terms}
              block
              style={{ minWidth: '200px' }}
            >
              {isEdit ? 'Guardar solicitud' : 'Crear solicitud'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({
  solicitude: Yup.object().shape({
    itemsAttributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Debes ingresar el nombre del objeto'),
        net_value: Yup.number()
          .required('Debes ingresar un costo')
          .typeError('Debes ingresar solo números')
          .positive('Debe ser mayor a 0'),
        weight: Yup.number()
          .required('Debes ingresar un peso')
          .typeError('Debes ingresar solo números')
          .positive('Debe ser mayor a 0'),
        height: Yup.number()
          .required('Debes ingresar una altura')
          .typeError('Debes ingresar solo números')
          .positive('Debe ser mayor a 0'),
        width: Yup.number()
          .required('Debes ingresar un ancho')
          .typeError('Debes ingresar solo números')
          .positive('Debe ser mayor a 0'),
        length: Yup.number()
          .required('Debes ingresar un largo')
          .typeError('Debes ingresar solo números')
          .positive('Debe ser mayor a 0')
      })
    ),
    pickupAddressAttributes: Yup.object().shape({
      isValidAddress: Yup.boolean().oneOf(
        [true],
        'Dirección invalida. Busca y selecciona tu dirección'
      ),
      streetName: Yup.string().required(
        'Debes ingresar una dirección de retiro.'
      )
    }),
    deliveryAddressAttributes: Yup.object().shape({
      isValidAddress: Yup.boolean().oneOf(
        [true],
        'Dirección invalida. Busca y selecciona tu dirección'
      ),
      streetName: Yup.string().required(
        'Debes ingresar una dirección de entrega.'
      )
    }),
    pickupPersonName: Yup.string().required(
      'Ingresa el nombre de quien entrega'
    ),
    pickupPersonPhone: Yup.string()
      .required('Ingresa el celular de quien retira')
      .test('len', 'Teléfono incorrecto', val => val?.trim().length === 14),
    pickupOption: Yup.string().required(
      'Debes seleccionar una opción de retiro'
    ),
    pickupEndDate: Yup.string().when('pickupOption', {
      is: val => val === 'between_range',
      then: Yup.string().required('Debes seleccionar una fecha de término')
    }),
    deliveryPersonName: Yup.string().required(
      'Ingresa el nombre de quien recibe'
    ),
    deliveryPersonPhone: Yup.string()
      .required('Ingresa el celular de quien entrega')
      .test('len', 'Teléfono incorrecto', val => val?.trim().length === 14),
    deliveryOption: Yup.string().required(
      'Debes seleccionar una opción de entrega'
    ),
    deliveryEndDate: Yup.string().when('deliveryOption', {
      is: val => val === 'between_range',
      then: Yup.string().required('Debes seleccionar una fecha de término')
    })
  })
});

const setInitialValues = props => {
  const { solicitude } = props;
  return {
    solicitude,
    terms: false
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ClientSolicitudeForm);
