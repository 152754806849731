import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { DefaultHeader, SuccessAlert } from '../../../components';
import { updateUserRequest } from '../../../requests/user';
import { validateToken } from '../../../actions/auth';
import ProfileClientForm from './ProfileClientForm';

const ProfileClientEdit = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { user } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUpdateRequest = values => {
    setOnRequest(true);
    const {
      avatar,
      clientAttributes: { frontIdentity, backIdentity }
    } = values.user;
    const params = snakeCaseKeys(values);

    params.user.client_attributes.front_identity = frontIdentity;
    params.user.client_attributes.back_identity = backIdentity;
    if (avatar) params.user.avatar = avatar;

    updateUserRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        dispatch(validateToken());
        setShowAlert(true);
        setOnRequest(false);
      },
      callback: () => setOnRequest(false)
    });
  };

  return (
    <div>
      <DefaultHeader
        breadcrumb={[
          { key: 1, name: 'Mi Perfil', href: '/client/profile' },
          { key: 2, name: 'Editar perfil' }
        ]}
      />
      <ProfileClientForm
        user={camelCaseRecursive(user)}
        modelName="user[clientAttributes]"
        formRequest={handleUpdateRequest}
        onRequest={onRequest}
      />
      <SuccessAlert
        message="Perfil editado"
        titlebtn="Entendido"
        onClickHide={() => {
          setShowAlert(false);
          setTimeout(() => {
            history.push('/client/profile');
          }, 300);
        }}
        show={showAlert}
      />
    </div>
  );
};

export default ProfileClientEdit;
