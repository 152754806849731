import ApiService from '../services/apiService';

const createRatingRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', `/solicitudes/${params.rankable_id}/ratings`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export default createRatingRequest;
