import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { useFetchForRemoteSelect } from '../../hooks';
import {
  debounceIndexTripsRequest,
  indexTripRequest
} from '../../requests/trips';
import { InputRemoteSelect } from '../Utils/Select';

const TripRemoteSelect = ({
  abbr,
  values,
  errors,
  touched,
  field,
  nestedDependency,
  dependencyArray,
  customDisabled,
  initialParams,
  onChangeCallback,
  label,
  placeholder
}) => {
  const labelText = label || 'Viaje';
  const placeholderText = placeholder || 'Seleccionar viaje';
  const { setFieldValue } = useFormikContext();
  const {
    options: trips,
    selectedOption: selectedTrip,
    fetchOptions,
    isFetching
  } = useFetchForRemoteSelect({
    indexRequest: indexTripRequest,
    debouncedIndexRequest: debounceIndexTripsRequest,
    value: getIn(values, field.id),
    forSelector: false,
    initialParams: initialParams && { ...initialParams },
    nestedDependency: nestedDependency && nestedDependency,
    dependencyArray: dependencyArray && [...dependencyArray]
  });

  return (
    <InputRemoteSelect
      {...field}
      abbr={abbr}
      isClearable
      disabled={isFetching || customDisabled}
      label={labelText}
      placeholder={placeholderText}
      defaultOptions={trips}
      value={selectedTrip}
      request={fetchOptions}
      onChange={data => {
        setFieldValue(field.name, data?.value || '');
        if (onChangeCallback) onChangeCallback(data);
      }}
      error={getIn(errors, field.name)}
      touched={getIn(touched, field.name)}
    />
  );
};

export default TripRemoteSelect;
