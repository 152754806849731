/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import IcoMoon from '../Icon';

const UploadFile = ({
  fileName,
  onChange,
  label,
  abbr,
  iconComponent,
  accept,
  error,
  touched,
  isForStepRegister,
  previewFile
}) => {
  const [name, setName] = useState('Subir Archivo');

  const _handleFileChange = e => {
    e.preventDefault();
    const reader = new FileReader();
    if (e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];
    reader.onloadend = () => {
      setName(file.name);
    };
    reader.readAsDataURL(file);
    onChange(file);
  };

  useEffect(() => {
    if (fileName) setName(fileName);
  }, [fileName]);

  useEffect(() => {
    if (previewFile) {
      if (!previewFile?.filename) {
        const reader = new FileReader();
        const file = previewFile;
        reader.onloadend = () => {
          setName(file.name);
        };
        reader.readAsDataURL(file);
      } else {
        setName(previewFile.filename);
      }
    }
  }, [previewFile]);

  return (
    <Row className="content-upload-file">
      {label && (
        <Form.Label className="w-100 mb-n1 mb-md-3">
          <div
            className={`d-flex justify-content-${
              isForStepRegister ? 'between' : 'around'
            } align-items-center`}
          >
            <div>
              {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
            </div>
            {isForStepRegister ? (
              <div
                className={`circle-image ${
                  name !== 'Subir Archivo' ? 'loaded' : 'load-btn'
                }`}
              >
                <IcoMoon icon="files" size={20} />
              </div>
            ) : (
              iconComponent
            )}
          </div>
        </Form.Label>
      )}
      <Col
        className="justify-center d-flex justify-content-center align-items-center h-140px flex-column"
        md={12}
      >
        <div className="input-group mb-3 mt-2 mt-md-0">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile"
              aria-describedby="inputGroupFileAddon"
              accept={accept}
              onChange={e => _handleFileChange(e)}
            />
            <label
              className={`custom-file-label upload-file text-truncate ${
                error && touched ? 'required' : ''
              }`}
              htmlFor="inputGroupFile"
            >
              <span>{name}</span>
            </label>
          </div>
        </div>
        {error && touched && (
          <Form.Text className="text-danger mt-n2">{error}</Form.Text>
        )}
      </Col>
    </Row>
  );
};

UploadFile.defaultProps = {
  onChange: () => {}
};

export default UploadFile;
