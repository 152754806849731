import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import StatusCard from './StatusCard';
import IcoMoon from '../../Utils/Icon/IcoMoon';
import { statusUpdateSolicitudeRequest } from '../../../requests/solicitudes';
import useConfirmModal from '../../../hooks/useConfirmModal';
import {
  solicitudeStatusButtonColor,
  statusToButtonText
} from '../../../services/utils';
import { sendAlert } from '../../../actions/utils';

const StatusBox = ({
  pickupPersonName,
  pickupPersonPhone,
  deliveryPersonName,
  deliveryPersonPhone,
  pickupAddressAttributes,
  deliveryAddressAttributes,
  shipping
}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(shipping.solicitude.status);
  const [translatedStatus, setTranslatedStatus] = useState(
    shipping?.solicitude?.statusTranslated
  );
  const [showWarningCard, setShowWarningCard] = useState(true);
  const handleUpdateStatus = () => {
    statusUpdateSolicitudeRequest(shipping.solicitude.id, {
      dispatch,
      successCallback: response => {
        setTranslatedStatus(response.data.status_translated);
        setStatus(response.data.status);
        dispatch(
          sendAlert({
            kind: 'success',
            message: 'Estado actualizado correctamente'
          })
        );
      }
    });
  };

  const buttonText = statusToButtonText[status];
  const shouldBeDisabled = ['delivered', 'finished'].includes(status);

  const { ModalComponent, handleShowModal } = useConfirmModal({
    modalTitle: 'Actualizar estado',
    onConfirmCallback: handleUpdateStatus,
    modalBody: (
      <div className="p-5">
        <h6>¿Estás seguro de actualizar el estado actual?</h6>
      </div>
    )
  });

  return (
    <>
      <Col sm={12} md={8} lg={8}>
        <Col className="status-box shadow-black">
          <div>
            <Row className="py-4">
              <StatusCard
                personName={pickupPersonName}
                personPhone={pickupPersonPhone}
                addressAttributes={pickupAddressAttributes}
                shipping={shipping}
                shippingStatus={translatedStatus}
              />
              <StatusCard
                isDeliverCard
                personName={deliveryPersonName}
                personPhone={deliveryPersonPhone}
                addressAttributes={deliveryAddressAttributes}
                shipping={shipping}
                shippingStatus={translatedStatus}
              />
              <div className="px-3">
                <h6>Estado: {translatedStatus}</h6>
              </div>
            </Row>
          </div>
        </Col>
        <Row className="justify-content-between form-client-solicitude mt-3 mb-5">
          <Col md={6} lg={6} xs={12}>
            {showWarningCard && (
              <div className="card-warning">
                <div className="title-card d-flex justify-content-between align-items-center">
                  <h6>Recuerda notificar el cambio de los estados</h6>
                  <IcoMoon
                    icon="cancel-circle"
                    className="close-warning-card-icon"
                    onClick={() => setShowWarningCard(false)}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col md={6} lg={6} xs={12}>
            <Button
              variant={solicitudeStatusButtonColor[status] || 'info'}
              className="btn-block"
              style={{ height: '100%' }}
              onClick={handleShowModal}
              disabled={shouldBeDisabled}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </Col>
      <ModalComponent />
    </>
  );
};

export default StatusBox;
