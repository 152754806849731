import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Breadcrumb as BSBreadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icomoon from '../Icon';

const Breadcrumb = ({ routes }) => {
  const history = useHistory();

  return (
    <Row className="d-flex align-items-baseline">
      <Col className="ml-n3 d-flex align-items-center" xs={12}>
        <Icomoon
          icon="chevron-back"
          className="btn-go-back"
          onClick={() => history.goBack()}
        />
        <BSBreadcrumb className="custom-breadcrumb">
          {routes.map((route, index) => (
            <BSBreadcrumb.Item
              key={`breadcrumb-${route.key.toString()}`}
              active={routes.length === index + 1}
              onClick={() => history.push(route.href)}
            >
              {route.name}
            </BSBreadcrumb.Item>
          ))}
        </BSBreadcrumb>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      key: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Breadcrumb;
