import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IcoMoon from '../Utils/Icon/IcoMoon';
import DefaultImgClient from '../../assets/images/profile-photo.svg';
import DefaultImgDriver from '../../assets/images/profile-driver.svg';
import ProfileSessionInfo from './ProfileSessionInfo';

const ProfileFirstCol = ({
  module,
  avatarProfileUrl,
  fullName,
  email,
  countDays,
  completeness,
  numOfStars,
  phone,
  user
}) => {
  const isClient = module === 'client';
  const isDriver = module === 'driver';
  const isValidated = user?.driverAttributes?.isValidated ?? false;

  return (
    <>
      <Col md={5} xl={4} className="left-animation">
        <div className="card-profile">
          <img
            src={
              isClient
                ? avatarProfileUrl || DefaultImgClient
                : avatarProfileUrl || DefaultImgDriver
            }
            alt="default-profile-img"
          />
          <div className="info">
            <h5>{fullName}</h5>

            {isDriver ? (
              <div className="box-star driver">
                <IcoMoon icon="star-full" />
                <span>{numOfStars}</span>
              </div>
            ) : (
              ''
            )}
            {isDriver && !isValidated && (
              <p className="email text-primary">
                ¡Pendiente de confirmación Briiing!
              </p>
            )}
            <p className="email">
              Correo: <span>{email}</span>
            </p>
            <p className="email">
              Teléfono: <span>{phone}</span>
            </p>
            <p className="use-time">Has usado Briiing! por {countDays} días</p>
            <div className="complete-profile">
              <p>Has completado el {completeness}% del perfil</p>
              <div className={`${isClient ? 'bg-bar' : 'bg-driver-bar'} `}>
                <div
                  style={{
                    width: `${completeness}%`,
                    transition: '0.2 ease-in-out all'
                  }}
                  className="bar"
                />
              </div>
              <Button
                as={Link}
                className="mt-4"
                block
                to={`/${module}/profile/edit`}
                variant={`${
                  isClient ? 'secondary-outline' : 'primary-outline black-text'
                }`}
              >
                Completar
              </Button>
            </div>
          </div>
        </div>
        {isDriver && (
          <ProfileSessionInfo
            classNameRow="d-none d-md-block"
            driver
            user={user}
          />
        )}
      </Col>
    </>
  );
};

export default ProfileFirstCol;
