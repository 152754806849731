// eslint-disable-next-line import/prefer-default-export
export const driverQuotationOrderOptions = [
  { label: 'Monto mas alto', value: 'DESC netValue' },
  { label: 'Monto mas bajo', value: 'ASC netValue' },
  { label: 'Fecha mas cercana', value: 'ASC startDate' },
  { label: 'Fecha mas lejada', value: 'DESC startDate' }
];

export const driverQuotationFilterParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  tripType: '',
  statusFilter: ''
};
