import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';

const FormikCheckBox = ({
  label,
  margin,
  field,
  toolbarVariant,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  error,
  touched,
  link,
  linkText,
  ...props
}) => (
  <>
    <Form.Group
      className={`d-flex align-items-center ${margin && margin} form-checkbox`}
    >
      <Form.Check
        {...field}
        custom
        type="checkbox"
        checked={field.value}
        label={label}
        id={field.name}
        {...props}
      />
      {tooltipText && (
        <ButtonToolbar className="ml-2">
          <OverlayTrigger
            key={direction}
            placement={direction}
            delay={{ show: delayShow, hide: delayHide }}
            overlay={<Tooltip>{tooltipText}</Tooltip>}
          >
            <HelpCircle />
          </OverlayTrigger>
        </ButtonToolbar>
      )}
      <Link
        to={link}
        style={{ marginLeft: 3, fontWeight: 600 }}
        target="_blank"
      >
        {linkText}
      </Link>
    </Form.Group>
    {error && touched ? (
      <Form.Text className="text-danger mt-n1 mb-1">{error}</Form.Text>
    ) : null}
  </>
);
FormikCheckBox.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikCheckBox.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};
export default FormikCheckBox;
