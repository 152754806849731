import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { downloadFile } from '../../services/utils';
import { LinkBtn, BasicDropdown } from '../../components';
import SlideDataTable from './SlideDataTable';
import {
  indexSlidesRequest,
  debounceIndexSlidesRequest
} from '../../requests/slides';
import useFetchData from '../../hooks/useFetchData';

const SlideIndex = () => {
  const {
    data: slides,
    totalData: totalSlides,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexSlidesRequest,
    withDataTable: true
  });
  const dispatch = useDispatch();
  const modelUrl = '/slides';

  const handleSuccessDownload = response => {
    downloadFile(response);
    setMoreData(m => !m);
  };

  const downloadIndex = format => {
    indexSlidesRequest({
      dispatch,
      format,
      successCallback: handleSuccessDownload
    });
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h2>Slides</h2>
        </Col>
        <Col md={2}>
          <LinkBtn variant="primary" block to="/slides/new">
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={2}>
          <BasicDropdown
            variant="button"
            titleDrop="Download"
            items={[
              {
                key: '1',
                title: 'pdf',
                props: {
                  onClick: () => downloadIndex('pdf'),
                  disabled: isFetching
                }
              },
              {
                key: '2',
                title: 'xlsx',
                props: {
                  onClick: () => downloadIndex('xlsx'),
                  disabled: isFetching
                }
              }
            ]}
          />
        </Col>
      </Row>
      <SlideDataTable
        tableData={slides}
        tableDataAmount={totalSlides}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(connect()(SlideIndex));
