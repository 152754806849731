import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { createSolicitudeRequest } from '../../../requests/solicitudes';
import { SpinnerLoader, SuccessAlert } from '../../../components';
import ClientSolicitudeForm from './ClientSolicitudeForm';
import basicSolicitude from './solicitude';

const ClientSolicitudeNew = () => {
  const location = useLocation();
  const [onRequest, setOnRequest] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreateRequest = values => {
    setOnRequest(true);
    const { itemsAttributes } = values.solicitude;
    let myParams = snakeCaseKeys(values);
    myParams.solicitude.items_attributes = itemsAttributes;
    const solicitudeId = location?.state?.solicitudeId;
    if (solicitudeId) {
      myParams = { solicitudeId, ...myParams };
    }

    createSolicitudeRequest({
      dispatch,
      params: myParams,
      formData: true,
      successCallback: () => {
        setOnRequest(false);
        setShowAlert(true);
      },
      callback: () => setOnRequest(false)
    });
  };

  return (
    <div className="container-client-solicitude">
      {onRequest && <SpinnerLoader animation="border" variant="secondary" />}
      <div className={onRequest ? 'bg-opacity' : ''}>
        <ClientSolicitudeForm
          solicitude={basicSolicitude}
          action="new"
          formRequest={handleCreateRequest}
        />
      </div>
      <SuccessAlert
        titlebtn="Entendido"
        onClickHide={() => {
          setShowAlert(false);
          setTimeout(() => {
            history.push('/client/home');
          }, 300);
        }}
        show={showAlert}
      />
    </div>
  );
};

export default ClientSolicitudeNew;
