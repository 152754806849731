import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useFetchData from '../../../hooks/useFetchData';
import { debouncedMyCarsRequest } from '../../../requests/user';
import ComponentDataTable from '../../Utils/DataTable';
import Columns, { conditionalRows, customStyles } from './Columns';

const VehicleQuotationDatatable = ({ error, touched, field, solicitudeId }) => {
  const [vehicles, setVehicles] = useState([]);
  const { setFieldValue } = useFormikContext();
  const { data, totalData, isFetching, handleIndexRequest } = useFetchData({
    debouncedIndexRequest: debouncedMyCarsRequest
  });

  const handleRowClicked = row => {
    const selectedVehicle = data.reduce((acc, value, index) => {
      if (value.id === row.id) {
        acc[index] = { ...value, isSelected: true };
        setFieldValue(field.name, value.id);
      } else {
        acc[index] = { ...value };
      }
      return acc;
    }, []);
    setVehicles(selectedVehicle);
  };

  const setFirstSelected = () => {
    return data.reduce((acc, value, index) => {
      if (index === 0) {
        acc[0] = { ...value, isSelected: true };
        setFieldValue(field.name, value.id);
      } else {
        acc[index] = { ...value };
      }
      return acc;
    }, []);
  };

  useEffect(() => setVehicles(setFirstSelected()), [data]);

  return (
    <>
      {vehicles.length === 0 && !isFetching ? (
        <>
          <p className="my-3">No tienes ningún vehículo agregado.</p>
          <Button
            as={Link}
            to={{
              pathname: '/driver/profile/edit',
              state: { quotation: solicitudeId }
            }}
            variant="primary"
          >
            Agregar vehículo
          </Button>
        </>
      ) : (
        <>
          <div className="default-styles-dt">
            <ComponentDataTable
              highlightOnHover
              pointerOnHover
              noTableHead
              selectableRowsHighlight
              pagination={false}
              conditionalRowStyles={conditionalRows}
              customStyles={customStyles}
              noSpinner
              onRequest={isFetching}
              totalRows={totalData}
              columns={Columns()}
              data={vehicles}
              resourceRequest={handleIndexRequest}
              onRowClicked={handleRowClicked}
              subHeader={false}
            />
          </div>
        </>
      )}
      {error && touched ? (
        <Form.Text className="text-danger mt-2">{error}</Form.Text>
      ) : null}
    </>
  );
};

export default VehicleQuotationDatatable;
