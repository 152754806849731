import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexBanksRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', '/banks', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debouncedIndexBankRequest = AwesomeDebouncePromise(
  indexBanksRequest,
  300
);
