import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import IcoMoon from 'react-icomoon';
import PropTypes from 'prop-types';

const iconSet = require('./selection.json');

const Icon = ({
  tooltipText,
  size,
  marginIcon,
  zIndex,
  classNameContainer,
  styleContainer,
  roundBorder,
  roundColor,
  tooltipPlacement = 'right',
  classNameTooltip,
  ...props
}) => {
  const renderTooltip = tooltipsProps => (
    <Tooltip
      className={classNameTooltip}
      id="button-tooltip"
      {...tooltipsProps}
    >
      {tooltipText}
    </Tooltip>
  );

  const roundStyle = roundBorder
    ? {
        background: roundColor || '#ececec',
        padding: '0px 6px 1px 6px',
        borderRadius: '20px',
        marginLeft: 10
      }
    : { ...styleContainer };

  return (
    <>
      {tooltipText ? (
        <OverlayTrigger
          placement={tooltipPlacement}
          delay={{ show: 100, hide: 100 }}
          overlay={renderTooltip}
        >
          <div
            className={`d-inline-block ${classNameContainer}`}
            style={{ margin: marginIcon, zIndex, ...roundStyle }}
          >
            <IcoMoon iconSet={iconSet} {...props} height={size} />
          </div>
        </OverlayTrigger>
      ) : (
        <div
          className={`d-inline-block ${classNameContainer}`}
          style={{ margin: marginIcon, zIndex, ...roundStyle }}
        >
          <IcoMoon iconSet={iconSet} {...props} height={size} />
        </div>
      )}
    </>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  marginIcon: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Icon.defaultProps = {
  className: 'icomoon',
  marginIcon: '5px',
  size: 25
};

export default Icon;
