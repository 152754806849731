import React from 'react';
import { Col } from 'react-bootstrap';
import DriverRouteCell from '../../TableCells/DriverRouteCell';
import IcoMoon from '../../Utils/Icon/IcoMoon';

const SolicitudeMainInfo = ({
  id,
  pickupAddressAttributes,
  deliveryAddressAttributes,
  client,
  deliveryPersonPhone,
  comments,
  status,
  ammount,
  tripId
}) => {
  return (
    <>
      <Col sm={12} md={4} lg={4} className="solicitude-main-info">
        <DriverRouteCell
          id={id}
          startLocation={pickupAddressAttributes.communeName}
          endLocation={deliveryAddressAttributes.communeName}
          state="Por hacer"
          codeNumber={id.split('-')[0]}
          status={status}
          tripId={tripId}
        />
        <p className="py-2">
          <strong className="">Remuneración total:</strong>
          <span className="ml-2">{ammount}</span>
        </p>
        <p className="py-2">
          <strong>Cliente :</strong> {client.name} {client.lastName}
        </p>
        <div className="d-flex justify-content-between align-items-center pt-2">
          <p>
            <strong>Cel:</strong> {deliveryPersonPhone}
          </p>
          <div className="mr-2">
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://api.whatsapp.com/send?phone=${deliveryPersonPhone}&text=¡Hola ${client.name} ${client.lastName}, te escribo para coordinar por el envío de Briiing! 📦🚚`}
            >
              <IcoMoon
                roundBorder
                roundColor="#bcf3cf"
                size="18"
                icon="whatsapp"
                className="hover-wsp"
                classNameContainer="hover-wsp"
              />
            </a>
          </div>
        </div>

        <div className="text-justify my-4">
          <p>
            <strong>Comentarios: </strong>
            {comments}
          </p>
        </div>
      </Col>
    </>
  );
};

export default SolicitudeMainInfo;
