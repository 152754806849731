import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect, useDispatch } from 'react-redux';
import jwt from 'jwt-decode';

import { history } from './app/config/store';
import routes from './app/config/routes';
import { Alert } from './app/components';
import GlobalErrorBoundary from './app/screens/Error/GlobalErrorBoundary';
import {
  getSettings,
  sendAlert,
  getNotifications,
  getUnreadNotifications
} from './app/actions/utils';
import './index.scss';

const App = () => {
  const dispatch = useDispatch();

  const handleNotifications = () => {
    dispatch(getUnreadNotifications());
    dispatch(getNotifications());
  };

  const handleChangeRoute = () =>
    history.listen(() => {
      const payload = localStorage.getItem('jwt');
      if (!payload) return;

      const currentTime = Date.now() / 1000;
      const isExpired = currentTime >= jwt(payload).exp;

      if (isExpired) {
        dispatch(
          sendAlert({ kind: 'error', message: 'Tu sesión ha expirado' })
        );
        localStorage.removeItem('jwt');
      }
      handleNotifications();
    });

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(handleChangeRoute, []);

  return (
    <GlobalErrorBoundary>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      <Alert />
    </GlobalErrorBoundary>
  );
};

const mapStateToProps = state => {
  const { utils } = state.utils;
  return {
    utils
  };
};

export default connect(mapStateToProps)(App);
