import React from 'react';
import memoize from 'memoize-one';
import ClientItemCell from '../TableCells/ClientItemCell';
import DateCell from '../TableCells/DateCell';
import QuotationSelectOffer from '../TableCells/QuotationSelectOffer';
import IsTodayCell from '../TableCells/IsTodayCell';

const Columns = memoize(({ modelUrl, solicitude: quotationSolicitude, driver }) => {
  return [
    {
      name: 'Nombre Item',
      grow: 1.2,
      sortable: false,
      selector: ({ solicitude }) => solicitude?.itemName,
      cell: ({ id, solicitude: { itemsAttributes } }) => (
        <ClientItemCell
          id={id}
          itemName={itemsAttributes[0].name}
          codeNumber={id.split('-')[0]}
          imageUrl={itemsAttributes[0].itemImages[0]?.fileUrlMinified}
        />
      )
    },
    {
      name: 'Fecha Viaje',
      grow: 1.2,
      sortable: false,
      selector: ({ solicitude }) => solicitude?.startDate,
      cell: ({
        solicitude: {
          id,
          deliveryAddressAttributes,
          pickupAddressAttributes,
          hasQuotation,
          startDate,
          endDate
        }
      }) => (
        <DateCell
          id={id}
          startLocation={pickupAddressAttributes.communeName}
          endLocation={deliveryAddressAttributes.communeName}
          startDate={startDate}
          endDate={endDate}
          hasQuotation={hasQuotation}
          module="driver"
        />
      )
    },
    {
      name: 'Precio',
      grow: 1,
      sortable: false,
      cell: row => (
        <QuotationSelectOffer
          driver={driver}
          modelUrl={modelUrl}
          quotation={row}
          solicitude={quotationSolicitude}
        />
      )
    },
    {
      name: 'IsToday',
      grow: 1,
      sortable: false,
      selector: row => row.isToday,
      cell: ({ solicitude: { id, pickupAt } }) => (
        <IsTodayCell id={id} dateToCheck={pickupAt} />
      )
    }
  ];
});

// eslint-disable-next-line import/prefer-default-export
export { Columns };
