import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUnreadNotifications } from '../../actions/utils';
import IcoMoon from '../Utils/Icon/IcoMoon';

const NotificationsButton = ({
  openCloseNotification,
  openPopUpNotification,
  badgeColor
}) => {
  const dispatch = useDispatch();
  const { notifications = {} } = useSelector(state => state.utils);
  const { unread: unreadNotificationsCounter } = notifications;

  return (
    <div className="notification-button">
      <IcoMoon
        className={`mr-2 ${openPopUpNotification ? 'text-white' : ''}`}
        size={33}
        icon="notifications"
        onClick={() => {
          openCloseNotification();
          dispatch(getUnreadNotifications());
        }}
      />
      {unreadNotificationsCounter > 0 && (
        <span className={`notification-button--badge-style ${badgeColor}`}>
          {unreadNotificationsCounter || 0}
        </span>
      )}
    </div>
  );
};

export default NotificationsButton;
