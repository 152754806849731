import React from 'react';
import { Field, withFormik, Form } from 'formik';
import CommuneRemoteSelect from '../../Address/CommuneRemoteSelect';

const LocationFilter = ({
  customParams,
  pickupAddress,
  setCustomParams,
  deliveryAddress,
  values,
  errors,
  touched
}) => {
  const handleInputCommuneChange = (value, destiniy) => {
    const commune = value?.label || '';
    if (destiniy === 'communeOriginId') {
      setCustomParams({
        ...customParams,
        [pickupAddress]: commune
      });
    } else {
      setCustomParams({
        ...customParams,
        [deliveryAddress]: commune
      });
    }
  };

  return (
    <>
      <Form className="d-flex w-100">
        <div className="d-flex flex-column justify-content-center align-items-center mx-3 pt-3 w-100">
          <Field name="[communeOriginId]">
            {({ field }) => (
              <CommuneRemoteSelect
                values={values}
                errors={errors}
                touched={touched}
                field={field}
                label="Origen"
                onChangeCallback={value =>
                  handleInputCommuneChange(value, 'communeOriginId')
                }
              />
            )}
          </Field>
          <Field name="[communeDeliveryId]">
            {({ field }) => (
              <CommuneRemoteSelect
                values={values}
                errors={errors}
                touched={touched}
                field={field}
                label="Destino"
                onChangeCallback={value =>
                  handleInputCommuneChange(value, 'communeDeliveryId')
                }
              />
            )}
          </Field>
        </div>
      </Form>
    </>
  );
};

const setInitialValues = () => {
  return {
    communeOriginId: '',
    communeDeliveryId: ''
  };
};

export default withFormik({
  mapPropsToValues: () => setInitialValues()
})(LocationFilter);
