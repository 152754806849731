import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage('es');
Geocode.setRegion('cl');

const geocodeAddress = async address => {
  // return { lat: -33.4566678, lng: -360 };
  const coordinates = await Geocode.fromAddress(address).then(
    response => {
      return response.results[0].geometry.location;
    },
    error => {
      console.error(error);
      return { lat: -33.436933, lng: -70.63443470000001 }; // Defaul address Plaza Italia
    }
  );
  return coordinates;
};

export default geocodeAddress;
