import React from 'react';
import jwt from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';
import { Container, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavbarLogged } from '../../components';
import { requestSignOut } from '../../actions/auth';
import Sidebar from '../../components/Utils/Sidebar';
import { redirectByRole } from '../../services/utils';

const LoggedLayout = ({
  module,
  hideNavigation,
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  const loginRole = localStorage.getItem('loginRole');
  const { sidebarOpen } = useSelector(state => state.utils);

  const isAllowedToStay = user => user?.roles.includes(module);

  return (
    <Route
      {...rest}
      render={props => {
        const payload = localStorage.getItem('jwt');
        const user = payload && jwt(payload);
        if (!payload)
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        return isAllowedToStay(user) ? (
          <>
            {!hideNavigation && (
              <>
                <Sidebar module={module} sidebarOpen={sidebarOpen} />
                <NavbarLogged>
                  <NavDropdown.Item
                    onClick={() => dispatch(requestSignOut())}
                    className={`${
                      loginRole === 'client' ? 'client' : 'driver'
                    }`}
                  >
                    Cerrar Sesión
                  </NavDropdown.Item>
                </NavbarLogged>
              </>
            )}
            <div className={`${sidebarOpen ? '' : 'content-body'}`}>
              <Container
                className={`container-layout ${
                  hideNavigation ? '' : 'padding-layout'
                } `}
                fluid
              >
                <Component {...props} />
              </Container>
            </div>
          </>
        ) : (
          <Redirect to={redirectByRole()} />
        );
      }}
    />
  );
};

export default LoggedLayout;
