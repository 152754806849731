import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ButtonComponent } from '..';

const WrapperSession = ({ children }) => (
  <>
    <ButtonComponent
      typeButton="Link"
      as={Link}
      to="/"
      icon="chevron-back"
      variant="primary"
      className="btn-back"
    >
      Volver a home
    </ButtonComponent>
    <Row className="justify-content-center align-items-center full-height session">
      <Col className="bg-card mx-2 mt-3" sm={12} md={6} lg={5} xl={4}>
        {children}
      </Col>
    </Row>
  </>
);

export default WrapperSession;
