import React from 'react';
import DriverItemCell from '../../../components/TableCells/DriverItemCell';
import DateCell from '../../../components/TableCells/DateCell';
import DriverRouteCell from '../../../components/TableCells/DriverRouteCell';
import AmmountCell from '../../../components/TableCells/AmmountCell';
import IsTodayCell from '../../../components/TableCells/IsTodayCell';

const columns = [
  {
    name: 'Ciudad de salida',
    grow: 2,
    sortable: false,
    selector: row => row.startLocation,
    cell: ({
      id,
      solicitude: {
        pickupAddressAttributes,
        deliveryAddressAttributes,
        statusTranslated
      }
    }) => (
      <DriverRouteCell
        id={id}
        startLocation={pickupAddressAttributes?.communeName}
        endLocation={deliveryAddressAttributes?.communeName}
        status={statusTranslated}
        codeNumber={id.split('-')[0]}
      />
    )
  },
  {
    name: 'Nombre Item',
    grow: 2,
    sortable: false,
    selector: row => row.itemName,
    cell: ({ tripId, solicitude: { id, itemsAttributes } }) => (
      <DriverItemCell
        id={id}
        itemName={itemsAttributes[0]?.name}
        height={itemsAttributes[0]?.height}
        length={itemsAttributes[0]?.length}
        width={itemsAttributes[0]?.width}
        weight={itemsAttributes[0]?.weight}
        imageUrl={itemsAttributes[0].itemImages[0]?.fileUrlMinified}
        tripId={tripId}
      />
    )
  },
  {
    name: 'Fecha Salida',
    grow: 1.5,
    sortable: false,
    selector: row => row.startDate,
    cell: ({
      solicitude: { id, startDate, endDate, pickupOption, deliveryOption }
    }) => (
      <DateCell
        id={id}
        startLocation="Salida"
        endLocation="Entrega"
        startDate={startDate}
        endDate={endDate}
        pickupOption={pickupOption}
        deliveryOption={deliveryOption}
      />
    )
  },
  {
    name: 'Precio',
    grow: 1,
    sortable: false,
    selector: row => row.ammount,
    cell: ({
      solicitude: {
        id,
        selectedQuotation: {
          parsedDriverCommissionAmount,
          paid,
          paidToDriver,
          briiingerPaymentRequest
        },
        status
      }
    }) => (
      <AmmountCell
        id={id}
        ammount={parsedDriverCommissionAmount}
        paid={paid}
        status={status}
        paidToDriver={paidToDriver}
        briiingerPaymentRequest={briiingerPaymentRequest}
      />
    )
  },
  {
    name: 'IsToday',
    grow: 1,
    sortable: false,
    selector: row => row.isToday,
    cell: ({ solicitude: { id, pickupAt } }) => (
      <IsTodayCell id={id} dateToCheck={pickupAt} />
    )
  }
];

export default columns;
