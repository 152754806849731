import React, { useState, useEffect } from 'react';
import { Field, Form, getIn, withFormik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { showSolicitudeRequest } from '../../requests/solicitudes';
import quotationSchema from './quotationSchema';
import QuotationFields from './QuotationFields';
import { FormikCheckBox } from '../Utils/Input';
import SolicitudePreview from './Solicitude/SolicitudePreview';

const QuotationForm = ({
  errors,
  touched,
  modelName,
  action,
  submitVariant = 'success',
  solicitudeId,
  values
}) => {
  const [solicitude, setSolicitude] = useState({});
  const submitBtnText = action === 'new' ? 'Enviar oferta' : 'Guardar cambios';
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.utils);

  const handleFetchSolicitude = () => {
    showSolicitudeRequest(solicitudeId, {
      dispatch,
      successCallback: response =>
        setSolicitude(camelCaseRecursive(response.data))
    });
  };

  useEffect(handleFetchSolicitude, []);
  return (
    <Form className="opacity-animation">
      <Row className="justify-content-between">
        <Col md={8} xl={9}>
          <p className="info-extra mb-4">
            Esta es tu oferta que será enviada al cliente. Te recordamos ser
            amable y los más especifico posible
          </p>
          <QuotationFields
            solicitude={solicitude}
            solicitudeId={solicitudeId}
            touched={touched}
            errors={errors}
            modelName={modelName}
            deliveryStartDate={solicitude?.deliveryAt}
            pickupStartDate={solicitude?.pickupAt}
          />
        </Col>
        <Col md={4} xl={3} className="pr-3 mt-2 mb-3 mb-md-0 mt-md-0 pr-md-0">
          <Link
            className="without-style-link"
            to={`/driver/solicitudes/${solicitudeId}/offer`}
          >
            <SolicitudePreview solicitudeId={solicitudeId} />
          </Link>
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={9}
          xl={6}
          className="d-flex justify-content-start terms-check-box"
        >
          <Field name="terms">
            {({ field }) => (
              <FormikCheckBox
                {...field}
                abbr="true"
                field={field}
                label="Acepto"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                link={`/${settings.linkTerms}`}
                linkText="términos y condiciones"
              />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col className="mt-4 d-flex justify-content-end" md={9}>
          <Button
            type="submit"
            variant={submitVariant}
            size="lg"
            disabled={!values.terms}
          >
            {submitBtnText}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = ({ quotation }) => {
  return { quotation, terms: false };
};

const validationSchema = Yup.object().shape({
  quotation: Yup.object().shape({ ...quotationSchema })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: ({ action }) => action !== 'new'
})(QuotationForm);
