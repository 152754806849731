const basicQuotation = {
  lowestCost: '',
  driverCommissionAmount: '',
  vehicleId: '',
  tripId: '',
  pickupAt: '',
  deliveryAt: '',
  comments: '',
  createdAt: '',
  updatedAt: '',
  status: 0
};

export default basicQuotation;
