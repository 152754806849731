import { defineAbility, AbilityBuilder } from '@casl/ability';
import { hasRole } from '../services/utils';

const setUpAbility = (can, cannot) => {
  const payload = localStorage.getItem('jwt');

  if (payload) {
    switch (true) {
      case hasRole('client') && hasRole('driver'):
        can('manage', 'client');
        can('manage', 'driver');
        break;
      case hasRole('driver'):
        can('manage', 'driver');
        cannot('manage', 'client');
        break;
      case hasRole('client'):
        can('manage', 'client');
        cannot('manage', 'driver');
        break;
      default:
        can('read', 'basic');
        break;
    }
  }
};

export default defineAbility((can, cannot) => setUpAbility(can, cannot));

export const updateAbility = ability => {
  const { can, cannot, rules } = new AbilityBuilder();
  setUpAbility(can, cannot);
  ability.update(rules);
};
