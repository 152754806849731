import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCommentRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', `/solicitudes/${params.solicitude_id}/comments`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createCommentRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('post', `/solicitudes/${id}/comments`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const debouncedIndexCommentRequest = AwesomeDebouncePromise(
  indexCommentRequest,
  300
);
