import React from 'react';
import { Card } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import IcoMoon from '../Utils/Icon';

const AmmountCell = ({
  ammount,
  client,
  paidToDriver,
  paidByClient,
  hasQuotation,
  trip,
  status,
  briiingerPaymentRequest
}) => {
  const paid = paidToDriver || paidByClient;

  const amountMessage = () => {
    if (paidByClient) return 'Pagado';

    if (client) return 'Por pagar';

    if (trip) return 'Posibles ganancias';

    if (paidToDriver) return 'Pagado por Briiing';

    if (status === 'finished' && briiingerPaymentRequest) {
      return 'Pago solicitado';
    }

    return 'Pagado por cliente';
  };

  return (
    <div
      data-tag="allowRowEvents"
      className="justify-content-center align-items-center align-items-xl-end d-flex flex-column pb-2"
    >
      <Card.Title
        data-tag="allowRowEvents"
        className="font-weight-bold text-right mt-3 mb-0 "
      >
        {ammount && (
          <NumericFormat
            displayType="text"
            value={ammount}
            prefix="$"
            allowNegative={false}
            thousandSeparator="."
            decimalSeparator=","
          />
        )}
        {!ammount && hasQuotation && <p className="mb-3 mt-2">Ver ofertas</p>}
      </Card.Title>
      {ammount && (
        <div className="d-flex align-items-center justify-content-center">
          <IcoMoon
            size="12"
            icon="clock"
            className={`m-0 p-0  ${paid ? 'bg-success' : 'bg-secondary'}`}
            style={{ borderRadius: '50%' }}
          />
          <p style={{ marginTop: 1, fontSize: 11.5 }}>{amountMessage()}</p>
        </div>
      )}
    </div>
  );
};

export default AmmountCell;
