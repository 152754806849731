import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const ChangeProfile = ({
  changeProfile,
  onRequest,
  isNewRoleCreated,
  handleCloseRoleModal
}) => {
  const loginRole = localStorage.getItem('loginRole');
  const isClient = loginRole === 'client';

  return (
    <>
      <div className="px-3 pb-3">
        <h5 className="text-center mb-3">
          {isNewRoleCreated ? '¡Felicitaciones!' : 'Crear perfil'}
        </h5>

        {isNewRoleCreated && (
          <div>
            <p className="text-center m-4 p-4">
              Has creado el nuevo perfil. Puedes acceder al nuevo perfile desde
              el menu desplegable.
            </p>
          </div>
        )}
        {!isNewRoleCreated && (
          <p className="text-center mb-4">
            Esta acción te permitirá crear el perfil de{' '}
            {isClient ? 'Briiinger' : 'Cliente'} y acceder al Panel de{' '}
            {isClient ? 'envíos' : 'solicitudes'}. ¿Quieres crear el nuevo
            perfil?
          </p>
        )}

        {!isNewRoleCreated && (
          <Button
            variant={`${isClient ? 'secondary' : 'primary'} `}
            size="md"
            className={`btn login-btn ${
              isClient ? 'client-btn' : 'driver-btn'
            } `}
            block
            onClick={() => changeProfile()}
          >
            {onRequest ? (
              <Spinner
                as="span"
                animation="border"
                className="mr-2"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              `Crear perfil ${isClient ? 'Briiinger' : 'Cliente'}`
            )}
          </Button>
        )}
        {isNewRoleCreated && (
          <Button
            variant="success"
            size="md"
            block
            onClick={() => handleCloseRoleModal()}
            className={`${isClient ? 'client' : 'driver'}`}
          >
            Entendido
          </Button>
        )}
      </div>
    </>
  );
};

export default ChangeProfile;
