import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { DefaultHeader, SpinnerLoader } from '../../../components';
import { showQuotationRequest } from '../../../requests/quotations';
import ItemsAndRoute from '../../../components/Client/ClientShow/ItemsAndRoute';

const DriverQuotationShow = ({ match }) => {
  const dispatch = useDispatch();
  const [quotation, setQuotation] = useState({});
  const [onRequest, setOnRequest] = useState(true);

  useEffect(() => {
    setOnRequest(true);
    showQuotationRequest(match.params.id, {
      dispatch,
      successCallback: response => {
        setQuotation(camelCaseRecursive(response.data));
      },
      callback: () => setOnRequest(false)
    });
  }, []);

  return (
    <>
      <DefaultHeader
        breadcrumb={[
          { key: 1, name: 'Cotizaciones', href: '/driver/quotations' },
          { key: 2, name: 'Detalle Cotización' }
        ]}
      />
      {onRequest && <SpinnerLoader animation="border" variant="primary" />}
      <Row className="client-solicitude-show">
        <Col md={12} className="d-felx flex-column items-container">
          {!onRequest && (
            <ItemsAndRoute
              roleProfile="driver"
              itemsAttributes={quotation?.solicitude?.itemsAttributes}
              description={quotation?.solicitude?.description}
              pickupStartDate={quotation?.solicitude?.startDate}
              deliveryEndDate={quotation?.solicitude?.endDate}
              deliveryAddressAttributes={
                quotation?.solicitude?.deliveryAddressAttributes
              }
              pickupAddressAttributes={
                quotation?.solicitude?.pickupAddressAttributes
              }
              solicitudeId={quotation?.solicitudeId}
              isQuotation
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DriverQuotationShow;
