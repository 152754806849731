// eslint-disable-next-line import/prefer-default-export
export const customStyles = role => {
  const color = role === 'driver' ? '#ff5e62' : '#ff9966';
  return {
    // table: {
    //   style: {}
    // },
    // tableWrapper: {
    //   style: {
    //     display: 'table'
    //   }
    // },
    // responsiveWrapper: {
    //   style: {}
    // },
    // headRow: {
    //   style: {
    //     border: 'none'
    //   }
    // },
    // headCells: {
    //   style: {
    //     fontSize: '13px',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     fontWeight: '500',
    //     padding: '0',
    //     textAlign: 'center'
    //   }
    // },
    // rows: {
    //   style: {},
    //   highlightOnHoverStyle: {}
    // },
    // cells: {
    //   style: {
    //     display: 'flex',
    //     justifyContent: 'flex-start'
    //   }
    // },
    pagination: {
      style: {
        border: 'none',
        display: 'flex',
        justifyContent: 'center'
      },
      pageButtonsStyle: {
        border: `1px solid ${color}`,
        borderRadius: '10%',
        height: '40px',
        width: '40px',
        cursor: 'pointer',
        transition: '0.2s',
        color: 'black',
        fill: 'black',
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          color: 'black',
          fill: '#ececec',
          border: '1px solid #ececec'
        },
        '&:hover:not(:disabled)': {
          border: `1px solid ${color}`,
          backgroundColor: color
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: role === 'driver' ? '#ffcfd0' : '#ffe0d1'
        }
      }
    }
  };
};
