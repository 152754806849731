import React from 'react';

const StepsHeader = ({ step, goToStep }) => {
  return (
    <div className="d-flex justify-content-center align-items-center my-4">
      <h6
        onClick={() => goToStep(1)}
        aria-hidden="true"
        className={`steps_register_status_num ${step >= 1 ? 'active_num' : ''}`}
      >
        1
      </h6>
      <div
        className={`steps_register_status_line ${
          step >= 2 ? 'solid_line' : 'dotted_line'
        }`}
      />
      <h6
        onClick={() => goToStep(2)}
        aria-hidden="true"
        className={`steps_register_status_num ${step >= 2 ? 'active_num' : ''}`}
      >
        2
      </h6>
      <div
        className={`steps_register_status_line ${
          step >= 3 ? 'solid_line' : 'dotted_line'
        }`}
      />
      <h6
        onClick={() => goToStep(3)}
        aria-hidden="true"
        className={`steps_register_status_num ${step >= 3 ? 'active_num' : ''}`}
      >
        3
      </h6>
    </div>
  );
};

export default StepsHeader;
