import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

function AccordionFaqs({
  questionOne,
  answerOne,
  questionTwo,
  answerTwo,
  questionThree,
  answerThree,
  questionFour,
  answerFour,
  questionFive,
  answerFive,
  questionSix,
  answerSix,
  questionSeven,
  answerSeven,
  questionEight,
  answerEight,
  questionNine,
  answerNine,
  questionTen,
  answerTen
}) {
  return (
    <Accordion defaultActiveKey="0">
      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="0">
          {questionOne || '1. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {answerOne || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="1">
          {questionTwo || '2. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            {answerTwo || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="2">
          {questionThree || '3. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            {answerThree || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="3">
          {questionFour || '4. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            {answerFour || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="4">
          {questionFive || '5. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            {answerFive || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="5">
          {questionSix || '6. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            {answerSix || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="6">
          {questionSeven || '7. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <Card.Body>
            {answerSeven || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="7">
          {questionEight || '8. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="7">
          <Card.Body>
            {answerEight || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="8">
          {questionNine || '9. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="8">
          <Card.Body>
            {answerNine || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card className="faqs__cards__item mb-3">
        <Accordion.Toggle as={Card.Header} eventKey="9">
          {questionTen || '10. ¿Pregunta frecuente...?'}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="9">
          <Card.Body>
            {answerTen || 'Explicación detallada de la preguntar anterior'}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default AccordionFaqs;
