import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './app/config/store';
import App from './App';
import { AbilityContext } from './app/config/abilityContext';
import ability from './app/config/ability';
import './index.scss';

Sentry.init({
  dsn:
    'https://1632f8632a5304fabceb98396aad7b5d@o419188.ingest.us.sentry.io/4507511722934272',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/briiing\.cl\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const app = (
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <App />
    </AbilityContext.Provider>
  </Provider>
);
ReactDOM.render(app, document.getElementById('root'));
