import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import jwt from 'jwt-decode';
import { sendAlert } from '../actions/utils';

const useFetchData = ({
  debouncedIndexRequest,
  fetchingErrorMessage = 'Ops, hubo un problema al buscar la información. Por favor inténtalo nuevamente',
  withoutWrapper = false,
  withDataTable = false,
  customParams,
  parentId
}) => {
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [moreData, setMoreData] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const dispatch = useDispatch();

  const handleSuccessRequest = response => {
    if (withoutWrapper) {
      const responseData = camelCaseRecursive(response.data);
      setData(responseData);
    } else {
      const responseData = camelCaseRecursive(response.data.data);
      const totalAmountOfData = response.data.metadata.amount;
      setData(responseData);
      setTotalData(totalAmountOfData);
    }
    setIsFetching(false);
  };

  const handleFailureRequest = error => {
    const errorMessage = error?.response?.data?.message || fetchingErrorMessage;
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setIsFetching(false);
  };

  const handleIndexRequest = params => {
    const payload = localStorage.getItem('jwt');

    if (payload) {
      const currentTime = Date.now() / 1000;
      const isExpired = currentTime >= jwt(payload).exp;
      if (isExpired) return;
    }

    const sendParams = {
      ...params,
      ...customParams
    };
    if (parentId) {
      debouncedIndexRequest(parentId, {
        dispatch,
        params: sendParams,
        successCallback: handleSuccessRequest,
        failureCallback: handleFailureRequest
      });
    } else {
      debouncedIndexRequest({
        dispatch,
        params: sendParams,
        successCallback: handleSuccessRequest,
        failureCallback: handleFailureRequest
      });
    }
    setIsFetching(true);
  };

  const initialFetch = () => {
    const payload = localStorage.getItem('jwt');
    if (payload) {
      const currentTime = Date.now() / 1000;
      const isExpired = currentTime >= jwt(payload).exp;
      if (isExpired) return;
    }

    if (!withDataTable) {
      handleIndexRequest({
        sort_column: 'created_at',
        sort_direction: 'asc'
      });
    }
  };

  useEffect(initialFetch, [moreData]);

  return {
    data,
    totalData,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest,
    setData
  };
};

export default useFetchData;
