import React from 'react';
import { Field, withFormik, Form } from 'formik';
import CommuneRemoteSelect from '../../Address/CommuneRemoteSelect';

const LocationFilter = ({
  customParams,
  pickupAddress,
  setCustomParams,
  deliveryAddress,
  values
}) => {
  const handleOriginInputChange = value => {
    const commune = value?.label || '';
    setCustomParams({ ...customParams, [pickupAddress]: commune });
  };
  const handleDestiniationInputChange = value => {
    const commune = value?.label || '';
    setCustomParams({ ...customParams, [deliveryAddress]: commune });
  };
  return (
    <>
      <Form className="d-flex location-filter ">
        <div className="d-flex justify-content-center align-items-center mx-3 pt-3 location-filter-inputs-box">
          <Field name="[communeOriginId]">
            {({ field }) => (
              <CommuneRemoteSelect
                values={values}
                field={field}
                label="Origen"
                onChangeCallback={handleOriginInputChange}
                placeholder="Comuna origen"
              />
            )}
          </Field>
          <Field name="[communeDeliveryId]">
            {({ field }) => (
              <CommuneRemoteSelect
                values={values}
                field={field}
                label="Destino"
                onChangeCallback={handleDestiniationInputChange}
                placeholder="Comuna destino"
              />
            )}
          </Field>
        </div>
      </Form>
    </>
  );
};

const setInitialValues = () => {
  return {
    communeOriginId: '',
    communeDeliveryId: ''
  };
};

export default withFormik({
  mapPropsToValues: () => setInitialValues()
})(LocationFilter);
