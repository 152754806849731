import React from 'react';
import IcoMoon from '../../Utils/Icon';

const Answer = ({ comment }) => {
  return (
    <>
      <div
        key={comment.id}
        className="d-flex justify-content-end align-items-center w-100"
      >
        <div className="d-flex answer justify-content-start align-items-start w-100 ml-2">
          <IcoMoon size="20" icon="answer" className="mb-4" />
          {comment.user.avatarProfile?.fileUrlMinified ? (
            <div className="avatar_profile_img m-2 d-flex justify-content-center align-items-center">
              <img
                alt="avatar"
                src={comment.user.avatarProfile.fileUrlMinified}
              />
            </div>
          ) : (
            <div className="person-box p-1 d-flex justify-content-center align-items-center m-2">
              <IcoMoon size="20" icon="person" />
            </div>
          )}

          <div className="d-flex flex-column ">
            <p className=" text-left small-font-client">
              {comment.userName} - {comment.commentDate}
            </p>
            <p className=" text-left line-space">
              {comment.active
                ? comment.text
                : 'Esta respuesta ha sido desactivada.'}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Answer;
