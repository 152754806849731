import React from 'react';
import ImageWithZoom from '../../Utils/Image/ImageWithZoom';

const ClientItemDescription = ({
  images,
  title,
  height,
  length,
  width,
  weight,
  containerClass = '',
  description
}) => {
  return (
    <>
      <div className="items-description p-2 mr-2">
        <div className={containerClass}>
          <p className="font-weight-bold subtitle item-name">{title}</p>
          <p className="mt-1">
            {length}cm x {width}cm x {height}cm - {weight} kg
          </p>
        </div>
        <div className="d-flex images-scrollable">
          {images.map(image => (
            <div
              key={image.id}
              className="d-flex justify-content-center align-items-center img-container mr-2"
              style={{
                width: '50px',
                minWidth: '50px',
                height: '50px',
                overflow: 'hidden',
                borderRadius: '5px'
              }}
            >
              <ImageWithZoom
                src={image.fileUrlMinified}
                fullSizeImage={image.fileUrl}
              />
            </div>
          ))}
        </div>
        {description && <p className="mt-2 text-truncate">{description}</p>}
      </div>
    </>
  );
};

export default ClientItemDescription;
