const basicSolicitude = {
  id: '',
  deliveryStartDate: '',
  deliveryEndDate: '',
  deliveryPersonName: '',
  deliveryPersonPhone: '',
  description: '',
  pickupPersonName: '',
  pickupPersonPhone: '',
  pickupStartDate: '',
  pickupEndDate: '',
  pickupOption: '',
  deliveryOption: '',
  icon: '',
  itemName: '',
  startDate: '',
  endDate: '',
  clientName: '',
  hasQuotation: false,
  client: {
    id: '',
    name: '',
    lastName: '',
    fullName: '',
    description: ''
  },
  deliveryAddressAttributes: {
    id: '',
    streetName: '',
    specificNumber: null,
    communeId: '',
    regionId: '',
    communeName: '',
    regionName: ''
  },
  pickupAddressAttributes: {
    id: '',
    streetName: '',
    specificNumber: null,
    communeId: '',
    regionId: '',
    communeName: '',
    regionName: ''
  },
  itemsAttributes: [
    {
      id: '',
      name: '',
      isFragile: true,
      height: '',
      length: '',
      width: '',
      weight: '',
      netValue: '',
      itemImages: [
        {
          id: '',
          fileSignature: '',
          fileUrl: '',
          filename: '',
          cleanFilename: '',
          createdAt: ''
        }
      ]
    }
  ]
};

export default basicSolicitude;
