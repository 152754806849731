import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import { useFetchData } from '../../../hooks';
import { ComponentDataTable, DefaultHeader } from '../../../components';
import CustomHeaderFilter from '../../../components/Utils/Filter/CustomHeaderFilter';
import { Columns, conditionalDriverRows } from './data';
import { driverTripFilterParams } from '../../../components/Trips/TripsFilterParams';
import { debounceIndexTripsRequest } from '../../../requests/trips';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';
import { driverTripTabOptions } from '../../../components/Trip/TripFilterParams';

const DriverTripIndex = () => {
  const { push } = useHistory();
  const customParamsRef = useRef(driverTripFilterParams);
  const [customParams, setCustomParams] = useState(customParamsRef.current);

  const {
    data,
    totalData,
    handleIndexRequest,
    isFetching,
    moreData,
    setMoreData
  } = useFetchData({
    debouncedIndexRequest: debounceIndexTripsRequest,
    withDataTable: true,
    params: {},
    customParams: {
      ...customParamsRef.current,
      for_driver: true,
      selected_filter: 'false',
      sort_column: 'pickup_start_date',
      sort_direction: 'asc'
    }
  });

  const onRowClicked = row => {
    const { id } = row;
    push(`/driver/trips/${id}`);
  };

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <DefaultHeader
        title="Mis viajes"
        tooltip="Viajes planificados a realizar. Estos viajes puedes adjudicarlos a las ofertas que realices."
        body={
          <CustomHeaderFilter
            customParams={customParams}
            setCustomParams={setCustomParams}
            startDateField="dateFrom"
            endDateField="dateTo"
            pickupAddress="communeFrom"
            deliveryAddress="communeTo"
            queryPlaceholder="Buscar por comuna origen"
          />
        }
      />
      <div className="">
        <div className="data-table  p-0 mt-3 mb-4 dt-driver-trips">
          <ComponentDataTable
            data={data}
            totalRows={totalData}
            resourceRequest={handleIndexRequest}
            onRequest={isFetching}
            moreData={moreData}
            columns={Columns()}
            onRowClicked={onRowClicked}
            conditionalRowStyles={conditionalDriverRows}
            customDatatableHeader={
              <HeaderFilter
                customParams={customParams}
                setCustomParams={setCustomParams}
                paramName="driverFilterTrip"
                tabOptions={driverTripTabOptions}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default DriverTripIndex;
