import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexCommuneRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', '/communes', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const getCommuneByName = ({
  dispatch,
  params,
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', `/find_commune`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debouncedIndexCommuneRequest = AwesomeDebouncePromise(
  indexCommuneRequest,
  300
);
