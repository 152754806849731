import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import IcoMoon from '../Icon/IcoMoon';
import { DefaultModal } from '../Modal';

const ImageWithZoom = ({ src, fullSizeImage, isPortrait }) => {
  const [ShowModal, setShowModal] = useState(false);
  const fullImage = fullSizeImage || src;
  const classForPortraitImg = { padding: '28%' };
  return (
    <>
      <div
        aria-hidden
        onClick={() => setShowModal(true)}
        className="image-whit-zoom-div"
      >
        <Image className="image-whit-zoom" src={src} />
        <IcoMoon
          className="image-whit-zoom-search"
          icon="search1"
          style={isPortrait ? classForPortraitImg : {}}
        />
      </div>
      <DefaultModal
        title=""
        body={
          <div className="d-flex justify-content-center h-100 w-100 mt-4">
            <Image
              className="w-100 h-100 image-with-zoom-image-size"
              src={fullImage}
            />
          </div>
        }
        show={ShowModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => setShowModal(false)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size="xl"
        noButtons
        className="image-modal"
      />
    </>
  );
};

export default ImageWithZoom;
