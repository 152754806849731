import React from 'react';
import memoize from 'memoize-one';
import DriverRouteCell from '../../../components/TableCells/DriverRouteCell';
import PickUpDeliveryCell from '../../../components/TableCells/PickUpDeliveryCell';
import BriiingsCountCell from '../../../components/TableCells/BriiingsCountCell';
import AmmountCell from '../../../components/TableCells/AmmountCell';

const Columns = memoize(() => {
  return [
    {
      name: 'Ciudad de salida',
      grow: 2.2,
      sortable: false,
      selector: row => row.startLocation,
      cell: ({ id, pickupAddressAttributes, deliveryAddressAttributes }) => (
        <DriverRouteCell
          id={id}
          startLocation={pickupAddressAttributes.communeName}
          endLocation={deliveryAddressAttributes.communeName}
          status=""
          codeNumber=""
          onlyDirections
        />
      )
    },
    {
      name: 'Retiro',
      grow: 1.5,
      sortable: false,
      selector: row => row.pickupStartDate,
      cell: ({
        pickupOption,
        pickupStartDate,
        pickupEndDate,
        deliveryStartDate,
        deliveryEndDate,
        originDetour,
        destinyDetour,
        deliveryOption,
        startDate,
        endDate
      }) => (
        <PickUpDeliveryCell
          deliveryOption={deliveryOption}
          pickupOption={pickupOption}
          pickupStartDate={pickupStartDate}
          pickupEndDate={pickupEndDate}
          deliveryStartDate={deliveryStartDate}
          deliveryEndDate={deliveryEndDate}
          originDetour={originDetour}
          destinyDetour={destinyDetour}
          startDate={startDate}
          endDate={endDate}
        />
      )
    },
    {
      name: 'Entrega',
      grow: 1.5,
      sortable: false,
      selector: row => row.deliveryEndDate,
      cell: ({
        pickupOption,
        pickupStartDate,
        pickupEndDate,
        deliveryStartDate,
        deliveryEndDate,
        originDetour,
        destinyDetour,
        deliveryOption,
        startDate,
        endDate
      }) => (
        <PickUpDeliveryCell
          delivery
          deliveryOption={deliveryOption}
          pickupOption={pickupOption}
          pickupStartDate={pickupStartDate}
          pickupEndDate={pickupEndDate}
          deliveryStartDate={deliveryStartDate}
          deliveryEndDate={deliveryEndDate}
          originDetour={originDetour}
          destinyDetour={destinyDetour}
          startDate={startDate}
          endDate={endDate}
        />
      )
    },
    {
      name: 'Retiro',
      grow: 1,
      sortable: false,
      selector: row => row.pickUp,
      cell: ({ quotations }) => (
        <BriiingsCountCell quotationsCount={quotations.length} />
      )
    },
    {
      name: 'Nombre Item',
      grow: 1,
      sortable: false,
      selector: row => row.itemName,
      cell: ({ totalTripAmount }) => (
        <AmmountCell id="1" trip ammount={totalTripAmount || '0'} />
      )
    }
  ];
});

const conditionalDriverRows = [
  // {
  //   when: ({ solicitude: { icon } }) => icon === 'flame',
  //   classNames: ['bg-green']
  // },
  // {
  //   when: ({ solicitude: { icon } }) => icon === 'files',
  //   classNames: ['hover-green']
  // },
  // {
  //   when: ({ solicitude: { icon, amIBestQuotation } }) =>
  //     icon === 'shipping' && !amIBestQuotation,
  //   classNames: ['hover-yellow']
  // },
  // {
  //   when: ({ solicitude: { icon, amIBestQuotation } }) =>
  //     icon === 'shipping' && amIBestQuotation,
  //   classNames: ['hover-disabled']
  // }
];

export { Columns, conditionalDriverRows };
