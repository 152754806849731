import React from 'react';
import memoize from 'memoize-one';
import QuotationDriverName from '../../TableCells/QuotationDriverName';
import AmmountCell from '../../TableCells/AmmountCell';
import DateCell from '../../TableCells/DateCell';
import QuotationSelectOffer from '../../TableCells/QuotationSelectOffer';
import { vehiclesTypes } from '../../../screens/Driver/Profile/formOptions';

const Columns = memoize(({ modelUrl, expired }) => {
  return [
    {
      name: 'Driver',
      grow: 1.2,
      sortable: false,
      selector: ({ driver }) => driver.name,
      cell: ({ driver: { name, lastName, numOfStars, avatar } }) => (
        <QuotationDriverName
          driverName={`${name} ${lastName}`}
          numOfStars={numOfStars}
          profileImage={avatar?.fileUrlMinified}
        />
      )
    },
    {
      name: 'Amount',
      grow: 1,
      sortable: false,
      selector: row => row.cost,
      cell: ({ cost, id }) => <AmmountCell client id={id} ammount={cost} />
    },
    {
      name: 'Tipo de vehiculo',
      grow: 0.09,
      sortable: false,
      selector: ({ vehicle }) => vehicle?.vehicleType,
      cell: ({ vehicle }) => (
        <p className="mt-1">
          {
            vehiclesTypes.find(object => object.value === vehicle.vehicleType)
              .label
          }{' '}
          {vehicle?.carYear}
        </p>
      )
    },
    {
      name: 'Fechas',
      grow: 1.3,
      sortable: false,
      selector: ({ solicitude }) => solicitude.clientName,
      cell: ({
        solicitude: { id, startDate, endDate, pickupOption, deliveryOption }
      }) => (
        <DateCell
          id={id}
          startLocation="Retiro"
          endLocation="Entrega"
          startDate={startDate}
          endDate={endDate}
          pickupOption={pickupOption}
          deliveryOption={deliveryOption}
        />
      )
    },
    {
      name: 'Precio',
      grow: 1.2,
      sortable: false,
      cell: row => (
        <QuotationSelectOffer
          fromSolicitude
          quotation={row}
          modelUrl={modelUrl}
          expired={expired}
        />
      )
    }
  ];
});
export default Columns;
