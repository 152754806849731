import React from 'react';
import HomeContact from './HomeContact';

const HomeNotYet = () => {
  return (
    <HomeContact
      title="No tan rápido"
      titleDrop="aún no estamos 100% listos, pero puedes dejarnos tu email para avisarte cuando lo estemos."
      isPreRegister="true"
      isForPaymentRequest="true"
      buttonText="Avísenme!"
    />
  );
};

export default HomeNotYet;
