import React from 'react';
import { Rating } from '@mui/material';

const RatingControlled = ({ rating, setRating }) => {
  return (
    <Rating
      name="simple-controlled"
      value={rating}
      onChange={(event, newValue) => {
        setRating(newValue);
      }}
      size="large"
    />
  );
};

export default RatingControlled;
