export const tabOptions = [
  { label: 'Todas', value: 'else' },
  { label: 'Con ofertas', value: 'withQuotation' },
  { label: 'Sin ofertas', value: 'withoutQuotation' },
  { label: 'Briiinger asignado', value: 'asShipping' },
  { label: 'Caducadas', value: 'expired' }
];

export const clientSolicitudeFilterParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  filter_active: 'none',
  filterQuotation: 'else'
};

export const orderOptions = [
  { label: 'Monto mas alto', value: 'DESC netValue' },
  { label: 'Monto mas bajo', value: 'ASC netValue' },
  { label: 'Fecha mas cercana', value: 'ASC startDate' },
  { label: 'Fecha mas lejada', value: 'DESC startDate' }
];

export const driverSolicitudeFilterParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  originCommune: '',
  destinationCommune: '',
  filter_active: 'none',
  driverFilterQuotation: 'else'
};

export const tripTypeOptions = [
  { label: 'Ida y vuelta', value: 'goBack' },
  { label: 'Solo ida', value: 'justGo' }
];

export const driverSolicitudeTabOptions = [
  { label: 'Todas', value: 'else' },
  { label: 'Con ofertas', value: 'withQuotation' },
  { label: 'Sin ofertas', value: 'withoutQuotation' }
];
