import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const NotificationItem = props => {
  const { notification, handleClose } = props;
  const isDriver = localStorage.getItem('loginRole') === 'driver';
  const history = useHistory();
  const location = useLocation();
  const { timeAgo, message, read, url } = notification;

  const handleOnClick = () => {
    const currentUrl = location.pathname.slice(1);
    if (url === currentUrl) {
      handleClose();
      return;
    }
    if (url) {
      history.push(`/${url}`);
      window.location.reload(false);
      handleClose();
    }
  };

  return (
    <div
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      tabIndex={0}
      role="button"
      className={`notification-list--item py-2 ${read ? '' : 'unread'}
        ${isDriver ? 'hover-driver' : 'hover-client'} ${
        url ? 'cursor-pointer' : 'default-cursor'
      }`}
    >
      <div className="w-100">
        <div dangerouslySetInnerHTML={{ __html: message }} />
        <p className="text-right mt-2">{timeAgo}</p>
      </div>
    </div>
  );
};

export default NotificationItem;
