import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TabFilter from './TabFilter';

const HeaderFilter = ({
  customParams,
  paramName,
  setCustomParams,
  tabOptions
}) => {
  return (
    <Row className="index-filters d-flex justify-content-lg-end left-animation">
      <Col className="pl-0">
        <TabFilter
          paramName={paramName}
          optionsArray={tabOptions}
          customParams={customParams}
          setCustomParams={setCustomParams}
        />
      </Col>
    </Row>
  );
};

HeaderFilter.defaultProps = {
  customParams: {},
  paramName: '',
  setCustomParams: () => null,
  tabOptions: []
};

export default HeaderFilter;
