/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import readAllNotificationsRequest from '../../requests/notifications';
import NotificationItem from './NotificationItem';
import { getNotifications, getUnreadNotifications } from '../../actions/utils';

const NotificationList = ({
  handleClose,
  openPopUpNotification,
  spinnerColor
}) => {
  const loginRole = localStorage.getItem('loginRole');
  const dispatch = useDispatch();
  const {
    notifications: { data: notificationsList },
    ongoingRequest: { getNotifications: isFetching }
  } = useSelector(state => state.utils);

  const handleReadAllNotifications = () => {
    readAllNotificationsRequest({
      dispatch,
      successCallback: () => dispatch(getUnreadNotifications())
    });
  };

  // eslint-disable-next-line consistent-return
  const handleManageNotificationsWorkflow = () => {
    if (openPopUpNotification) {
      dispatch(getNotifications());
      return handleReadAllNotifications;
    }
  };

  useEffect(handleManageNotificationsWorkflow, [openPopUpNotification]);

  return (
    <>
      <h6
        className={`font-weight-bold mt-2 ml-3 ${
          loginRole === 'driver' ? 'text-primary' : 'text-secondary'
        }`}
      >
        Notificaciones
      </h6>
      <div className="d-flex justify-content-center flex-column align-items-center">
        {isFetching && (
          <Spinner
            className={`mt-3 spinner-notification ${spinnerColor}`}
            animation="border"
            variant="primary"
          />
        )}
        {!isFetching && (
          <div className="mt-1 w-100 container-notification-list">
            {notificationsList.length === 0 && !isFetching && (
              <p className="font-weight-500 text-center empty-msg mx-3">
                No tienes ninguna notificación para mostrar
              </p>
            )}
            {notificationsList.map(notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleClose={handleClose}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationList;
