import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showSolicitudeRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/solicitudes/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexSolicitudeRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', `/solicitudes${format || ''}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const mySolicitudesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', `/my_solicitudes${format || ''}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const createSolicitudeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback,
  callback
}) =>
  ApiService.request('post', '/solicitudes', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const updateSolicitudeRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback, callback }
) =>
  ApiService.request('put', `/solicitudes/${id}`, {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback,
    callback
  });

export const statusUpdateSolicitudeRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/solicitudes/${id}/status_update`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteSolicitudeRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/solicitudes/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const deactivateSolicitudeRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('put', `/solicitudes/${id}/deactivate`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexSolicitudesRequest = AwesomeDebouncePromise(
  indexSolicitudeRequest,
  300
);

export const debounceMySolicitudesRequest = AwesomeDebouncePromise(
  mySolicitudesRequest,
  300
);
