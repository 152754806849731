import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

const UrgencyButtons = ({
  side,
  selectedType,
  setFieldValue,
  modelValues,
  modelName,
  setUrgencyBtnSelected,
  rangeOptions,
  variantButton
}) => {
  const left = side === 'left';
  const { pickupOption, deliveryOption } = modelValues;
  const activeClass = `btn-active ${variantButton || 'secondary-outline'}`;

  const active1 = pickupOption === 'soon_as_possible' ? activeClass : '';
  const active2 = pickupOption === 'between_range' ? activeClass : '';
  const active3 = pickupOption === 'on_date' ? activeClass : '';
  const active4 = deliveryOption === 'soon_as_possible' ? activeClass : '';
  const active5 = deliveryOption === 'between_range' ? activeClass : '';
  const active6 = deliveryOption === 'on_date' ? activeClass : '';

  const optionField = left
    ? `${modelName}[pickupOption]`
    : `${modelName}[deliveryOption]`;

  const updateSoonAsPossible = () => {
    if (left) {
      setFieldValue(`${modelName}[pickupStartDate]`, '');
      setFieldValue(`${modelName}[pickupEndDate]`, '');
    } else {
      setFieldValue(`${modelName}[deliveryStartDate]`, '');
      setFieldValue(`${modelName}[deliveryEndDate]`, '');
    }
  };

  const selectedBtn = () => {
    setUrgencyBtnSelected({
      left: !!left,
      right: !left
    });
  };

  const clearFields = () => {
    if (left) {
      setFieldValue(`${modelName}[pickupStartDate]`, '');
      setFieldValue(`${modelName}[pickupEndDate]`, '');
    } else {
      setFieldValue(`${modelName}[deliveryStartDate]`, '');
      setFieldValue(`${modelName}[deliveryEndDate]`, '');
    }
  };

  const updateOnDate = () => {
    if (left) {
      setFieldValue(`${modelName}[pickupStartDate]`, moment().toDate());
      setFieldValue(`${modelName}[pickupEndDate]`, moment().toDate());
    } else {
      setFieldValue(`${modelName}[deliveryStartDate]`, moment().toDate());
      setFieldValue(`${modelName}[deliveryEndDate]`, moment().toDate());
    }
  };

  const updateBetweenRange = () => {
    if (left) {
      setFieldValue(`${modelName}[pickupStartDate]`, moment().toDate());
      setFieldValue(`${modelName}[pickupEndDate]`, '');
    } else {
      setFieldValue(`${modelName}[deliveryStartDate]`, moment().toDate());
      setFieldValue(`${modelName}[deliveryEndDate]`, '');
    }
  };

  return (
    <div className="mb-3">
      {rangeOptions.includes('soon_as_possible') && (
        <Button
          className={`mt-1 mr-3 btn-urgency ${left ? active1 : active4}`}
          variant={variantButton || 'secondary-outline'}
          onClick={() => {
            updateSoonAsPossible();
            selectedBtn();
            selectedType(`${side}-soon_as_possible`);
            setFieldValue(optionField, 'soon_as_possible');
          }}
        >
          Lo antes posible
        </Button>
      )}
      {rangeOptions.includes('between_range') && (
        <Button
          className={`mt-1 mr-3 btn-urgency ${left ? active2 : active5}`}
          variant={variantButton || 'secondary-outline'}
          onClick={() => {
            selectedBtn();
            selectedType(`${side}-between_range`);
            setFieldValue(optionField, 'between_range');
            clearFields();
            updateBetweenRange();
          }}
        >
          Entre
        </Button>
      )}
      {rangeOptions.includes('on_date') && (
        <Button
          className={`mt-1 btn-urgency ${left ? active3 : active6}`}
          variant={variantButton || 'secondary-outline'}
          onClick={() => {
            selectedBtn();
            selectedType(`${side}-on_date`);
            setFieldValue(optionField, 'on_date');
            clearFields();
            updateOnDate();
          }}
        >
          Escoger un día
        </Button>
      )}
    </div>
  );
};

export default UrgencyButtons;
