import React from 'react';
import moment from 'moment';

const IsTodayCell = ({ dateToCheck }) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const isToday = currentDate === dateToCheck;
  return (
    <>
      {isToday ? (
        <div
          data-tag="allowRowEvents"
          className="d-flex justify-content-center align-items-center is-today"
        >
          <p data-tag="allowRowEvents">Es hoy!</p>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default IsTodayCell;
