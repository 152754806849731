import ApiService from '../services/apiService';

// eslint-disable-next-line import/prefer-default-export
export const createPaymentRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/payments', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const commitPaymentRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/payments/commit', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
