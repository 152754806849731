import React from 'react';
import PropTypes from 'prop-types';
import { CenteredModal } from './index';
import SuccessIcon from '../../../assets/images/success-icon.svg';

const SuccessAlert = ({
  show,
  message,
  onClickHide,
  onClickConfirm,
  titlebtn,
  variantBtn,
  ...props
}) => {
  return (
    <CenteredModal
      {...props}
      size="sm"
      titlebtn={titlebtn}
      variantBtn={variantBtn || 'success'}
      onHide={onClickHide}
      onClickHide={onClickHide}
      onClickConfirm={onClickConfirm}
      show={show}
      body={
        <div className="d-flex align-items-center flex-column">
          <img src={SuccessIcon} alt="success-icon" />
          <h6 className="my-3">{message || 'Proceso exitoso'}</h6>
        </div>
      }
    />
  );
};

SuccessAlert.propTypes = {
  onClickHide: PropTypes.func,
  show: PropTypes.bool
};

SuccessAlert.defaultProps = {
  onClickHide: () => null,
  show: () => null
};

export default SuccessAlert;
