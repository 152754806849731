import React, { useRef, useState } from 'react';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer
} from '@react-google-maps/api';
import { Button, Row, Col, Spinner } from 'react-bootstrap';

const center = { lat: -33.422417, lng: -70.610563 };

const GoogleRouteMap = ({
  startPoint,
  endPoint,
  showRouteControls,
  showDsitanceAndDurationTrip,
  zoomControl,
  streetViewControl,
  mapTypeControl,
  fullscreenControl,
  setDistanceAndDuration
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  if (!isLoaded) {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      </>
    );
  }

  async function calculateRoute() {
    // Un comment next lines if query come from showRouteControls
    // if (originRef.current.value === '' || destiantionRef.current.value === '') {
    //   return
    // }

    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: startPoint, // replace for originRef if query from showRouteControls
      destination: endPoint, // replace for destiantionRef if query from showRouteControls
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
    setDistanceAndDuration(distance, duration);
  }

  // function clearRoute() {
  //   setDirectionsResponse(null);
  //   setDistance('');
  //   setDuration('');
  //   originRef.current.value = '';
  //   destiantionRef.current.value = '';
  // }

  const options = {
    zoomControl,
    streetViewControl,
    mapTypeControl,
    fullscreenControl
  };

  calculateRoute();

  if (map) console.log('Map Loaded');

  return (
    <div className="google-map">
      <div className="google-map">
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={options}
          onLoad={mapData => {
            setMap(mapData);
          }}
        >
          <Marker position={center} />
          {/* Put as many Marker as you desire... */}
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div>
      {showRouteControls && (
        <Row className="map-controls d-flex flex-column justify-content-center align-items-center">
          <Row className="d-flex justify-content-between align-items-center w-100">
            <Col>
              <Autocomplete>
                <input
                  className="py-1"
                  type="text"
                  placeholder="Origin"
                  ref={originRef}
                />
              </Autocomplete>
            </Col>
            <Col>
              <Autocomplete>
                <input
                  className="py-1"
                  type="text"
                  placeholder="Destination"
                  ref={destiantionRef}
                />
              </Autocomplete>
            </Col>
            <Col>
              <Button className="btn btn-sm" onClick={() => calculateRoute()}>
                Ruta
              </Button>
            </Col>
          </Row>
          {showDsitanceAndDurationTrip && (
            <Row className="d-flex justify-content-around align-items-center w-100">
              <h7>
                <strong>Distancia: </strong> {distance}
              </h7>
              <h7>
                <strong>Duracion: </strong> {duration}
              </h7>
            </Row>
          )}
        </Row>
      )}
    </div>
  );
};

export default GoogleRouteMap;
