export const shipmentFilterParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  filterByStatus: 'completed_shipping'
};

export const driverShipmentFilterParams = {
  query: '',
  dateTo: '',
  dateFrom: '',
  filterByStatus: 'else'
};

export const shipmentTabOptions = [
  { label: 'Todos', value: 'else' },
  { label: 'Por realizar', value: 'initialized' },
  { label: 'En ruta', value: 'picked_up' },
  { label: 'Entregados', value: 'delivered' },
  { label: 'Finalizados', value: 'finished' }
];
