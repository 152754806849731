import React, { useState, useEffect } from 'react';
import { Col, FormControl, Form, Row, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { customStyles } from './customStyles';
import SpinnerLoader from '../Spinner/index';

// https://www.npmjs.com/package/react-data-table-component

const ComponentDataTable = ({
  moreData,
  RowsPage,
  handleSortCase,
  resourceRequest,
  rangePicker,
  classNameSpinner,
  noResultsMsg,
  ...props
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [textInput, setTextInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPage, setRowsPage] = useState(RowsPage);
  const [column, setColumn] = useState('created_at');
  const [direction, setDirection] = useState('desc');
  const loginRole = localStorage.getItem('loginRole');

  const setRequest = params => {
    let sortColumn = { sort_column: column };
    let dateFilter = { date_from: startDate, date_to: endDate };
    if (rangePicker && startDate && endDate) {
      dateFilter = { date_range: [startDate, endDate] };
    }
    if (handleSortCase) {
      sortColumn = handleSortCase(column);
    }
    resourceRequest({
      query: textInput,

      display_start: page * rowsPage,
      display_length: rowsPage,
      sort_direction: direction,
      ...dateFilter,
      ...sortColumn,
      ...moreData,
      ...params
    });
  };

  const onChangePage = pageValue => {
    setPage(pageValue - 1);
    setRequest({ display_start: (pageValue - 1) * rowsPage });
  };

  const onChangeRowsPage = RowsPageValue => {
    setRowsPage(RowsPageValue);
    setRequest({ display_start: 0, display_length: RowsPageValue });
  };

  const handleSort = (colValue, dirValue) => {
    setDirection(dirValue);
    setPage(0);
    setColumn(colValue.selector);
    setRequest({
      display_start: 0,
      sort_direction: dirValue
    });
  };

  const handleRowSelection = (selection, handleSelect) => {
    if (!handleSelect) {
      return;
    }
    handleSelect(selection.selectedRows);
  };

  useEffect(setRequest, [moreData, startDate, endDate, textInput, column]);
  const {
    onRequest,
    data,
    totalRows,
    withDates,
    handleSelect,
    subHeader = true,
    customDatatableHeader,
    noSpinner
  } = props;

  return (
    <div>
      {onRequest && !noSpinner && (
        <SpinnerLoader
          customClassName={`spinner-datatable ${classNameSpinner} ${
            !customDatatableHeader ? 'no-header' : ''
          }`}
          animation="border"
          variant={loginRole === 'driver' ? 'primary' : 'secondary'}
        />
      )}
      <DataTable
        className="custom-data-table"
        pagination={!onRequest}
        noDataComponent={
          onRequest ? (
            <span className={`ml-n5 ${!noSpinner ? 'd-none' : ''}`}>
              <Spinner
                animation="border"
                variant={loginRole === 'driver' ? 'primary' : 'secondary'}
                className="ml-1"
                size="md"
              />
            </span>
          ) : (
            <span className="custom-data-table-placeholder opacity-animation mt-3 ml-n5">
              {noResultsMsg || 'No se encontraron datos.'}
            </span>
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowsPage}
        paginationRowsPerPageOptions={[10, 25, 50]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por pagina:',
          rangeSeparatorText: 'de'
        }}
        sortServer
        onRowSelected={memoize(selection =>
          handleRowSelection(selection, handleSelect)
        )}
        onSort={(colValue, dirValue) => handleSort(colValue, dirValue)}
        sortFunction={a => a}
        noHeader
        subHeader={subHeader}
        disabled={onRequest}
        data={data}
        customStyles={customStyles(loginRole)}
        subHeaderComponent={
          <Col className="p-0">
            {customDatatableHeader || (
              <Row className="datatable-sub-header">
                {withDates && (
                  <Col xs={12} lg={4}>
                    <Row>
                      <Col>
                        <Form.Group className="form-datepicker date-input">
                          <DatePicker
                            placeholderText="Desde"
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            showYearDropdown
                            selected={startDate}
                            onChange={date => {
                              setStartDate(date);
                              setPage(0);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="form-datepicker date-input">
                          <DatePicker
                            placeholderText="Hasta"
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            showYearDropdown
                            selected={endDate}
                            onChange={date => {
                              setEndDate(date);
                              setPage(0);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col lg={3} className="pl-0 pb-0">
                  <Form.Group className="form-input mb-0">
                    <FormControl
                      placeholder="Buscar"
                      name="textInput"
                      value={textInput}
                      onChange={e => {
                        setPage(0);
                        setTextInput(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </Col>
        }
        {...props}
      />
    </div>
  );
};

ComponentDataTable.propTypes = {
  onRequest: PropTypes.bool,
  totalRows: PropTypes.number,
  RowsPage: PropTypes.number,
  moreData: PropTypes.bool,
  resourceRequest: PropTypes.func
};

ComponentDataTable.defaultProps = {
  onRequest: false,
  totalRows: 0,
  RowsPage: 25,
  moreData: false,
  resourceRequest: () => null
};

export default ComponentDataTable;
