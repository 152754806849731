import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput, BasicTextArea } from '../../components';
import { sendContactFormRequest } from '../../requests/utils';
import { sendAlert } from '../../actions/utils';
import { driverPaymentRequest } from '../../requests/user';

const HomeContact = props => {
  const [disabledButton, setDisabledButton] = useState(true);
  const {
    isForPaymentRequest = false,
    onHide,
    isValid,
    submitVariant,
    errors,
    touched,
    reusable,
    email,
    setFieldValue,
    title,
    titleDrop,
    subTitle,
    buttonText
  } = props;

  const handleDisabledButton = () => {
    if (!isForPaymentRequest) {
      return reusable ? disabledButton || !isValid : !isValid;
    }

    return false;
  };

  useEffect(() => {
    if (email) {
      setFieldValue('email', email);
      setDisabledButton(false);
    }
  }, []);

  return (
    <Row
      className={
        reusable
          ? ''
          : 'justify-content-center align-items-center contact-height'
      }
    >
      <Col md={reusable ? 12 : 4}>
        <h4 className={reusable ? 'mb-4 mt-n4' : 'mb-2'}>
          {title || 'Contactar'}
          <p>{titleDrop}</p>
        </h4>
        {subTitle && <p className="mb-2">{subTitle}</p>}
        <Form>
          {!email && (
            <Field name="email">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="E-mail"
                  placeholder="Email"
                  inputType="email"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
          )}
          {!isForPaymentRequest && (
            <Field name="message">
              {({ field }) => (
                <BasicTextArea
                  {...field}
                  abbr
                  label="Mensaje"
                  placeholder="Escribe aquí tu mensaje..."
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
          )}
          <Button
            variant={submitVariant}
            size="lg"
            className="mt-4"
            block
            type="submit"
            disabled={handleDisabledButton}
            onClick={onHide}
          >
            {buttonText || 'Enviar'}
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const initialValues = {
  email: '',
  message: ''
};

const validationSchema = ({ isForPaymentRequest }) => {
  if (isForPaymentRequest) return Yup.object().shape({});

  return Yup.object().shape({
    email: Yup.string()
      .email('El email que ingresaste no es válido')
      .required('Este campo es obligatorio'),
    message: Yup.string().required('Este campo es obligatorio')
  });
};

const handleSubmit = (values, { props, resetForm }) => {
  const {
    dispatch,
    hideModal,
    isForPaymentRequest,
    isPreRegister = false
  } = props;
  const handleSuccessCallback = result => {
    resetForm();
    if (hideModal) hideModal();
    dispatch(sendAlert({ kind: 'success', message: result.data.message }));
  };

  if (isPreRegister) {
    sendContactFormRequest({
      dispatch,
      params: {
        email: values.email,
        message: `Nuevo mail de persona interesada: ${values.email}`
      },
      successCallback: handleSuccessCallback
    });
    return;
  }

  if (isForPaymentRequest) {
    driverPaymentRequest({
      dispatch,
      successCallback: handleSuccessCallback
    });

    return;
  }

  sendContactFormRequest({
    dispatch,
    params: {
      email: values.email,
      message: values.message
    },
    successCallback: handleSuccessCallback
  });
};

export default connect()(
  withFormik({
    mapPropsToValues: () => initialValues,
    validationSchema,
    handleSubmit
  })(HomeContact)
);
