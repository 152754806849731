import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import IcoMoon from '../../Utils/Icon/IcoMoon';
import { DefaultModal } from '../../Utils/Modal';
import GooglePointMap from '../../Utils/GoogleMap/GooglePointMap';

const StatusCard = ({ personName, personPhone, addressAttributes }) => {
  const [showMap, setShowMap] = useState(false);
  const address = `${addressAttributes.streetName}, ${addressAttributes.communeName}`;

  return (
    <>
      <Col
        sm={12}
        md={12}
        lg={6}
        className="px-3 status-card my-2 d-flex flex-column justify-content-between align-items-between"
      >
        <div className="d-flex justify-content-between align-items-center mb-4 position-relative">
          <p className="status-card--address_text">Dirección: {address}</p>
          <div
            onClick={() => setShowMap(true)}
            aria-hidden="true"
            className="d-flex justify-content-center align-items-center px-1 mx-1 text-dark location-car-box"
          >
            <IcoMoon
              className="icon-primary text-dark "
              size="20"
              icon="location1"
            />
          </div>
        </div>
        <p>
          <strong>Entrega: </strong>
          {personName}
        </p>
        <div className="d-flex justify-content-between align-items-center my-4">
          <p>
            <strong>Cel:</strong> {personPhone}
          </p>

          <div className="mr-2">
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://api.whatsapp.com/send?phone=${personPhone}&text=¡Hola ${personName}, te escribo para coordinar por el envio de Briiing! 📦🚚`}
            >
              <IcoMoon
                roundBorder
                roundColor="#bcf3cf"
                size="18"
                icon="whatsapp"
                className="hover-wsp"
                classNameContainer="hover-wsp"
              />
            </a>
          </div>
        </div>
      </Col>

      <DefaultModal
        title={address}
        body={
          <div style={{ width: '100%', height: '500px' }}>
            <GooglePointMap address={address} />
          </div>
        }
        show={showMap}
        handleClose={() => setShowMap(false)}
        handleConfirm={() => setShowMap(false)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size="xl"
        noButtons
      />
    </>
  );
};

export default StatusCard;
