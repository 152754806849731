import React from 'react';
import IconCell from '../../../components/TableCells/IconCell';
import ClientCell from '../../../components/TableCells/ClientCell';
import AmmountCell from '../../../components/TableCells/AmmountCell';
// import IsTodayCell from '../../../components/TableCells/IsTodayCell';
import ClientItemCell from '../../../components/TableCells/ClientItemCell';
import DateCell from '../../../components/TableCells/DateCell';
import QuotationCell from '../../../components/TableCells/QuotationCell';

const columns = [
  {
    name: 'Icon',
    grow: 0.05,
    sortable: false,
    selector: row => row.icon,
    cell: ({ id, hasQuotation, selectedQuotation, icon, iconText }) => (
      <IconCell
        id={id}
        icon={icon}
        hasQuotation={hasQuotation}
        hasSelectedQuotation={!!selectedQuotation}
        message={iconText}
      />
    )
  },
  {
    name: 'Nombre Item',
    grow: 1.4,
    sortable: false,
    selector: row => row.itemName,
    cell: ({ id, itemsAttributes }) => (
      <ClientItemCell
        id={id}
        itemName={itemsAttributes[0]?.name}
        codeNumber={id.split('-')[0]}
        imageUrl={itemsAttributes[0].itemImages[0]?.fileUrlMinified}
      />
    )
  },
  {
    name: 'Fecha Viaje',
    grow: 1.5,
    sortable: false,
    selector: row => row.startDate,
    cell: ({
      id,
      deliveryAddressAttributes,
      pickupAddressAttributes,
      hasQuotation,
      selectedQuotation,
      startDate,
      endDate,
      pickupOption,
      deliveryOption,
      expired
    }) => (
      <DateCell
        id={id}
        endLocation={deliveryAddressAttributes.communeName}
        startLocation={pickupAddressAttributes.communeName}
        startDate={startDate}
        endDate={endDate}
        hasQuotation={hasQuotation}
        hasSelectedQuotation={!!selectedQuotation}
        pickupOption={pickupOption}
        deliveryOption={deliveryOption}
        expired={expired}
      />
    )
  },
  {
    name: row => (row?.hasTrip ? 'Nombre Cliente' : 'Cotizaciones'),
    grow: 1.4,
    sortable: false,
    selector: row => (row?.hastrip ? row.clientName : row?.quotationCount),
    cell: ({
      id,
      hasQuotation,
      selectedQuotation,
      quotationCount,
      quotationCountMessage,
      expired
    }) => {
      if (selectedQuotation)
        return (
          <ClientCell
            id={id}
            fristName={selectedQuotation?.driver?.name}
            lastName={selectedQuotation?.driver?.lastName}
            profileImage={selectedQuotation?.driverAvatar?.fileUrlMinified}
          />
        );
      return (
        <QuotationCell
          id={id}
          hasQuotation={hasQuotation}
          quotationCount={quotationCount}
          quotationMessage={quotationCountMessage}
          expired={expired}
        />
      );
    }
  },
  {
    name: 'Precio',
    grow: 1,
    sortable: false,
    selector: row => row.ammount,
    cell: ({ selectedQuotation, hasQuotation }) => (
      <AmmountCell
        client
        ammount={selectedQuotation ? selectedQuotation.cost : null}
        paidByClient={selectedQuotation ? selectedQuotation.paid : false}
        hasQuotation={hasQuotation}
      />
    )
  }
  // {
  //   name: 'IsToday',
  //   grow: 1,
  //   sortable: false,
  //   selector: row => row.isToday,
  //   cell: ({ id, pickupAt }) => <IsTodayCell id={id} dateToCheck={pickupAt} />
  // }
];

const conditionalClientRows = [
  {
    when: row => row.hasQuotation && Boolean(row.selectedQuotation),
    classNames: ['bg-green']
  },
  {
    when: row => !!row.hasQuotation && !row.selectedQuotation,
    classNames: ['bg-orange']
  },
  {
    when: row => !row.hasQuotation,
    classNames: ['bg-disabled']
  },
  {
    when: row => row.expired,
    classNames: ['bg-expired']
  }
];

const dashboardColumns = [
  {
    name: 'Icon',
    grow: 0.05,
    sortable: false,
    selector: row => row.icon,
    cell: ({ solicitude: { id, hasQuotation, selectedQuotation } }) => (
      <IconCell
        id={id}
        icon="car"
        hasQuotation={hasQuotation}
        hasSelectedQuotation={!!selectedQuotation}
      />
    )
  },
  {
    name: 'Nombre Item',
    grow: 1.5,
    sortable: false,
    selector: row => row.itemName,
    cell: ({ solicitude: { id, itemsAttributes } }) => (
      <ClientItemCell
        id={id}
        itemName={itemsAttributes[0]?.name}
        codeNumber={id.split('-')[0]}
        imageUrl={itemsAttributes[0].itemImages[0]?.fileUrlMinified}
      />
    )
  },
  {
    name: 'Fecha Viaje',
    grow: 1.4,
    sortable: false,
    selector: row => row.startDate,
    cell: ({
      solicitude: {
        id,
        deliveryAddressAttributes,
        pickupAddressAttributes,
        hasQuotation,
        selectedQuotation,
        startDate,
        endDate,
        pickupOption,
        deliveryOption
      }
    }) => (
      <DateCell
        id={id}
        endLocation={deliveryAddressAttributes.communeName}
        startLocation={pickupAddressAttributes.communeName}
        startDate={startDate}
        endDate={endDate}
        hasQuotation={hasQuotation}
        hasSelectedQuotation={!!selectedQuotation}
        pickupOption={pickupOption}
        deliveryOption={deliveryOption}
      />
    )
  },
  {
    name: row => (row?.hasTrip ? 'Nombre Cliente' : 'Cotizaciones'),
    grow: 1.4,
    sortable: false,
    selector: row => (row?.hastrip ? row.clientName : row?.quotationCount),
    cell: ({
      solicitude: {
        id,
        hasQuotation,
        selectedQuotation,
        quotationCount,
        quotationCountMessage,
        expired
      }
    }) => {
      if (selectedQuotation)
        return (
          <ClientCell
            id={id}
            fristName={selectedQuotation?.driver?.name}
            lastName={selectedQuotation?.driver?.lastName}
            profileImage={selectedQuotation?.driverAvatar?.fileUrlMinified}
          />
        );
      return (
        <QuotationCell
          id={id}
          hasQuotation={hasQuotation}
          quotationCount={quotationCount}
          quotationMessage={quotationCountMessage}
          expired={expired}
        />
      );
    }
  },
  {
    name: 'Precio',
    grow: 1,
    sortable: false,
    selector: row => row.ammount,
    cell: ({ solicitude: { selectedQuotation } }) => (
      <AmmountCell
        ammount={selectedQuotation ? selectedQuotation.cost : null}
        paidByClient={selectedQuotation ? selectedQuotation.paid : false}
      />
    )
  }
  // {
  //   name: 'IsToday',
  //   grow: 1,
  //   sortable: false,
  //   selector: row => row.isToday,
  //   cell: ({ solicitude: { id, pickupAt } }) => (
  //     <IsTodayCell id={id} dateToCheck={pickupAt} />
  //   )
  // }
];

export { columns, conditionalClientRows, dashboardColumns };
