import ApiService from '../services/apiService';

export const clientDashboardRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', '/dashboards/client', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const driverDashboardRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', '/dashboards/driver', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
