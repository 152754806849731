import React from 'react';
import memoize from 'memoize-one';
import IcoMoon from '../../Utils/Icon/IcoMoon';

const Columns = memoize(() => {
  return [
    {
      name: 'Icon',
      grow: 0.1,
      sortable: false,
      cell: () => <IcoMoon icon="car1" />
    },
    {
      name: 'Marca',
      grow: 1.5,
      sortable: false,
      selector: row => row.carMake
    },
    {
      name: 'Modelo',
      grow: 1.5,
      sortable: false,
      selector: row => row.carModel
    },
    {
      name: 'Año',
      grow: 0.5,
      sortable: false,
      selector: row => row.carYear
    },
    {
      name: 'Patente',
      grow: 0.5,
      sortable: false,
      selector: row => row.licensePlate.toUpperCase()
    },
    {
      name: 'Tamaño',
      grow: 1,
      sortable: false,
      selector: () => 'Grande'
    },
    {
      name: 'Dimensiones',
      grow: 3,
      sortable: false,
      selector: row => row.fullDimensions
    }
  ];
});

export default Columns;

export const conditionalRows = [
  {
    when: row => row.isSelected,
    style: {
      backgroundColor: '#FFEFEF',
      border: '2px solid #FF5E62'
    }
  }
];

export const customStyles = {
  table: {
    style: {
      padding: 10
    }
  },
  rows: {
    style: {
      border: '1px',
      borderRadius: '12px',
      marginBottom: 10,
      boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 6px 0px;'
    }
  }
};
