import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../components';
import { updateUserPasswordRequest } from '../../requests/user';
import { sendAlert } from '../../actions/utils';

const ChangePassword = props => {
  const loginRole = localStorage.getItem('loginRole');
  const isClient = loginRole === 'client';
  const { onHide, isValid, errors, touched } = props;
  return (
    <Form className="login_box mt-n2">
      <h5 className="text-center mb-3">Cambiar contraseña</h5>
      <Field name="oldPassword">
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            inputType="password"
            label="Contraseña anterior"
            placeholder="Contraseña"
            error={errors[field.name]}
            touched={touched[field.name]}
          />
        )}
      </Field>
      <Field name="password">
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            inputType="password"
            label="Nueva contraseña"
            placeholder="Contraseña"
            error={errors[field.name]}
            touched={touched[field.name]}
          />
        )}
      </Field>
      <Field name="passwordConfirmation">
        {({ field }) => (
          <FormikInput
            {...field}
            abbr
            inputType="password"
            label="Confirmar nueva contraseña"
            placeholder="Confirmar Contraseña"
            error={errors[field.name]}
            touched={touched[field.name]}
          />
        )}
      </Field>
      <Button
        variant={isClient ? 'secondary' : 'primary'}
        size="lg"
        className="btn login-btn"
        block
        type="submit"
        disabled={!isValid}
        onClick={onHide}
      >
        Cambiar Contraseña
      </Button>
    </Form>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  oldPassword: '',
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test(
      'password-confirmation-test',
      'Las contraseñas no coinciden',
      function equalTo(value) {
        if (value && value === this.parent.password) {
          return true;
        }
        return false;
      }
    )
});

const handleSubmit = (values, { props }) => {
  const { dispatch, setModalShow } = props;
  updateUserPasswordRequest({
    dispatch,
    params: {
      user: {
        current_password: values.oldPassword,
        password: values.password
      }
    },
    successCallback: result => {
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
      setModalShow(false);
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(ChangePassword)
  )
);
