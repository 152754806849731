import React from 'react';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MainLogo from '../MainLogo';
import IcoMoon from '../Icon';

const Footer = props => {
  const { settings } = props;
  return (
    <Navbar
      className="footer footer-content"
      expand="lg"
      variant="light"
      bg="light"
    >
      <div>
        <div className="footer-content-info">
          <Navbar.Brand as={Link} to="/">
            <MainLogo />
          </Navbar.Brand>
          <div className="mr-auto footer-content-info-description">
            <Nav className="mr-auto">
              <Nav.Link
                as={Link}
                to={`/${settings.linkTerms}`}
                className="nav-button"
              >
                Términos y condiciones
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={`/${settings.linkPrivacyPolicies}`}
                className="nav-button"
              >
                Políticas de privacidad
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" className="nav-button">
                Contáctanos
              </Nav.Link>
            </Nav>
          </div>
          <div className="navbar-nav rrss-content">
            <p>Síguenos</p>

            <a
              className="icon-footer"
              href={settings.linkTiktok}
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon size="25" icon="tiktok" />
            </a>

            <a
              className="icon-footer"
              href={settings.linkInstagram}
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon size="25" icon="instagram" />
            </a>

            <a
              className="icon-footer"
              href={settings.linkLinkedin}
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon size="25" icon="linkedin" />
            </a>

            <a
              className="icon-footer"
              href={settings.linkFacebook}
              target="_blank"
              rel="noreferrer"
            >
              <IcoMoon size="25" icon="facebook" />
            </a>
          </div>
        </div>
        <Row>
          <Col className="sign">
            <p>
              Desarrollado por{' '}
              <a href="https://nnodes.com/" target="_blank" rel="noreferrer">
                {' '}
                Nnodes
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </Navbar>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  const { settings } = state.utils;
  return {
    ongoingRequest,
    signedIn,
    settings
  };
};

export default connect(mapStateToProps)(Footer);
