import React from 'react';
import IcoMoon from '../Utils/Icon/IcoMoon';

const FormImportantInfo = ({ setCloseWarningCard }) => {
  return (
    <div className="form-extra--info mt-2">
      <div className="d-flex justify-content-between">
        <h6 className="mt-1">Precaución!</h6>
        <IcoMoon
          icon="close"
          className="cursor-pointer"
          onClick={() => setCloseWarningCard(false)}
        />
      </div>
      <ul>
        <li>Tu pago sera liberado al finalizar las entregas</li>
        <li>
          En caso de ser elegido tendras acceso a la informacion del cliente
        </li>
        <li>
          Para cancelar un envio sin costo debe ser al menos con 72hrs de
          antelación
        </li>
      </ul>
    </div>
  );
};

export default FormImportantInfo;
