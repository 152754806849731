import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

const QuotationCell = ({
  hasQuotation,
  hasMyOffer,
  module = 'client',
  quotationMessage,
  quotationCount,
  buttonName,
  buttonUrl,
  disabledButton,
  buttonVariant = 'success',
  expired
}) => {
  const history = useHistory();
  const location = useLocation();
  const isClient = location.pathname.includes('client');

  const defineMessage = () => {
    if (!hasQuotation) return 'Sin ofertas';
    const hasMoreThanOneQuotation = quotationCount > 0;
    if (hasMoreThanOneQuotation) return quotationMessage;

    return '1 cotización';
  };
  const defineBackground = () => {
    if (module === 'driver') {
      if (hasMyOffer) return 'bg-disabled';
      return 'bg-green';
    }
    if (expired) return 'bg-expired';
    if (hasQuotation) return 'bg-orange';
    return 'bg-disabled';
  };
  const handleCellCLick = () => {
    history.push(buttonUrl);
  };
  return (
    <div
      role="button"
      data-tag="allowRowEvents"
      tabIndex={0}
      className="d-flex"
    >
      <Card.Text
        data-tag="allowRowEvents"
        className={`text-center py-2 px-1 m-2 quotations-box ${defineBackground()}`}
        style={{ width: '200px' }}
      >
        {defineMessage()}
      </Card.Text>

      {!disabledButton && (
        <div
          role="button"
          data-tag="allowRowEvents"
          tabIndex={0}
          className={`view-offer right-animation ${isClient ? 'd-none' : ''} `}
        >
          <Button onClick={handleCellCLick} variant={buttonVariant}>
            {buttonName || 'Ver oferta'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default QuotationCell;
