import React from 'react';
import IcoMoon from '../Utils/Icon';

const QuotationDriverName = ({
  driverName = 'Pedro',
  numOfStars,
  profileImage
}) => {
  const whitoutRating = numOfStars === 'Sin calificación';

  const getItemProfile = () => {
    if (profileImage) {
      return (
        <div className="avatar_profile_img d-flex justify-content-center align-items-center">
          <img alt="avatar" src={profileImage} />
        </div>
      );
    }
    return <IcoMoon style={{ marginRight: '5px' }} icon="person" size={40} />;
  };

  return (
    <>
      {getItemProfile()}
      <div
        className={`d-flex ${
          whitoutRating
            ? 'flex-column align-items-start'
            : 'flex-row align-items-center'
        } justify-content-center  align-content-center `}
      >
        <p
          data-tag="allowRowEvents"
          className={`font-weight-bold  ${whitoutRating ? 'text-center' : ''}`}
        >
          {driverName}
        </p>
        <span
          data-tag="allowRowEvents"
          className={`d-flex justify-content-center align-items-center score-box ${
            whitoutRating ? 'ml-0' : 'ml-1'
          }`}
        >
          <IcoMoon data-tag="allowRowEvents" size="17" icon="star-full" />
          <p data-tag="allowRowEvents" className="mt-1 ">
            {numOfStars}
          </p>
        </span>
      </div>
    </>
  );
};

export default QuotationDriverName;
