import React from 'react';
import { Form } from 'react-bootstrap';

const BasicTextArea = ({
  inputType,
  placeholder,
  helpText,
  label,
  size,
  row,
  error,
  touched,
  abbr,
  ...props
}) => (
  <Form.Group>
    {label ? (
      <Form.Label>
        {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
      </Form.Label>
    ) : (
      false
    )}

    <Form.Control
      as="textarea"
      rows={row}
      size={size}
      type={inputType}
      placeholder={placeholder}
      className={error && touched ? 'is-invalid' : ''}
      {...props}
    />
    {error && touched ? (
      <Form.Text className="text-danger">{error}</Form.Text>
    ) : null}
    {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
  </Form.Group>
);

export default BasicTextArea;
