import { useRef, useState } from 'react';
import snakeCaseKeys from 'snakecase-keys';

import { emptyFalseParamsRecursive } from '../services/utils';

const useCustomParams = ({ customFilters }) => {
  const customParamsRef = useRef(customFilters);
  const [customParams, setCustomParams] = useState(customFilters);

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
  };

  const handleFilterRequest = () => {
    filtersSanitize(customParams);
  };

  return {
    customParams,
    setCustomParams,
    customParamsRef,
    handleFilterRequest
  };
};

export default useCustomParams;
