import ApiService from '../services/apiService';

const readAllNotificationsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/notifications/read_all', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export default readAllNotificationsRequest;
