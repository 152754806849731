import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import IcoMoon from '../../Utils/Icon';
import CommentForm from './CommentForm';

const Question = ({
  comment,
  isCLient,
  answersLenght,
  fetchComments,
  solicitudeId
}) => {
  const [showForm, setshowForm] = useState(false);
  const [respondText, setrespondText] = useState('Responder');

  const toggleShowForm = () => {
    setshowForm(!showForm);
    if (respondText === 'Responder') setrespondText('Ocultar');
    if (respondText === 'Ocultar') {
      localStorage.removeItem('commentId');
      setrespondText('Responder');
    }
  };

  return (
    <>
      <div
        key={comment.id}
        className="d-flex justify-content-start align-items-start my-1 w-100"
      >
        <div className="d-flex question justify-content-start align-items-start max-width">
          {comment.user.avatarProfile?.fileUrlMinified ? (
            <div className="avatar_profile_img m-2 d-flex justify-content-center align-items-center">
              <img
                alt="avatar"
                src={comment.user.avatarProfile.fileUrlMinified}
              />
            </div>
          ) : (
            <div className="driver-car-box-question-and-answers p-1 d-flex justify-content-center align-items-center m-2">
              <IcoMoon size="20" icon="car" />
            </div>
          )}

          <div className="d-flex flex-column ">
            <p className=" text-left small-font-driver">
              {comment.userName} - {comment.commentDate}
            </p>
            <p className=" text-left font-weight-bold line-space">
              {comment.active
                ? comment.text
                : 'Esta pregunta ha sido desactivada.'}
            </p>
          </div>
        </div>
        {isCLient && answersLenght === 0 && (
          <Button
            className="mt-3"
            variant="outline-success ml-3"
            onClick={toggleShowForm}
          >
            {respondText}
          </Button>
        )}
      </div>
      {showForm && (
        <Fade duration={400}>
          <CommentForm
            commentId={comment.id}
            buttonText="Responder a Briiinger"
            fetchComments={fetchComments}
            solicitudeId={solicitudeId}
          />
        </Fade>
      )}
    </>
  );
};

export default Question;
