import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonComponent } from '../Utils/Button';

const BasicActionBtns = ({
  item,
  path,
  clickHandler,
  showClick,
  editClick,
  destroyText,
  withShow,
  withEdit,
  withDestroy
}) => (
  <>
    {showClick ? (
      <ButtonComponent
        typeButton="Icon"
        icon="eye"
        tooltipText="Ver"
        variant="outline-info"
        onClick={() => clickHandler(item, 'show')}
      />
    ) : (
      withShow && (
        <ButtonComponent
          as={Link}
          typeButton="Icon"
          icon="eye"
          to={`${path}/${item.id}`}
          tooltipText="Ver"
          variant="outline-info"
        />
      )
    )}
    {editClick ? (
      <ButtonComponent
        typeButton="Icon"
        icon="pencil"
        tooltipText="Editar"
        variant="outline-warning"
        onClick={() => clickHandler(item, 'edit')}
      />
    ) : (
      withEdit && (
        <ButtonComponent
          as={Link}
          typeButton="Icon"
          icon="pencil"
          to={`${path}/${item.id}/edit`}
          tooltipText="Editar"
          variant="outline-warning"
        />
      )
    )}
    {withDestroy && (
      <ButtonComponent
        onClick={() => clickHandler(item, 'destroy')}
        typeButton="Icon"
        icon="trash"
        tooltipText={destroyText}
        variant="outline-danger"
      />
    )}
  </>
);

BasicActionBtns.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  path: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  destroyText: PropTypes.string,
  editClick: PropTypes.bool,
  showClick: PropTypes.bool,
  withShow: PropTypes.bool,
  withEdit: PropTypes.bool,
  withDestroy: PropTypes.bool
};

BasicActionBtns.defaultProps = {
  destroyText: 'Eliminar',
  path: '',
  editClick: false,
  showClick: false,
  withShow: true,
  withEdit: true,
  withDestroy: true
};

export default BasicActionBtns;
