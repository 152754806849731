/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { validateToken } from '../../../actions/auth';
import PrincipalNavbar from './PrincipalNavbar';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';
import { togglePath } from '../../../services/utils';
import {
  getNotifications,
  getUnreadNotifications
} from '../../../actions/utils';
import { NotificationButton, NotificationList } from '../../Notification';
import IcoMoon from '../Icon/IcoMoon';
import { DefaultModal } from '../Modal';
import NewTripForm from '../../../screens/Driver/Trip/NewTripForm';
import SuccessAlert from '../Modal/SuccessAlert';

const NavbarLogged = props => {
  const [openPopUpNotification, setOpenPopUpNotification] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const history = useHistory();
  const { children, user } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const ability = useAbility(AbilityContext);
  const client = ability.can('manage', 'client');
  const driver = ability.can('manage', 'driver');
  const basic = ability.can('read', 'basic');
  const currentPathName = location.pathname.split('/')[1];
  const loginRole = localStorage.getItem('loginRole');
  const isClient = loginRole === 'client';
  const isDriver = loginRole === 'driver';
  const { showTripModal } = props;

  const updateAbilityRoles = () => updateAbility(ability);

  const openCloseNotification = () => {
    setOpenPopUpNotification(!openPopUpNotification);
  };

  useEffect(() => {
    if (user.id === 0) {
      dispatch(validateToken());
    }
  }, []);

  useEffect(updateAbilityRoles, [user]);

  const profileImage = user?.avatar_profile?.file_url_minified;

  const getItemProfile = () => {
    if (profileImage) {
      return (
        <div className="avatar_profile_img d-flex justify-content-center align-items-center">
          <img alt="avatar" src={profileImage} />
        </div>
      );
    }
    return <IcoMoon icon="person" size={30} />;
  };

  useEffect(() => {
    dispatch(getNotifications());
    dispatch(getUnreadNotifications());
  }, []);

  const notificationButton = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="d-lg-none">
          {isClient ? (
            <Link to="/client/solicitudes/new">
              <IcoMoon icon="add" size={38} classNameContainer="mr-2" />
            </Link>
          ) : (
            <IcoMoon
              icon="add"
              size={38}
              classNameContainer="mr-2"
              onClick={() => setShowModal(true)}
            />
          )}
        </div>
        <NotificationButton
          openCloseNotification={openCloseNotification}
          openPopUpNotification={openPopUpNotification}
          badgeColor={isClient ? 'badge-primary' : 'badge-secondary'}
        />

        <>
          <div
            className={`popup-menu ${
              openPopUpNotification ? 'right-animation show' : 'hide'
            }`}
          >
            <NotificationList
              openPopUpNotification={openPopUpNotification}
              handleClose={openCloseNotification}
              spinnerColor={isClient ? 'spinner-secondary' : 'spinner-primary'}
            />
          </div>
          {openPopUpNotification && (
            <div
              onClick={openCloseNotification}
              className={`opacity-background opacity-animation `}
            />
          )}
        </>
      </div>
    );
  };

  const renderButton = () => {
    if (isClient) {
      return (
        <Button
          type="submit"
          variant="light"
          className="mr-3"
          as={Link}
          to="/client/solicitudes/new"
        >
          Crear Solicitud
        </Button>
      );
    }
    if (isDriver) {
      return (
        <Button
          onClick={() => setShowModal(true)}
          variant="light"
          className="mr-4 px-4"
        >
          Crear viaje
        </Button>
      );
    }
  };

  useEffect(() => {
    if (showTripModal)
      setTimeout(() => {
        setShowModal(true);
      }, 200);
  }, [showTripModal]);

  return (
    <>
      <PrincipalNavbar notificationButton={notificationButton()}>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto align-items-center">
            {renderButton()}
            {notificationButton()}
            <NavDropdown
              alignRight
              title={getItemProfile()}
              id="basic-nav-dropdown"
            >
              {!basic && (
                <>
                  <NavDropdown.Item
                    as={Link}
                    to={
                      client && currentPathName === 'client'
                        ? '/client/home'
                        : '/driver/home'
                    }
                    className={`${isClient ? 'client' : 'driver'}`}
                  >
                    Inicio
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to={
                      client && currentPathName === 'client'
                        ? '/client/profile'
                        : '/driver/profile'
                    }
                    className={`${isClient ? 'client' : 'driver'}`}
                  >
                    Perfil
                  </NavDropdown.Item>
                  {driver && client && (
                    <NavDropdown.Item
                      as={Link}
                      to={togglePath(currentPathName)}
                      className={`${isClient ? 'client' : 'driver'}`}
                    >
                      Ir a
                      {currentPathName === 'driver' ? ' Cliente' : ' Briiinger'}
                    </NavDropdown.Item>
                  )}
                </>
              )}
              {children}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </PrincipalNavbar>
      <DefaultModal
        title="Crear mi viaje"
        body={
          <NewTripForm
            setShowModal={setShowModal}
            setShowAlert={setShowAlert}
          />
        }
        show={ShowModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => setShowModal(false)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size="xl"
        noButtons
        className=""
      />
      <SuccessAlert
        message="Viaje creado"
        titlebtn="Entendido"
        onClickHide={() => {
          setShowAlert(false);
          setTimeout(() => {
            if (location.pathname === '/driver/trips') {
              window.location.reload();
            } else {
              history.push('/driver/trips');
            }
          }, 300);
        }}
        show={showAlert}
      />
    </>
  );
};

const mapStateToProps = state => {
  const { user } = state.auth;
  const { showTripModal } = state.utils;
  return { user, showTripModal };
};

export default connect(mapStateToProps)(NavbarLogged);
