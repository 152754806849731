import React from 'react';
import GoogleRouteMap from '../../Utils/GoogleMap/GoogleRouteMap';

const Map = ({ setDistanceAndDuration, startPoint, endPoint }) => {
  return (
    <div className="route-map">
      <GoogleRouteMap
        startPoint={startPoint}
        endPoint={endPoint}
        showRouteControls={false}
        showDsitanceAndDurationTrip={false}
        zoomControl
        streetViewControl
        mapTypeControl
        fullscreenControl
        setDistanceAndDuration={setDistanceAndDuration}
      />
    </div>
  );
};

export default Map;
