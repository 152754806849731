import React from 'react';

const BriiingsCountCell = ({ quotationsCount }) => {
  return (
    <p
      style={{ fontSize: '17px' }}
      data-tag="allowRowEvents"
      className="font-weight-bold"
    >
      Briiings: {quotationsCount}
    </p>
  );
};

export default BriiingsCountCell;
