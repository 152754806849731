import React from 'react';
import { Col } from 'react-bootstrap';
import { IcoMoon } from '../../../components';

const WarningCard = ({ setCloseWarningCard }) => {
  return (
    <Col md={8} lg={9} xl={7}>
      <div className="card-warning mt-3">
        <div className="title-card">
          <h6>Precaución!</h6>
          <IcoMoon
            icon="close"
            className="close-warning-card-icon"
            onClick={() => setCloseWarningCard(false)}
          />
        </div>
        <div className="body-card">
          <ul>
            <li>
              Tu pago se hará efectivo cuando el Briiinger de por finalizado el
              envío.
            </li>
            <li>
              Luego de elegir una oferta tendrás acceso a la información del
              Briiinger.
            </li>
            <li>
              Para cancelar un envío sin costo debe ser al menos con 72 hrs
              antes.
            </li>
          </ul>
        </div>
      </div>
    </Col>
  );
};

export default WarningCard;
