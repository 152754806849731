import React from 'react';
import { useLocation } from 'react-router-dom';
import IcoMoon from '../Utils/Icon';

const DriverRoute = ({
  startLocation,
  endLocation,
  status,
  codeNumber,
  onlyDirections,
  tripStatus,
  tripId
}) => {
  const location = useLocation();
  const isTrip = location.pathname.includes('trips');

  return (
    <div
      data-tag="allowRowEvents"
      className="d-flex flex-column mx-1 driver-route-cell w-100"
      style={{ minWidth: '270px' }}
    >
      {!onlyDirections && (
        <div
          data-tag="allowRowEvents"
          className="d-flex justify-content-between w-100 align-items-center mt-2 order-sm-2"
        >
          <p data-tag="allowRowEvents">
            <span className="font-weight-bold">Estado:</span>
            {status ? (
              <span className="text-primary font-weight-bold ml-1">
                {status}
              </span>
            ) : (
              <span className="text-primary font-weight-bold ml-1">
                {tripStatus}
              </span>
            )}
          </p>
          <p data-tag="allowRowEvents"> Código: {codeNumber}</p>
        </div>
      )}
      <div
        data-tag="allowRowEvents"
        className="d-flex justify-content-between w-100 align-items-center order-sm-1 my-2"
      >
        <div
          data-tag="allowRowEvents"
          className="d-flex justify-content-start align-items-center driver-route-box mr-4 "
        >
          {!onlyDirections && (
            <IcoMoon data-tag="allowRowEvents" size="25" icon="repeat1" />
          )}
          <p
            data-tag="allowRowEvents"
            className="font-weight-bold text-ellipsis"
          >
            {startLocation} -
          </p>
          <p
            data-tag="allowRowEvents"
            className="font-weight-bold ml-1 text-ellipsis"
          >
            {endLocation}
          </p>
        </div>
        {!onlyDirections && !isTrip && tripId && (
          <IcoMoon
            data-tag="allowRowEvents"
            className="text-dark "
            tooltipText="Viaje asociado"
            tooltipPlacement="top"
            size="18"
            icon="car"
            classNameContainer="d-flex justify-content-center align-items-center px-1 mx-1 text-dark driver-car-box"
          />
        )}
      </div>
    </div>
  );
};

export default DriverRoute;
