import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { CenteredModal } from '../Utils/Modal';
import IcoMoon from '../Utils/Icon/IcoMoon';
import HomeContact from '../../screens/Home/HomeContact';

const DashboardInfoBox = ({
  title,
  icon,
  total,
  driver,
  userInfo,
  showModal,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const onClickHide = () => setShow(false);

  return (
    <>
      <Col
        lg={12}
        xl={4}
        className="right-animation my-2 pr-0 pl-0 pl-xl-3"
        {...props}
        onClick={showModal ? () => setShow(true) : () => null}
      >
        <div
          className={`info-card ${driver ? 'driver' : ''} ${
            showModal ? 'hover-box' : ''
          } d-flex justify-content-start`}
        >
          <div className="d-flex">
            <div
              className={`icon-background-circle icon-dashboard ${
                driver ? 'driver' : ''
              } d-flex justify-content-center align-items-center`}
            >
              <IcoMoon size="25" icon={icon} />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <Card.Title className="mt-3 font-18 mb-0">{title}</Card.Title>
              <Card.Title className="font-weight-bold font-30">
                {total}
              </Card.Title>
            </div>
          </div>
        </div>
      </Col>
      <CenteredModal
        size="md"
        onHide={onClickHide}
        onClickHide={onClickHide}
        show={show}
        body={
          <div className="p-3">
            <HomeContact
              isForPaymentRequest
              reusable
              withFields={false}
              title="Solicitud de Retiro"
              titleDrop="(montos briiings finalizados)"
              subTitle="El depósito será válido en máximo 3 días hábiles."
              buttonText="Solicitar Retiro"
              email={userInfo?.email}
              hideModal={onClickHide}
            />
          </div>
        }
      />
    </>
  );
};

export default DashboardInfoBox;
