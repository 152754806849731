import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ButtonComponent } from '../Utils/Button';

const ExpiredSolicitudeHeader = ({ editOfferUrl, setModalShow }) => {
  return (
    <>
      <div className="expired-solicitude-header__container mb-5">
        <ButtonComponent
          typeButton="icon"
          variant="danger"
          icon="warning"
          iconSize={30}
          iconColor="#fff"
        />
        <strong style={{ marginLeft: 10 }}>
          Esta solicitud de envío ha expirado, puedes eliminarla o hacer una
          nueva solicitud
        </strong>

        <div className="expired-solicitude-actions__buttons">
          <Button variant="light" as={Link} to={editOfferUrl}>
            Editar solicitud
          </Button>
          <Button
            variant="danger"
            onClick={() => setModalShow(true)}
            className="px-4 ml-2"
          >
            Eliminar solicitud
          </Button>
        </div>
      </div>
    </>
  );
};

export default ExpiredSolicitudeHeader;
