import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SpinnerLoader } from '../../components';
import { getPage } from '../../actions/utils';

const Page = props => {
  const dispatch = useDispatch();
  const {
    match,
    utils: {
      page: { body },
      ongoingRequest
    }
  } = props;

  useEffect(() => {
    dispatch(getPage(match.params.page));
  }, [match?.url]);

  const loading = () => <SpinnerLoader animation="border" variant="primary" />;

  const content = () => {
    if (body && !ongoingRequest.getPage) {
      return <div dangerouslySetInnerHTML={{ __html: body }} />;
    }
    return <h4 className="text-center">404</h4>;
  };

  return (
    <Row className="static-page mx-1">
      {ongoingRequest.getPage ? (
        loading()
      ) : (
        <Col md={10} lg={6}>
          {content()}
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = state => {
  const { utils } = state;
  return {
    utils
  };
};

export default withRouter(connect(mapStateToProps)(Page));
