import React from 'react';
import ClientCell from '../../../components/TableCells/ClientCell';
import AmmountCell from '../../../components/TableCells/AmmountCell';
import ClientItemCell from '../../../components/TableCells/ClientItemCell';
import DateCell from '../../../components/TableCells/DateCell';
import ShippingCellChanger from '../../../components/TableCells/ShippingCellChanger';

const columns = [
  {
    name: row => (row?.hasTrip ? 'Nombre Cliente' : 'Cotizaciones'),
    grow: 1.3,
    sortable: false,
    selector: row => (row?.hastrip ? row.clientName : row?.quotationCount),
    cell: ({ solicitude: { selectedQuotation } }) => (
      <ClientCell
        fristName={selectedQuotation?.driver?.name}
        lastName={selectedQuotation?.driver?.lastName}
        profileImage={selectedQuotation?.driverAvatar?.fileUrlMinified}
      />
    )
  },
  {
    name: 'Nombre Item',
    grow: 2,
    sortable: false,
    selector: row => row.itemName,
    cell: ({ solicitude: { id, itemsAttributes } }) => (
      <ClientItemCell
        id={id}
        itemName={itemsAttributes[0]?.name}
        codeNumber={id.split('-')[0]}
        imageUrl={itemsAttributes[0].itemImages[0]?.fileUrl}
      />
    )
  },
  {
    name: 'Fecha Viaje',
    grow: 2,
    sortable: false,
    selector: row => row.startDate,
    cell: ({
      solicitude: {
        id,
        deliveryAddressAttributes,
        pickupAddressAttributes,
        hasQuotation,
        startDate,
        endDate,
        pickupOption,
        deliveryOption
      }
    }) => (
      <DateCell
        id={id}
        startLocation={deliveryAddressAttributes.communeName}
        endLocation={pickupAddressAttributes.communeName}
        startDate={startDate}
        endDate={endDate}
        hasQuotation={hasQuotation}
        pickupOption={pickupOption}
        deliveryOption={deliveryOption}
      />
    )
  },
  {
    name: 'Calificacion',
    grow: 1.2,
    sortable: false,
    cell: ({ solicitude }) => <ShippingCellChanger solicitude={solicitude} />
  },
  {
    name: 'Precio',
    grow: 1,
    sortable: false,
    selector: row => row.ammount,
    cell: ({
      solicitude: {
        id,
        selectedQuotation: { paid, cost }
      }
    }) => <AmmountCell id={id} ammount={cost} paidByClient={paid} />
  }
  // {
  //   name: 'IsToday',
  //   grow: 1,
  //   sortable: false,
  //   cell: ({ solicitude: { id, pickupAt } }) => (
  //     <IsTodayCell id={id} dateToCheck={pickupAt} />
  //   )
  // }
];

const conditionalClientRows = [
  {
    when: row => !!row.hasQuotation,
    classNames: ['bg-orange']
  },
  {
    when: row => !row.hasQuotation,
    classNames: ['bg-disabled']
  }
];

export { columns, conditionalClientRows };
