import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { setSidebar } from '../../../actions/utils';
import { requestSignOut } from '../../../actions/auth';
import { ButtonComponent } from '../Button';
import { togglePath } from '../../../services/utils';
import IcoMoon from '../Icon/IcoMoon';
import SidebarClient from './SidebarClient';
import SidebarDriver from './SidebarDriver';

const Sidebar = ({ module, sidebarOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector(state => state.auth);
  const currentPathName = location.pathname.split('/')[1];

  const pathName = () => {
    const { pathname } = location;
    const paths = pathname.split('/');
    return paths[2];
  };

  const closeSidebar = () => dispatch(setSidebar({ show: false }));

  const renderSidebar = () => {
    switch (module) {
      case 'client':
        return (
          <SidebarClient
            location={location}
            pathName={pathName()}
            closeSidebar={closeSidebar}
          />
        );
      case 'driver':
        return (
          <SidebarDriver
            location={location}
            pathName={pathName()}
            closeSidebar={closeSidebar}
          />
        );
      default:
        return (
          <SidebarClient
            location={location}
            pathName={pathName()}
            closeSidebar={closeSidebar}
          />
        );
    }
  };

  return (
    <>
      <div className={`sidebar ${!sidebarOpen ? 'hidden-sidebar' : ''}`}>
        {renderSidebar()}
        <Nav
          className="flex-column d-lg-none sidebar-mobile"
          activeKey={pathName()}
        >
          <Nav.Item>
            <Nav.Link
              eventKey="profile"
              as={Link}
              to={module === 'driver' ? '/driver/profile' : '/client/profile'}
              className={`mt-1 ${module === 'driver' ? 'driver' : 'client'}`}
              onClick={() => closeSidebar()}
            >
              <IcoMoon icon="person" />
              <span className="d-inline-block m-2 mt-2">Mi Perfil</span>
            </Nav.Link>
          </Nav.Item>
          {user?.current_roles?.includes('client') &&
            user?.current_roles?.includes('driver') && (
              <Nav.Item>
                <Nav.Link
                  as={Link}
                  to={togglePath(currentPathName)}
                  className={`${module === 'driver' ? 'driver' : 'client'}`}
                  onClick={() => closeSidebar()}
                >
                  <span className="d-inline-block m-2 mt-3">
                    Ir a{' '}
                    {currentPathName === 'client' ? 'Briiinger' : 'Cliente'}
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}
        </Nav>
        <ButtonComponent
          typeButton="Button"
          className={`btn-logout ml-3 d-lg-none ${
            module === 'client' ? 'client' : 'driver'
          }`}
          onClick={() => dispatch(requestSignOut())}
        >
          Cerrar sesión
        </ButtonComponent>
      </div>
      <div
        className={`${sidebarOpen ? 'overlay' : 'overlay-hidden'}`}
        onClick={closeSidebar}
        aria-hidden="true"
      />
    </>
  );
};

export default Sidebar;
