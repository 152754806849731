import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import snakeCaseKeys from 'snakecase-keys';

import QuotationCard from './QuotationCard';
import { CenteredModal } from '../Utils/Modal';
import { createPaymentRequest } from '../../requests/payments';

const QuotationSelectOffer = ({
  driver,
  quotation,
  fromSolicitude = false,
  modelUrl,
  solicitude,
  expired
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [urlAction, setUrlAction] = useState('');
  const [wsToken, setWsToken] = useState('');
  const { id, parsedDriverCommissionAmount, solicitudeId } =
    Boolean(quotation) && quotation;
  const dispatch = useDispatch();
  const history = useHistory();
  const { amIBestQuotation } = Boolean(solicitude) && solicitude;

  const handleCloseModal = () => setModalOpen(false);

  const handleToggleWebPay = async response => {
    const { url, token } = response.data;
    setUrlAction(url);
    setWsToken(token);
  };

  const handleSelectQuotation = () => {
    const sendParams = snakeCaseKeys({ quotationId: id });

    createPaymentRequest({
      dispatch,
      params: sendParams,
      successCallback: handleToggleWebPay
    });
  };

  const handleReOffer = () => {
    const newUri = `${modelUrl}/${solicitudeId}/new`;
    history.push({ pathname: newUri, state: 'fromQuotation' });
  };

  // eslint-disable-next-line no-nested-ternary
  const buttonText = fromSolicitude
    ? 'Ir a pagar'
    : amIBestQuotation
    ? 'Eres la mejor oferta - Re ofertar'
    : 'Re ofertar';
  const buttonOnClick = fromSolicitude ? null : handleReOffer;

  useEffect(() => {
    if (fromSolicitude && modalOpen) handleSelectQuotation();
  }, [modalOpen, fromSolicitude]);

  return (
    <>
      <div className="driver-comment w-100">
        {fromSolicitude ? (
          <p
            className={`text-center ${
              quotation?.comments?.length === 0 ? 'text-info' : ''
            }`}
          >
            {quotation?.comments?.substring(0, 70) ||
              'Sin comentarios del Briiinger'}
          </p>
        ) : (
          <>
            <h5 style={{ fontSize: '1.25rem' }}>
              Oferta {parsedDriverCommissionAmount}
            </h5>
          </>
        )}
      </div>

      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => setModalOpen(!modalOpen)}
        onClick={() => setModalOpen(!modalOpen)}
        className="select-offer right-animation"
      >
        <Button variant="success">Ver oferta</Button>
      </div>

      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={modalOpen}
        body={
          <QuotationCard
            classNameContainer="px-3 mt-n1"
            driver={driver}
            handleCloseModal={handleCloseModal}
            quotation={quotation}
            buttonOnClick={buttonOnClick}
            buttonText={buttonText}
            actionUrl={urlAction}
            token={wsToken}
            fromSolicitude={fromSolicitude}
            expired={expired}
          />
        }
      />
    </>
  );
};

export default QuotationSelectOffer;
