import React from 'react';
import { Card } from 'react-bootstrap';
import IcoMoon from '../Utils/Icon';

const RouteCell = ({
  startLocation,
  endLocation,
  startDate,
  endDate,
  hasQuotation,
  module = 'client',
  hasSelectedQuotation,
  colorIcon,
  colorLine,
  pickupOption,
  deliveryOption,
  hasMyQuotation = false,
  expired
}) => {
  const defineBackground = () => {
    if (module === 'driver') {
      if (!hasMyQuotation) return 'bg-green';
      return 'bg-disabled';
    }
    if (expired) return 'bg-expired';
    if (hasSelectedQuotation) return 'bg-green';
    if (hasQuotation) return 'bg-orange';
    return 'bg-disabled';
  };

  return (
    <div
      data-tag="allowRowEvents"
      className="d-flex justify-content-start"
      style={{ minWidth: '200px' }}
    >
      <div
        data-tag="allowRowEvents"
        className="d-flex flex-column justify-content-center align-items-center mr-1"
      >
        <div
          data-tag="allowRowEvents"
          className={`dotted-line icon-primary ${colorLine ||
            defineBackground()}`}
        />
        <IcoMoon
          zIndex={1}
          data-tag="allowRowEvents"
          className={`icon-primary ${colorIcon || defineBackground()} mb-2`}
          size="16"
          icon="radio-checked"
        />
        <IcoMoon
          zIndex={1}
          data-tag="allowRowEvents"
          className={`icon-primary ${colorIcon || defineBackground()}`}
          size="20"
          icon="location1"
        />
      </div>
      <div
        data-tag="allowRowEvents"
        className="d-flex flex-column justify-content-center"
      >
        <div
          data-tag="allowRowEvents"
          className="d-flex align-items-start flex-wrap mt-1 mb-1"
        >
          <Card.Text
            data-tag="allowRowEvents"
            className="font-weight-bold mr-2 min-w"
          >
            {startLocation}:
          </Card.Text>
          <Card.Text data-tag="allowRowEvents">
            {pickupOption === 'soon_as_possible'
              ? 'Lo antes posible'
              : startDate}
          </Card.Text>
        </div>
        <div
          data-tag="allowRowEvents"
          className="d-flex align-items-start flex-wrap mt-2"
        >
          <Card.Text
            data-tag="allowRowEvents"
            className="font-weight-bold mr-2 min-w"
          >
            {endLocation}:
          </Card.Text>
          <Card.Text data-tag="allowRowEvents">
            {deliveryOption === 'soon_as_possible'
              ? 'Lo antes posible'
              : endDate}
          </Card.Text>
        </div>
      </div>
    </div>
  );
};
export default RouteCell;
