import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import RatingReadOnly from '../Utils/Rating/RatingReadOnly';
import RatingForm from '../Utils/Rating/RatingForm';
import { CenteredModal } from '../Utils/Modal/index';

const RatingCell = ({ quotationId, solicitudeId, rating }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [ratingInfo, setRatingInfo] = useState(rating);

  const onRequest = () => {
    setIsFetching(!isFetching);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const updateRating = newRating => {
    setRatingInfo(newRating);
  };

  return (
    <div className="rating">
      {ratingInfo ? (
        <div className="opacity-animation">
          <div className="d-flex flex-column justify-content-center align-items-center rating-read-only rating-bg-hover">
            <div className="d-flex flex-column justify-content-center align-items-center hide-on-hover ">
              <RatingReadOnly value={ratingInfo?.numberOfStars} size="large" />
              <p>Ver Comentario</p>
            </div>
            <div className="opacity-animation">
              <div className="d-flex flex-column justify-content-start align-items-start show-on-hover">
                <RatingReadOnly
                  className="font-weight-bold"
                  value={ratingInfo?.numberOfStars}
                  size="small"
                />
                <p className="font-weight-bold m-0">Comentario:</p>
                <p className="text-justify">{ratingInfo?.body}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <CenteredModal
            size="sm"
            onHide={toggleModal}
            onClickHide={toggleModal}
            show={openModal}
            body={
              <RatingForm
                quotationId={quotationId}
                solicitudeId={solicitudeId}
                toggleModal={toggleModal}
                onRequest={onRequest}
                isFetching={isFetching}
                updateRating={updateRating}
              />
            }
          />
          <div
            role="presentation"
            onClick={toggleModal}
            onKeyDown={toggleModal}
            className="rating-read-only d-flex flex-column justify-content-center align-items-center"
          >
            <div className="opacity-animation">
              <div className="d-flex flex-column justify-content-center align-items-center hide-on-hover w-100">
                <p>Calificar Briiing</p>
                <RatingReadOnly
                  toggleModal={toggleModal}
                  value={0}
                  size="large"
                />
              </div>
            </div>
            <div className="opacity-animation w-100">
              <div className="d-flex justify-content-center align-items-center rating-btn w-100">
                <Button
                  onClick={toggleModal}
                  className="w-100"
                  variant="success"
                >
                  Calificar
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RatingCell;
