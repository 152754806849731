import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { statusUpdateSolicitudeRequest } from '../../requests/solicitudes';
import { sendAlert } from '../../actions/utils';
import useConfirmModal from '../../hooks/useConfirmModal';

const StatusCell = ({ setStatus, id, status }) => {
  const dispatch = useDispatch();
  const handleUpdateStatus = () => {
    statusUpdateSolicitudeRequest(id, {
      dispatch,
      successCallback: response => {
        setStatus(response.data.status);
        dispatch(
          sendAlert({
            kind: 'success',
            message: 'Estado actualizado correctamente'
          })
        );
      }
    });
  };
  const shouldBeDisabled = status === 'finished';

  const { ModalComponent, handleShowModal } = useConfirmModal({
    modalTitle: 'Actualizar estado',
    onConfirmCallback: handleUpdateStatus,
    modalBody: (
      <div className="p-5">
        <h6>¿Estás seguro de actualizar el estado actual?</h6>
      </div>
    )
  });

  return (
    <>
      <Button
        variant="info"
        className="mt-3 mt-md-0 btn-lg"
        onClick={handleShowModal}
        disabled={shouldBeDisabled}
      >
        Confirmar entrega
      </Button>
      <ModalComponent />
    </>
  );
};

export default StatusCell;
