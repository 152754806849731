import React from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import DriverRouteCell from '../../TableCells/DriverRouteCell';

const SolicitudeTripMainInfo = ({
  id,
  distance,
  duration,
  startLocation,
  endLocation,
  startDate,
  endDate,
  pickupStartDate,
  pickupEndDate,
  deliveryStartDate,
  deliveryEndDate,
  origin,
  destiny,
  tripStatus
}) => {
  return (
    <>
      <Col sm={12} md={5} className="solicitude-main-info left-animation">
        <DriverRouteCell
          id="1"
          startLocation={startLocation}
          endLocation={endLocation}
          state="Por hacer"
          codeNumber={id.split('-')[0]}
          tripStatus={tripStatus}
        />
        <p className="py-2">
          <strong>Tiempo de viaje: </strong> {duration}
        </p>
        {startDate && (
          <p className="py-2">
            <strong>Comienza: </strong> {moment(startDate).format('DD/MM/YYYY')}
            <strong className="ml-1">Finaliza:</strong>{' '}
            {moment(endDate).format('DD/MM/YYYY')}
          </p>
        )}
        <p className="py-2">
          <strong>Distancia: </strong> {distance}
        </p>
        <p className="py-2">
          <strong>Origen: </strong> {origin}.
        </p>

        <p className="py-2">
          <strong>
            Fecha{pickupStartDate !== pickupEndDate && 's'} de Retiro:{' '}
          </strong>{' '}
          {moment(pickupStartDate).format('DD/MM/YYYY')}
          {pickupStartDate !== pickupEndDate &&
            `- ${moment(pickupEndDate).format('DD/MM/YYYY')}`}
        </p>

        <p className="py-2">
          <strong>Destino: </strong> {destiny}.
        </p>
        <p className="py-2">
          <strong>
            Fecha{deliveryStartDate !== deliveryEndDate && 's'} de Entrega:{' '}
          </strong>{' '}
          {moment(deliveryStartDate).format('DD/MM/YYYY')}
          {deliveryStartDate !== deliveryEndDate &&
            `- ${moment(deliveryEndDate).format('DD/MM/YYYY')}`}
        </p>
      </Col>
    </>
  );
};

export default SolicitudeTripMainInfo;
