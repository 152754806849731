import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';
import { FormikInput } from '../../components';
import { sendAlert } from '../../actions/utils';
import { forgotPasswordRequest } from '../../requests/utils';
import WrapperSession from '../../components/Auth/WrapperSession';
import bgDriver from '../../assets/images/background_driver_blend.png';
import bgClient from '../../assets/images/background_client_blend.png';

const RecoverPassword = props => {
  const { onHide, isValid, errors, touched } = props;
  const loginRole = localStorage.getItem('loginRole');
  const isClient = loginRole === 'client';

  return (
    <WrapperSession>
      <img
        src={isClient ? bgClient : bgDriver}
        alt="bg-client"
        className={`bg-image ${isClient ? 'color-client' : 'color-driver'}`}
      />
      <Form className="login_box recover-password-box">
        <Fade top duration={400}>
          <h5 className="text-center mb-3">Recuperar contraseña</h5>
        </Fade>
        <Fade bottom duration={400}>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                label="Email"
                placeholder="Email"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant={isClient ? 'secondary' : 'primary'}
            size="lg"
            className={`btn login-btn ${
              isClient ? 'client-btn' : 'driver-btn'
            }`}
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Recuperar mi contraseña
          </Button>
          <Link to="/register" className="register">
            <div
              className={`d-flex mt-2 justify-content-center align-items-center register-btn register-btn-${
                isClient ? 'client' : 'driver'
              }`}
            >
              Registrarme
            </div>
          </Link>
          <Link to="/login" className="register">
            <div
              className={`d-flex mt-2 justify-content-center align-items-center register-btn register-btn-${
                isClient ? 'client' : 'driver'
              }`}
            >
              Iniciar Sesión
            </div>
          </Link>
        </Fade>
      </Form>
    </WrapperSession>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch, history } = props;
  forgotPasswordRequest({
    dispatch,
    params: {
      email: values.email
    },
    successCallback: result => {
      history.push('/login');
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RecoverPassword)
  )
);
