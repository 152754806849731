import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { BasicTextArea, FormikDatePicker, FormikInput } from '../Utils/Input';
import VehicleQuotationDatatable from './Vehicle/VehicleQuotationDatatable';
import FormImportantInfo from './FormImportantInfo';
import TripRemoteSelect from '../Trips/TripRemoteSelect';

const QuotationFields = ({
  touched,
  errors,
  modelName,
  solicitudeId,
  deliveryStartDate,
  pickupStartDate,
  solicitude
}) => {
  const [pickupDate, setPickupDate] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [minDate, setMinDate] = useState('');
  const [closeWarningCard, setCloseWarningCard] = useState(true);
  const { pickupOption, deliveryOption } = solicitude;
  // const [briiingCommissionAmount, setBriiingCommissionAmount] = useState(0);
  // const [clientAmount, setClientAmount] = useState(0);
  // const [clientAmountLowestCost, setClientAmountLowestCost] = useState(0);
  // const [
  //   briiingCommissionLowestCost,
  //   setBriiingCommissionLowestCost
  // ] = useState(0);
  const { setFieldValue, values } = useFormikContext();
  // const { settings } = useSelector(state => state.utils);
  // const hasLowestCost = getIn(values, `${modelName}[lowestCost]`);
  // const hasDriverCommission = getIn(
  //   values,
  //   `${modelName}[driverCommissionAmount]`
  // );

  const handleDateChange = (date, field = 'pickup') => {
    if (field === 'pickup') {
      setPickupDate(date || '');
      setFieldValue(`${modelName}[pickupAt]`, date || '');
    } else {
      setDeliveryDate(date || '');
      setFieldValue(`${modelName}[deliveryAt]`, date || '');
    }
  };

  const handleCostChange = (field, e) => {
    const { value } = e.target;
    setFieldValue(field.name, value);

    // const {
    //   briiingCommissionPercentage,
    //   driverCommissionPercentage
    // } = settings;

    // const clientCost = value / (driverCommissionPercentage / 100);
    // const briiingCommission = clientCost * (briiingCommissionPercentage / 100);

    // if (field.name === 'quotation[lowestCost]') {
    //   setBriiingCommissionLowestCost(briiingCommission);
    //   setClientAmountLowestCost(clientCost);
    // } else {
    //   setBriiingCommissionAmount(briiingCommission);
    //   setClientAmount(clientCost);
    // }
  };

  useEffect(() => {
    if (pickupStartDate && deliveryStartDate) {
      const selectedPickupAt = moment(pickupStartDate).toDate();

      if (pickupOption === 'on_date') {
        setPickupDate(selectedPickupAt);
        setFieldValue(`${modelName}[pickupAt]`, selectedPickupAt);
        setMinDate(selectedPickupAt);
      }
      if (deliveryOption === 'on_date') {
        const selectedDeliveryAt = moment(deliveryStartDate).toDate();
        setDeliveryDate(selectedDeliveryAt);
        setFieldValue(`${modelName}[deliveryAt]`, selectedDeliveryAt);
      }
      if (pickupOption === 'between_range') setMinDate(selectedPickupAt);
    }
    if (pickupOption === 'soon_as_possible') setMinDate(new Date());
  }, [pickupOption, deliveryOption, deliveryStartDate, pickupStartDate]);

  const textAreaMessage =
    'Aquí puedes saludar y ejemplo hablar de como es tu proceso de búsqueda y entrega, recuerda no incluir información de contacto o seras suspendido temporalmente';

  return (
    <>
      <Row>
        <Col md={6}>
          <Field name={`${modelName}[driverCommissionAmount]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="number"
                label="Cuanto ofreces"
                placeholder="Valor a cobrar en pesos"
                autoComplete="off"
                onChange={e => handleCostChange(field, e)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          {/* {hasDriverCommission && (
            <CommissionMessage
              briiingCommission={briiingCommissionAmount}
              clientAmount={clientAmount}
            />
          )} */}
        </Col>
        {/* <Col md={6}>
          <Field name={`${modelName}[lowestCost]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                label="Valor más bajo a cobrar"
                placeholder="Valor más bajo a cobrar en pesos"
                autoComplete="off"
                onChange={e => handleCostChange(field, e)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          {hasLowestCost && (
            <CommissionMessage
              briiingCommission={briiingCommissionLowestCost}
              clientAmount={clientAmountLowestCost}
            />
          )}
        </Col> */}
      </Row>
      {/* cars */}
      <section className="mt-4 mb-3">
        <p className="subtitle">Mi auto</p>
        <Field name={`${modelName}[vehicleId]`}>
          {({ field }) => (
            <VehicleQuotationDatatable
              field={field}
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              solicitudeId={solicitudeId}
            />
          )}
        </Field>
      </section>
      <Row>
        <Col md={6}>
          <Field name={`${modelName}[tripId]`}>
            {({ field }) => (
              <TripRemoteSelect
                values={values}
                field={field}
                label="Viaje"
                placeholder="Seleccionar un viaje"
                initialParams={{
                  for_selector: true,
                  effective_date: true,
                  filter_active: true,
                  sort_column: 'start_date'
                }}
              />
            )}
          </Field>
        </Col>
        <Col md={6} className="mb-3">
          <Row>
            <Col xl={6}>
              <Field name={`${modelName}[pickupAt]`}>
                {({ field }) => (
                  <FormikDatePicker
                    abbr
                    showYearDropDown
                    selected={pickupDate}
                    minDate={minDate}
                    dateFormat="dd-MM-yyyy"
                    label="Fecha de retiro"
                    onChange={date => handleDateChange(date)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
            <Col xl={6}>
              <Field name={`${modelName}[deliveryAt]`}>
                {({ field }) => (
                  <FormikDatePicker
                    abbr
                    showYearDropDown
                    selected={deliveryDate}
                    minDate={minDate}
                    dateFormat="dd-MM-yyyy"
                    label="Fecha de entrega"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    onChange={date => handleDateChange(date, 'delivery')}
                  />
                )}
              </Field>
            </Col>
          </Row>
        </Col>
      </Row>

      {/*  comments */}

      <Row>
        <Col>
          <Field name={`${modelName}[comments]`}>
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Comentario adicional"
                placeholder={textAreaMessage}
                row={5}
              />
            )}
          </Field>
        </Col>
      </Row>
      {closeWarningCard && (
        <Row className="mb-3">
          <Col md={11} xl={8}>
            <FormImportantInfo setCloseWarningCard={setCloseWarningCard} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default QuotationFields;
