import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLogoDark from '../../components/Utils/MainLogo/MainLogoDark';
import conductor from '../../assets/images/unsplash_efgpRGeu9tg.png';
import cliente from '../../assets/images/unsplash_LKTrzus_uq0.png';

const SelectSession = () => {
  const jwt = localStorage.getItem('jwt');
  const loginRole = localStorage.getItem('loginRole');
  const { user } = useSelector(state => state.auth);

  const setLoginRole = role => {
    localStorage.setItem('loginRole', role);
  };

  if (jwt) {
    return (
      <Redirect
        to={
          loginRole === 'client' && user.current_roles?.includes('client')
            ? '/client/home'
            : '/driver/home'
        }
      />
    );
  }

  return (
    <div className="landing-page">
      <div className="top-logo shadow-black">
        <div className="top-animation">
          <MainLogoDark />
        </div>
      </div>
      <Link
        to="/login"
        onClick={() => setLoginRole('driver')}
        style={{
          backgroundImage: `url(${cliente})`
        }}
        className="background-image left-content row"
      >
        <div className="left-animation">
          <div className="landing-page-btn">
            <h3>Briiinger</h3>
          </div>
        </div>
      </Link>
      <Link
        to="/login"
        onClick={() => setLoginRole('client')}
        style={{
          backgroundImage: `url(${conductor})`
        }}
        className="background-image right-content row"
      >
        <div className="right-animation">
          <div className="landing-page-btn">
            <h3>Cliente</h3>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SelectSession;
