import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        fontColor: '#000'
      }
    },
    title: {
      display: true,
      text: 'Ingresos durante los últimos 12 meses'
    }
  }
};

const ChartDriver = ({ values }) => {
  const data = {
    labels: values.map(i => i.month),
    datasets: [
      {
        label: 'Ganancia',
        data: values.map(i => i.money),
        backgroundColor: '#ff5e62'
      }
    ]
  };

  return (
    <div className="mt-4">
      <Bar options={options} data={data} />
    </div>
  );
};

export default ChartDriver;
