import AwesomeDebouncePromise from 'awesome-debounce-promise';

import ApiService from '../services/apiService';

export const indexShippingRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', `/shippings${format || ''}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const myShippingsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', `/my_shippings${format || ''}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateShippingRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) => {
  ApiService.request('put', `/shippings/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });
};

export const showShippingRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/shippings/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const debounceIndexShippingsRequest = AwesomeDebouncePromise(
  indexShippingRequest,
  300
);

export const debouncedMyShippingsRequest = AwesomeDebouncePromise(
  myShippingsRequest,
  300
);
