import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';

const DefaultModal = ({
  title,
  body,
  variantBtnClose,
  titleBtnClose,
  variantBtnSave,
  titleBtnSave,
  handleClose,
  handleConfirm,
  show,
  noButtons,
  onlyConfirmBtn,
  ...props
}) => (
  <Modal {...props} show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    {!noButtons && (
      <Modal.Footer>
        {onlyConfirmBtn ? (
          ''
        ) : (
          <Button
            className="btn"
            variant={variantBtnClose}
            onClick={handleClose}
          >
            {titleBtnClose}
          </Button>
        )}
        <Button
          className="btn"
          variant={variantBtnSave}
          onClick={handleConfirm}
        >
          {titleBtnSave}
        </Button>
      </Modal.Footer>
    )}
  </Modal>
);

DefaultModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variantBtnClose: PropTypes.string,
  titleBtnClose: PropTypes.string.isRequired,
  variantBtnSave: PropTypes.string,
  titleBtnSave: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

DefaultModal.defaultProps = {
  variantBtnClose: 'primary',
  variantBtnSave: 'success',
  body: {}
};

const CenteredModal = ({
  header,
  title,
  body,
  titlebtn,
  size,
  onClickHide,
  onClickConfirm,
  variantBtn,
  ...props
}) => (
  <Modal
    {...props}
    size={size}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      {title && (
        <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
      )}
    </Modal.Header>
    <Modal.Body>
      <h4>{title}</h4>
      <div>{body}</div>
    </Modal.Body>
    {titlebtn && (
      <Modal.Footer>
        <Button
          variant={variantBtn}
          onClick={() => {
            if (onClickConfirm) return onClickConfirm();
            return onClickHide();
          }}
        >
          {titlebtn}
        </Button>
      </Modal.Footer>
    )}
  </Modal>
);

CenteredModal.propTypes = {
  header: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string
};

CenteredModal.defaultProps = {
  header: null,
  size: 'lg',
  body: ''
};

const ModalCenter = props => {
  const { header, title, body, titlebtn, size, titlebtnmodal } = props;
  const [modalShow, setModalShow] = useState(false);

  const modalClose = () => setModalShow(false);

  return (
    <ButtonToolbar>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        {titlebtnmodal}
      </Button>

      <CenteredModal
        show={modalShow}
        onHide={modalClose}
        header={header}
        title={title}
        body={body}
        titlebtn={titlebtn}
        size={size}
        titlebtnmodal={titlebtnmodal}
      />
    </ButtonToolbar>
  );
};

export { DefaultModal, ModalCenter, CenteredModal };
