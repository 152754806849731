import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { emptyFalseParamsRecursive } from '../../../services/utils';
import HeaderFilter from '../../../components/Utils/Filter/HeaderFilter';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader';
import ComponentDataTable from '../../../components/Utils/DataTable/index';
import { DefaultHeader } from '../../../components';
import { useFetchData } from '../../../hooks';
import { columns, conditionalClientRows } from './data';
import { clientDashboardRequest } from '../../../requests/dashboards';
import { debounceMySolicitudesRequest } from '../../../requests/solicitudes';
import {
  clientSolicitudeFilterParams,
  orderOptions,
  tabOptions
} from '../../../components/Solicitude/SolicitudeFilterParams';
import CustomHeaderFilter from '../../../components/Utils/Filter/CustomHeaderFilter';

const ClientDashboard = ({ user }) => {
  const [dashboardData, setDashboardData] = useState({});
  const customParamsRef = useRef({ ...clientSolicitudeFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const modelUrl = '/client/solicitudes';
  const userInfo = camelCaseRecursive(user);

  localStorage.removeItem('isFirstLogin');

  const handleRowClicked = row => {
    const newUri = `${modelUrl}/${row.id}`;
    history.push({ pathname: newUri, state: 'fromDashboard' });
  };

  const {
    data: solicitudes,
    moreData,
    setMoreData,
    handleIndexRequest,
    isFetching,
    totalData
  } = useFetchData({
    debouncedIndexRequest: debounceMySolicitudesRequest,
    withDataTable: true,
    fetchingErrorMessage: 'Oops, ocurrió un problema al buscar tus solicitudes',
    customParams: {
      ...customParams
    }
  });

  const filtersSanitize = (params = {}) => {
    let newParams = { ...emptyFalseParamsRecursive(params) };
    newParams = snakeCaseKeys(newParams);
    // eslint-disable-next-line no-param-reassign
    customParamsRef.current = { ...newParams };
    setMoreData(m => !m);
  };

  const handleFilterRequest = () => filtersSanitize(customParams);

  const handleFetchClientDashboard = () => {
    clientDashboardRequest({
      dispatch,
      successCallback: response => {
        setDashboardData(camelCaseRecursive(response.data));
      }
    });
  };

  useEffect(handleFetchClientDashboard, []);
  useEffect(handleFilterRequest, [customParams]);

  return (
    <>
      <div className="border-rounded data-table dt-client p-0 mb-5">
        <DashboardHeader
          dashboardData={dashboardData}
          name={userInfo.clientAttributes?.name}
          countDays={userInfo.countDays}
        />
      </div>
      <DefaultHeader
        title="Mis Solicitudes de envíos"
        body={
          <div className="d-flex align-items-center header-responsive">
            <CustomHeaderFilter
              customParams={customParams}
              setCustomParams={setCustomParams}
              orderOptions={orderOptions}
              startDateField="dateFrom"
              endDateField="dateTo"
              pickupAddress="communeFrom"
              deliveryAddress="communeTo"
              queryPlaceholder="Buscar por nombre"
            />
          </div>
        }
      />
      <div className="data-table dt-client p-0 dt-client-shippments index-view">
        <ComponentDataTable
          onRequest={isFetching}
          columns={columns}
          data={solicitudes}
          moreData={moreData}
          totalRows={totalData}
          onRowClicked={handleRowClicked}
          conditionalRowStyles={conditionalClientRows}
          resourceRequest={handleIndexRequest}
          customDatatableHeader={
            <HeaderFilter
              customParams={customParams}
              setCustomParams={setCustomParams}
              paramName="filterQuotation"
              tabOptions={tabOptions}
            />
          }
        />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { user } = state.auth;
  return {
    user
  };
};

export default connect(mapStateToProps)(ClientDashboard);
