/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Fade from 'react-reveal/Fade';
import {
  FormikInput,
  FormikCheckBox,
  FormikNumberFormat
} from '../../components';
import { signUpRequest } from '../../actions/auth';
import WrapperSession from '../../components/Auth/WrapperSession';
import client from '../../assets/images/svg/client.svg';
import bgDriver from '../../assets/images/background_driver_blend.png';
import bgClient from '../../assets/images/background_client_blend.png';

const Register = props => {
  const { onHide, isValid, errors, touched, initialValues } = props;
  const loginRole = localStorage.getItem('loginRole');
  const isClient = loginRole === 'client';
  const { settings } = props;

  if (!loginRole) {
    return <Redirect to="/" />;
  }

  initialValues.registerRole = loginRole;

  return (
    <WrapperSession>
      <img
        src={isClient ? bgClient : bgDriver}
        alt="bg-client"
        className={`bg-image ${isClient ? 'color-client' : 'color-driver'}`}
      />
      <Form className="login_box">
        <Fade top duration={400}>
          <div className="logo shadow-orange">
            <Image src={client} alt="Logo" style={{ width: '50px' }} />
          </div>
        </Fade>
        <Fade bottom duration={400}>
          <h4 className="text-center mb-2">Regístrate</h4>

          <Field name="firstname">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr="true"
                label="Nombre"
                placeholder="Ingresa tu nombre..."
                inputType="text"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="lastname">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr="true"
                label="Apellido"
                placeholder="Ingresa tu apellido..."
                inputType="text"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr="true"
                label="Correo"
                placeholder="Nombre@correo.com"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="phone">
            {({ field }) => (
              <FormikNumberFormat
                {...field}
                abbr="true"
                label="Teléfono"
                phoneFormat
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr="true"
                inputType="password"
                label="Contraseña"
                placeholder="Ingresa tu contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="passwordConfirmation">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr="true"
                inputType="password"
                label="Confirmar Contraseña"
                placeholder="Confirmar Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <div className="mt-3 mb-n3 d-flex flex-column align-items-center">
            <Field name="terms">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  abbr="true"
                  field={field}
                  label="Acepto"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                  link={`/${settings.linkTerms}`}
                  linkText="términos y condiciones"
                />
              )}
            </Field>
          </div>
          <Button
            variant={isClient ? 'secondary' : 'primary'}
            size="lg"
            className={`btn mb-2 login-btn ${
              isClient ? 'client' : 'driver'
            }-btn`}
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Crear Cuenta
          </Button>
          <Link to="/login" className="register">
            <div
              className={`d-flex justify-content-center align-items-center register-btn register-btn-${
                isClient ? 'client' : 'driver'
              }`}
            >
              Ya tengo cuenta
            </div>
          </Link>
          <div
            className={`my-4 d-flex justify-content-center reset-password-btn ${
              isClient ? 'client' : 'driver'
            }`}
          >
            <Link to="/recover_password" className="text-black">
              ¿Olvidaste tu cuenta?
            </Link>
          </div>
        </Fade>
      </Form>
    </WrapperSession>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  const { settings } = state.utils;
  return {
    ongoingRequest,
    signedIn,
    settings
  };
};

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  firstname: '',
  lastname: '',
  phone: '',
  registerRole: '',
  terms: false
};

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('Por favor ingresa tu nombre'),
  lastname: Yup.string().required('Por favor ingresa tu apellido'),
  phone: Yup.string()
    .required('Por favor ingresa tu teléfono')
    .test('len', 'Teléfono incorrecto', val => val?.trim().length === 14),
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test(
      'password-confirmation-test',
      'Las contraseñas no coinciden',
      function equalTo(value) {
        if (value && value === this.parent.password) {
          return true;
        }
        return false;
      }
    ),
  terms: Yup.boolean()
    .required('Los términos y condiciones deben ser aceptados.')
    .oneOf([true], 'Los términos y condiciones deben ser aceptados.')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    signUpRequest({
      user: {
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        firstname: values.firstname,
        lastname: values.lastname,
        phone: values.phone,
        register_role: values.registerRole
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Register)
  )
);
