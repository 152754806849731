import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Card, Spinner } from 'react-bootstrap';

import { showSolicitudeRequest } from '../../../requests/solicitudes';
import basicSolicitude from '../../Solicitude/basicSolicitude';
import DrivertItemDescription from '../../Driver/DriverShow/DrivertItemDescription';
import DateCell from '../../TableCells/DateCell';

const SolicitudePreview = ({ solicitudeId }) => {
  const [solicitude, setSolicitude] = useState(basicSolicitude);
  const [onFetching, setOnFetching] = useState(false);
  const {
    itemsAttributes,
    pickupAddressAttributes,
    deliveryAddressAttributes,
    description,
    startDate,
    endDate
  } = Boolean(solicitude) && solicitude;

  const dispatch = useDispatch();
  const handleSuccessFetchSolicitude = response => {
    setSolicitude(camelCaseRecursive(response.data));
  };
  const handleFetchSolicitude = () => {
    setOnFetching(true);
    showSolicitudeRequest(solicitudeId, {
      dispatch,
      successCallback: handleSuccessFetchSolicitude
    });
    setOnFetching(false);
  };

  useEffect(handleFetchSolicitude, []);

  return (
    <>
      {onFetching && (
        <div
          className="containerSpinnerLoad"
          style={{ position: 'absolute', height: '100%' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!onFetching && (
        <Card className="solicitude-preview--container">
          <h6 className="ml-2 mb-1 title-preview">Resumen Solicitud</h6>
          <div className="preview-item--info">
            {itemsAttributes?.map(item => (
              <DrivertItemDescription
                containerClass="mb-2"
                key={item.name}
                images={item.itemImages}
                title={item.name}
                height={item.height}
                length={item.length}
                width={item.width}
                weight={item.weight}
                description={description}
              />
            ))}
            <div className="mt-2">
              <DateCell
                hasQuotation
                id={solicitude}
                startLocation={pickupAddressAttributes.communeName}
                endLocation={deliveryAddressAttributes.communeName}
                startDate={startDate}
                endDate={endDate}
                colorIcon="color-primary"
                colorLine="color-red"
              />
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default SolicitudePreview;
