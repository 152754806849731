import React from 'react';
import IcoMoon from '../Utils/Icon';

const IconCell = ({
  icon,
  hasQuotation,
  message,
  module = 'client',
  userHasBestQuotation = false
}) => {
  const defineBackground = (() => {
    const iconBackgroundObject = {
      client: {
        files: hasQuotation ? 'bg-orange' : 'bg-disabled',
        car: 'bg-green',
        warning: 'bg-expired'
      },
      driver: {
        flame: 'bg-success',
        files: 'bg-green',
        shipping: userHasBestQuotation ? 'bg-green' : 'bg-yellow'
      }
    };

    return iconBackgroundObject[module][icon] || 'bg-disabled';
  })();

  return (
    <div
      data-tag="allowRowEvents"
      className={`d-flex flex-column justify-content-center align-items-center icon-box ${defineBackground}`}
      style={{ height: '100%' }}
    >
      <IcoMoon
        data-tag="allowRowEvents"
        size="25"
        icon={icon}
        className="flame"
      />
      <h6 data-tag="allowRowEvents" className="opportunity-tag">
        {message}
      </h6>
    </div>
  );
};

export default IconCell;
