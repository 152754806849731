import { takeEvery, put } from 'redux-saga/effects';
import { utilsTypes } from '../actions/utils';
import API from '../services/api';
import runDefaultSaga, { setUserHeaders } from './default';

// Settings
const getSettingsRequest = () => API.get('/settings.json');

function* getSettingsSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_SETTINGS_SUCCESS, result, response });
  }
}

function* getSettingsFailureCallback() {
  yield put({
    type: utilsTypes.GET_SETTINGS_FAILURE
  });
}

function* getSettings() {
  yield runDefaultSaga(
    { request: getSettingsRequest },
    getSettingsSuccessCallback,
    getSettingsFailureCallback
  );
}

// Slides
const getSlidesRequest = () => API.get('/pages');

function* getSlidesSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_SLIDES_SUCCESS, result, response });
  }
}

function* getSlidesFailureCallback() {
  yield put({
    type: utilsTypes.GET_SLIDES_FAILURE
  });
}

function* getSlides() {
  yield runDefaultSaga(
    { request: getSlidesRequest },
    getSlidesSuccessCallback,
    getSlidesFailureCallback
  );
}

// Page
const getPageRequest = url => API.get(`/pages/${url}`);

function* getPageSuccessCallback(result) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_PAGE_SUCCESS, result });
  }
}

function* getPageFailureCallback() {
  yield put({
    type: utilsTypes.GET_PAGE_FAILURE
  });
}

function* getPage(action) {
  yield runDefaultSaga(
    { request: getPageRequest, params: action.url },
    getPageSuccessCallback,
    getPageFailureCallback
  );
}

// Headers
function* setHeaders(headers) {
  yield setUserHeaders(headers);
}

// Notifications
const getNotificationsRequest = () =>
  API.get('/notifications?last_notifications=true');

function* getNotificationsSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: utilsTypes.GET_NOTIFICATIONS_SUCCESS, result, response });
  }
}

function* getNotificationsFailureCallback() {
  yield put({
    type: utilsTypes.GET_NOTIFICATIONS_FAILURE
  });
}

function* getNotifications() {
  yield runDefaultSaga(
    { request: getNotificationsRequest },
    getNotificationsSuccessCallback,
    getNotificationsFailureCallback
  );
}

const getUnreadNotificationsRequest = () =>
  API.get('/notifications/unread_notifications');

function* getUnreadNotificationsSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({
      type: utilsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS,
      result,
      response
    });
  }
}

function* getUnreadNotificationsFailureCallback() {
  yield put({
    type: utilsTypes.GET_UNREAD_NOTIFICATIONS_FAILURE
  });
}

function* getUnreadNotifications() {
  yield runDefaultSaga(
    { request: getUnreadNotificationsRequest },
    getUnreadNotificationsSuccessCallback,
    getUnreadNotificationsFailureCallback
  );
}

// DEFINE utilsSagas
export default function* utilsSagas() {
  yield takeEvery(utilsTypes.GET_SETTINGS_REQUEST, getSettings);
  yield takeEvery(utilsTypes.GET_SLIDES_REQUEST, getSlides);
  yield takeEvery(utilsTypes.GET_PAGE_REQUEST, getPage);
  yield takeEvery(utilsTypes.SET_HEADERS, setHeaders);
  yield takeEvery(utilsTypes.GET_NOTIFICATIONS_REQUEST, getNotifications);
  yield takeEvery(
    utilsTypes.GET_UNREAD_NOTIFICATIONS_REQUEST,
    getUnreadNotifications
  );
}
