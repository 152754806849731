import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import FirstSection from './Landing/FirstSection';
// import SecondSection from './Landing/SecondSection';
import FourthSection from './Landing/FourthSection';
// import FooterSection from './Landing/FooterSection';

const LandingPage = () => {
  const jwt = localStorage.getItem('jwt');
  const loginRole = localStorage.getItem('loginRole');
  const { auth, utils } = useSelector(state => state);
  const { user } = auth;
  const {
    firstSectionTitle,
    firstSectionSubtitle,
    // secondSectionTitle,
    // secondSectionColA,
    // secondSectionColB,
    // footerTitle,
    // footerText,
    // linkAppstore,
    // linkPlaystore,
    questionOne,
    answerOne,
    questionTwo,
    answerTwo,
    questionThree,
    answerThree,
    questionFour,
    answerFour,
    questionFive,
    answerFive,
    questionSix,
    answerSix,
    questionSeven,
    answerSeven,
    questionEight,
    answerEight,
    questionNine,
    answerNine,
    questionTen,
    answerTen
  } = utils?.settings;

  if (jwt) {
    return (
      <Redirect
        to={
          loginRole === 'client' && user.current_roles?.includes('client')
            ? '/client/home'
            : '/driver/home'
        }
      />
    );
  }

  return (
    <div className="home-landing">
      <Container className="container-layout home-container" fluid>
        <FirstSection
          firstSectionTitle={firstSectionTitle}
          firstSectionSubtitle={firstSectionSubtitle}
        />
        {/* <SecondSection
          secondSectionTitle={secondSectionTitle}
          secondSectionColA={secondSectionColA}
          secondSectionColB={secondSectionColB}
        /> */}
        <FourthSection
          questionOne={questionOne}
          answerOne={answerOne}
          questionTwo={questionTwo}
          answerTwo={answerTwo}
          questionThree={questionThree}
          answerThree={answerThree}
          questionFour={questionFour}
          answerFour={answerFour}
          questionFive={questionFive}
          answerFive={answerFive}
          questionSix={questionSix}
          answerSix={answerSix}
          questionSeven={questionSeven}
          answerSeven={answerSeven}
          questionEight={questionEight}
          answerEight={answerEight}
          questionNine={questionNine}
          answerNine={answerNine}
          questionTen={questionTen}
          answerTen={answerTen}
        />
        {/* <FooterSection
          footerTitle={footerTitle}
          footerText={footerText}
          linkAppstore={linkAppstore}
          linkPlaystore={linkPlaystore}
        /> */}
      </Container>
    </div>
  );
};

export default LandingPage;
