import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const FormikNumberFormat = ({
  abbr,
  delayHide,
  delayShow,
  direction,
  disabled,
  error,
  fieldName,
  helpText,
  label,
  leftAddon,
  rightAddon,
  tooltipHtml,
  tooltipText,
  touched,
  phoneFormat,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Form.Group>
      {label && (
        <div className="label-with--tooltip">
          <Form.Label>
            {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          </Form.Label>
        </div>
      )}
      <InputGroup>
        {leftAddon && (
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">{leftAddon}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        {phoneFormat ? (
          <PatternFormat
            {...props}
            className={error && touched ? 'is-invalid' : ''}
            customInput={Form.Control}
            format="+56# #### ####"
            allowEmptyFormatting
          />
        ) : (
          <NumericFormat
            {...props}
            className={error && touched ? 'is-invalid' : ''}
            disabled={disabled}
            allowNegative={false}
            customInput={Form.Control}
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            onValueChange={values => setFieldValue(fieldName, values?.value)}
            onBlur={e => setFieldValue(props.name, e.target.value)}
          />
        )}
        {rightAddon && (
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon2">{rightAddon}</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      {error && touched && (
        <Form.Text className="text-danger">{error}</Form.Text>
      )}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikNumberFormat.propTypes = {
  direction: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  tooltipHtml: PropTypes.bool
};

FormikNumberFormat.defaultProps = {
  direction: 'top',
  delayShow: 250,
  delayHide: 0,
  tooltipHtml: true
};

export default FormikNumberFormat;
