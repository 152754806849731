import React, { useState } from 'react';
import DatesFilter from './DatesFilter';
import IcoMoon from '../Icon/IcoMoon';
import QueryFilter from './QueryFilter';
import LocationFilter from './LocationFilter';
import { FormikSelect } from '../Select';

const CustomHeaderFilter = ({
  customParams,
  setCustomParams,
  startDateField,
  endDateField,
  pickupAddress,
  deliveryAddress,
  queryPlaceholder,
  optionsSelect
}) => {
  const [isToggled, setIsToggled] = useState({
    search: true,
    dates: false,
    locations: false,
    paid: false
  });
  const [selectedValue, setSelectedValue] = useState('');
  const showSearchInputs = () =>
    setIsToggled({ search: true, dates: false, locations: false, paid: false });
  const showDateInputs = () =>
    setIsToggled({ search: false, dates: true, locations: false, paid: false });
  const showLocationsInputs = () =>
    setIsToggled({ search: false, dates: false, locations: true, paid: false });
  const showPaidInputs = () =>
    setIsToggled({ search: false, dates: false, locations: false, paid: true });

  return (
    <div
      className="d-flex align-items-center header-filters"
      style={{ height: '60px' }}
    >
      {isToggled.search && (
        <div className="left-animation">
          <QueryFilter
            customParams={customParams}
            setCustomParams={setCustomParams}
            paramName="query"
            queryPlaceholder={queryPlaceholder}
          />
        </div>
      )}
      {isToggled.search && (
        <div
          onClick={() => showDateInputs()}
          onKeyPress={() => showDateInputs()}
          role="button"
          tabIndex="0"
          className="dates-filter--icon pointer opacity-animation ml-3"
        >
          <IcoMoon icon="search" size={20} />
        </div>
      )}

      {isToggled.dates && (
        <div className="left-animation">
          <DatesFilter
            customParams={customParams}
            setCustomParams={setCustomParams}
            startDateField={startDateField}
            finishDateField={endDateField}
          />
        </div>
      )}
      {isToggled.dates && (
        <div
          onClick={() => showLocationsInputs()}
          onKeyPress={() => showLocationsInputs()}
          role="button"
          tabIndex="0"
          className="dates-filter--icon pointer opacity-animation ml-3"
        >
          <IcoMoon icon="calendar" size={20} />
        </div>
      )}

      {isToggled.locations && (
        <div className="left-animation">
          <LocationFilter
            customParams={customParams}
            setCustomParams={setCustomParams}
            pickupAddress={pickupAddress}
            deliveryAddress={deliveryAddress}
          />
        </div>
      )}
      {isToggled.locations && (
        <div
          onClick={() =>
            optionsSelect ? showPaidInputs() : showSearchInputs()
          }
          onKeyPress={() =>
            optionsSelect ? showPaidInputs() : showSearchInputs()
          }
          role="button"
          tabIndex="0"
          className="dates-filter--icon pointer opacity-animation"
        >
          <IcoMoon icon="location1" size={20} />
        </div>
      )}
      {optionsSelect && (
        <>
          {isToggled.paid && (
            <div className="left-animation paid-filter">
              <FormikSelect
                placeholder="Filtrar por tipo de pago"
                options={optionsSelect}
                value={selectedValue}
                defaultValue={selectedValue}
                isClearable
                onChange={data => {
                  const type = data === null ? '' : data.value;
                  setSelectedValue(type);
                  setCustomParams(params => ({
                    ...params,
                    filter_by_paid: type
                  }));
                }}
              />
            </div>
          )}
          {isToggled.paid && (
            <div
              onClick={() => showSearchInputs()}
              onKeyPress={() => showSearchInputs()}
              role="button"
              tabIndex="0"
              className="dates-filter--icon pointer opacity-animation"
            >
              <IcoMoon icon="money" size={20} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomHeaderFilter;
