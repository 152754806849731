import React from 'react';
import { Col, Row } from 'react-bootstrap';

const QuotationCardWrapper = ({
  classNameContainer,
  split,
  firstPart,
  secondPart
}) => {
  if (split)
    return (
      <Row
        className="quotation-card-modal w-100"
        style={{ overflow: 'hidden' }}
      >
        <Col md={6} className="pl-n3">
          {firstPart}
        </Col>
        <Col md={6} className="pl-md-4">
          {secondPart}
        </Col>
      </Row>
    );

  return (
    <div
      className={`quotation-card-modal ${classNameContainer}`}
      style={{ overflow: 'hidden' }}
    >
      {firstPart}
      <hr />
      {secondPart}
    </div>
  );
};

export default QuotationCardWrapper;
