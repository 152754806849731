const basicSolicitude = {
  deliveryStartDate: '',
  deliveryEndDate: '',
  deliveryPersonName: '',
  deliveryPersonPhone: '',
  deliveryOption: '',
  description: '',
  pickupStartDate: '',
  pickupEndDate: '',
  pickupPersonName: '',
  pickupPersonPhone: '',
  pickupOption: '',
  itemsAttributes: [
    {
      name: '',
      net_value: '',
      height: '',
      width: '',
      length: '',
      weight: '',
      is_fragile: false,
      images: [],
      item_images: []
    }
  ],
  deliveryAddressAttributes: {
    communeId: '',
    streetName: '',
    latitude: '',
    longitude: '',
    isValidAddress: false
  },
  pickupAddressAttributes: {
    communeId: '',
    streetName: '',
    latitude: '',
    longitude: '',
    isValidAddress: false
  },
  deleteItemsPhotos: [],
  terms: false
};

export default basicSolicitude;
