import React from 'react';
import { Button } from 'react-bootstrap';
import IcoMoon from '../Icon/IcoMoon';

import DateFilter from './DatesFilter';
import QueryFilter from './QueryFilter';
import LocationFilter from './SideLocationFilter';

const SidebarFilter = ({
  collapsed,
  closeSidebar,
  setCustomParams,
  customParams,
  onFilterCallback
}) => {
  const handleOnFilter = () => {
    if (onFilterCallback) onFilterCallback();
    // closeSidebar();
  };

  return (
    <div
      className={`sidebar-filter--container shadow ${
        collapsed ? 'collapsed' : 'right-animation'
      }`}
    >
      <div className="d-flex">
        <IcoMoon
          icon="cancel-circle"
          className="pointer"
          onClick={closeSidebar}
        />
      </div>
      <div className="mx-2 d-flex flex-column justify-content-start align-items-left mt-4">
        <p className="mb-2 text-primary font-weight-bold">Filtrar por nombre</p>
        <QueryFilter
          sidebar
          customParams={customParams}
          setCustomParams={setCustomParams}
          paramName="query"
          queryPlaceholder="Buscar por nombre"
        />
        <hr style={{ width: '100%', marginTop: 30, marginBottom: 20 }} />
        <p className="text-primary font-weight-bold">Filtrar por fechas</p>
        <DateFilter
          customParams={customParams}
          finishDateField="dateTo"
          setCustomParams={setCustomParams}
          startDateField="dateFrom"
        />
        <hr style={{ width: '100%', marginBottom: 20, marginTop: 8 }} />
        <p className="text-primary font-weight-bold">Filtrar por comunas</p>
        <LocationFilter
          customParams={customParams}
          setCustomParams={setCustomParams}
          pickupAddress="communeFrom"
          deliveryAddress="communeTo"
        />

        <Button block className="mt-4" onClick={handleOnFilter}>
          Filtrar
        </Button>
      </div>
    </div>
  );
};

export default SidebarFilter;
