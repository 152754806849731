import React, { useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { DefaultHeader } from '../../../components';
import { validateToken } from '../../../actions/auth';
import {
  ProfileFirstCol,
  ProfileSecondCol,
  ProfileSessionInfo
} from '../../../components/Profile';

const ProfileDriver = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const {
    driverAttributes,
    completenessDriver,
    avatarProfile,
    email,
    countDays
  } = camelCaseRecursive(user);

  const frontLicenseImg = driverAttributes?.frontLicenseImg?.fileUrlMinified;
  const frontLicenseImgFullRes = driverAttributes?.frontLicenseImg?.fileUrl;

  const backLicenseImg = driverAttributes?.backLicenseImg?.fileUrlMinified;
  const backLicenseImgFullRes = driverAttributes?.backLicenseImg?.fileUrl;

  const frontIdentityImg = driverAttributes?.frontIdentityImg?.fileUrlMinified;
  const frontIdentityImgFullRes = driverAttributes?.frontIdentityImg?.fileUrl;

  const backIdentityImg = driverAttributes?.backIdentityImg?.fileUrlMinified;
  const backIdentityImgFullRes = driverAttributes?.backIdentityImg?.fileUrl;

  const numOfStars = driverAttributes?.numOfStars;
  const phone = driverAttributes?.phone;
  const fullName = driverAttributes?.fullName;

  useEffect(() => {
    dispatch(validateToken());
  }, []);

  return (
    <div className="edit-driver-profile">
      <DefaultHeader
        title="Mi Perfil"
        firstColXs={6}
        body={
          <Button as={Link} to="/driver/profile/edit" variant="primary">
            Editar
          </Button>
        }
      />
      <Row className="mt-4">
        <ProfileFirstCol
          module="driver"
          avatarProfileUrl={avatarProfile?.fileUrlMinified}
          fullName={fullName}
          email={email}
          countDays={countDays}
          completeness={completenessDriver}
          numOfStars={numOfStars}
          phone={phone}
          user={camelCaseRecursive(user)}
        />
        <ProfileSecondCol
          driver={driverAttributes}
          description={driverAttributes?.description}
          frontLicenseUrl={frontLicenseImg}
          backLicenseUrl={backLicenseImg}
          frontIdentityUrl={frontIdentityImg}
          backIdentityUrl={backIdentityImg}
          vehiclesAttributes={driverAttributes?.vehiclesAttributes}
          frontLicenseImgFullRes={frontLicenseImgFullRes}
          backLicenseImgFullRes={backLicenseImgFullRes}
          frontIdentityImgFullRes={frontIdentityImgFullRes}
          backIdentityImgFullRes={backIdentityImgFullRes}
          bankAccountAttributes={driverAttributes?.bankAccountAttributes}
        />
      </Row>
      <ProfileSessionInfo
        user={camelCaseRecursive(user)}
        driver
        classNameRow="d-md-none"
      />
    </div>
  );
};

export default ProfileDriver;
