import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import LandingNavbar from './LandingNavbar';
import Icomoon from '../Icon/IcoMoon';

const NavbarTop = () => {
  const location = useLocation();
  // const { utils } = useSelector(state => state);
  // const {
  // secondSectionTitle,
  // thirdSectionTitle,
  // footerTitle
  // } = utils?.settings;

  return (
    <LandingNavbar>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {location.pathname !== '/contact' ? (
            <>
              {/* <Nav.Link href="/" className="nav-button">
                Home
              </Nav.Link> */}
              {/* <Nav.Link href="#how_works" className="nav-button">
                {secondSectionTitle || ''}
              </Nav.Link> */}
              {/* <Nav.Link href="#faqs_briiing" className="nav-button">
                {thirdSectionTitle || ''}
              </Nav.Link> */}
              {/* <Nav.Link href="#download" className="nav-button">
                {footerTitle || ''}
              </Nav.Link> */}
            </>
          ) : (
            <Nav.Link as={Link} to="/" className="nav-button">
              <Icomoon
                styleContainer={{ margin: '0 5px 0 0' }}
                icon="chevron-back"
                className="ml-n2"
              />
              Volver
            </Nav.Link>
          )}
          <Nav.Link as={Link} to="/contact" className="nav-button">
            Contáctanos
          </Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={Link} to="/not-yet" className="btn btn-secondary">
            Comenzar ahora
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </LandingNavbar>
  );
};

export default NavbarTop;
